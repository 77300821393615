import SearchKeyword from "components/Common/SearchKeyword";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { STATUS_KEYWORD_OPTIONS, typeSearch, } from "helpers/constans";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Row, Modal, ModalBody, ModalHeader, } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getAllCategoriesTrendIndexNormal, getAllCategoriesType, getAllMedias, getAllTemplates, getAllTypes, getKeywords as onGetKeywords } from "../../../store/thunks";
import CampaignFormByKeyword from "pages/Campaign/CampaignForm/CampaignFormByKeyword";
import { IMedia, IType } from "api/types/_media";
import { ITemplate } from "api/types/_template";
import { ICategory } from "api/types/_category";
import CategoryFormByKeyword from "../Category/CategoryFormByKeyword";

interface Option {
  label: string;
  value: string;
}

const typeQuery = {
  'search_monthly': StringParam,
  'search_monthly_pc': StringParam,
  'search_monthly_mobile': StringParam,
  'click_avg_monthly': StringParam,
  'click_avg_monthly_pc': StringParam,
  'click_avg_monthly_mobile': StringParam,
  'click_rate_monthly': StringParam,
  'click_rate_monthly_pc': StringParam,
  'click_rate_monthly_mobile': StringParam,
  'impressions_avg_monthly': StringParam,
}
const TYPE_SELECT_DEFAULT: string = 'search_monthly';
registerLocale('en', en);
registerLocale('ko', ko);


const NaverKeyword = () => {
  const { t, i18n } = useTranslation();
  const [query, setQuery]: any = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    category_id: NumberParam,
    competition_level: StringParam,
    keyword: StringParam,
    status: withDefault(StringParam, ''),
    ...typeQuery,
  });
  const typeSearchLang = typeSearch?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
  const [typeSelect, setTypeSelect] = useState(typeSearchLang?.find((e: any) => e.value === query.sort_by));
  const rangeValue = useRef<any>();
  const [fromRangeSearch, setFromRangeSearch] = useState<string>("");
  const [toRangeSearch, setToRangeSearch] = useState<string>("");
  const [categorySelect, setCategorySelect] = useState<Option | null>();

  const [optionsSelected, setOptionsSelected] = useState<any>({});

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);

  const [isOpenCategory, setIsOpenCategory] = useState<boolean>(false);

  // Begin::List for create category
  const [listType, setListType] = useState<IType[]>([]);

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [listTemplate, setListTemplate] = useState<ITemplate[]>([]);

  const [listCategoriesType, setListCategoriesType] = useState<Option[]>([]);

  const [listCategoriesTrendIndex, setListCategoriesTrendIndex] = useState<ICategory[]>([]);

  // End::List for create category

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Naver;
  const naverCategoryProperties = createSelector(
    selectLayoutState,
    (state) => ({
      keywords: state.keywords,
      isKeywordSuccess: state.isKeywordSuccess,
      isKeywordLoading: state.isKeywordLoading,
      error: state.error,
    })
  );
  // Inside your component
  const { keywords, error, isKeywordLoading } = useSelector(naverCategoryProperties);

  useEffect(() => {
    dispatch(onGetKeywords(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modal]);

  const searchData = () => {
    const rangeQuery = (fromRangeSearch && toRangeSearch) ? `gt:${fromRangeSearch}-lt:${toRangeSearch}` : undefined;
    setQuery({
      ...query,
      [typeSelect?.value]: rangeQuery,
      keyword: keywordSearch || undefined,
      sort_by: typeSelect?.value || TYPE_SELECT_DEFAULT,
      page: 1,
      category_id: categorySelect?.value,
    });

  };

  const resetData = () => {
    setOptionsSelected((_prev: any) => ({}));
    setQuery({
      keyword: undefined,
      sort_by: undefined,
      [typeSelect?.value]: undefined,
      category_id: undefined,
    }, "push")
    setKeywordSearch("");
    setTypeSelect(typeSearchLang?.find((e: any) => e.value === TYPE_SELECT_DEFAULT));
    setFromRangeSearch("");
    setToRangeSearch("");
    setCategorySelect(null);
  };


  // Begin:: handle select table 
  const handleCheck = (valueCheck: Option, valueIndex: number | string, e: any = null) => {
    const index = String(valueIndex);
    if (valueCheck?.value === 'ALL') {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (keywords?.list || [])?.map((item: any) => ({ value: item?.id, label: item?.keyword }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter((item: any) => (item?.value !== valueCheck?.value));
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = ([...prevClone[index], valueCheck]);
        return prevClone;
      });
    }
  };

  const isChecked = (valueCheck: Option) => {
    const index = String(query.page);
    return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (optionsSelected[valueIndexString]?.length > 0 && optionsSelected[valueIndexString]?.length === keywords?.list?.length);
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((total: number, [key, value]: any) => {
      return Number(total + (value || [])?.length);
    }, 0)
  }, [optionsSelected]);

  const listKeyword = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce((arr: Option[], [key, value]: any) => {
      return ([...arr, ...(value || [])]);
    }, [])
  }, [optionsSelected]);

  // End:: handle select table 

  const onCloseClick = (e: any) => {
    e.preventDefault();
    setIsOpen((_prev) => false);
  };

  const onCloseCategoryClick = (e: any) => {
    e.preventDefault();
    setIsOpenCategory((_prev) => false);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Search Monthly'),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: 'text-start',
        thWidth: 46,
        thComponent: () => (
          <>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" checked={isCheckedAll(query?.page)} onChange={(e) => handleCheck(({ value: 'ALL', label: '' }), query?.page, e)} value="" id={`cell-check-all`} />
              <label className="form-check-label" htmlFor={`cell-check-all`}></label>
            </div>
          </>
        ),
        Cell: (cell: any) => (
          <>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" name="table" checked={isChecked({ value: cell?.row?.original?.id, label: cell?.row?.original?.keyword })} value={cell?.row?.original?.id ?? ''} onChange={(e) => handleCheck(({ value: cell?.row?.original?.id, label: cell?.row?.original?.keyword }), query?.page, e)} id={`cell-check-${cell?.row?.original?.id ?? ''}`} />
              <label className="form-check-label" htmlFor={`cell-check-${cell?.row?.original?.id ?? ''}`}></label>
            </div>
          </>
        ),
      },
      {
        Header: t('Search Monthly'),
        accessor: "search_monthly",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell.value ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Categories'),
        accessor: "categories",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const categories = value?.categories;
          let categoryName: string = "";
          for (let category of categories) {
            for (let sub of category?.categories) {
              categoryName += "- " + sub?.name + "<br/>";
            }
          }
          return (
            <div dangerouslySetInnerHTML={{ __html: categoryName }} />
          );
        }
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell.value ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Search Monthly PC'),
        accessor: "search_monthly_pc",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell.value ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Search Monthly Mobile'),
        accessor: "search_monthly_mobile",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell.value ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Click Avg Monthly'),
        accessor: "click_avg_monthly",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell.value ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Click Avg Monthly PC'),
        accessor: "click_avg_monthly_pc",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell.value ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Click Avg Monthly Mobile'),
        accessor: "click_avg_monthly_mobile",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell.value ?? "-"}</span>
          </>
        ),
      },
    ],
    [i18n?.language, JSON.stringify(optionsSelected), query?.page, JSON.stringify(keywords?.list)]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  useEffect(() => {
    if (typeSelect?.value) {
      setTypeSelect((_prev: any) =>
        typeSearchLang?.find((e: any) => e.value === typeSelect?.value)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Keyword')} - ${t('Naver Keyword')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);


  const handleCallAllOption = async () => {
    try {
      const [resType, resMedia, resCategoriesType, resTemplate, resCategoriesTrendIndex]: any = await Promise.all([getAllTypes(), getAllMedias(), getAllCategoriesType(), getAllTemplates(), getAllCategoriesTrendIndexNormal()]);
      setListType((_prev) => resType?.data || []);
      setListMedia((_prev) => resMedia?.data || []);
      setListCategoriesType((_prev) => (resCategoriesType?.data?.map((item: any) => ({ value: item?.type, label: item?.name })) || []));
      setListTemplate((_prev) => resTemplate?.data || []);
      setListCategoriesTrendIndex((_prev) => resCategoriesTrendIndex?.data || []);
    } catch (error: any) {
      return error;
    }
  };

  const handleRefetchCategoriesTrendIndex = useCallback(async () => {
    try {
      const [resCategoriesTrendIndex]: any = await Promise.all([getAllCategoriesTrendIndexNormal()]);
      setListCategoriesTrendIndex((_prev) => resCategoriesTrendIndex?.data || []);
    } catch (error: any) {
      return error;
    }
  }, []);

  const handleClearSelectedKeywords = useCallback(async () => {
    try {
      setOptionsSelected((_prev: any) => ({}));
    } catch (error: any) {
      return error;
    }
  }, []);

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Naver Keyword')} pageTitle={t('Keyword')} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center mb-2">
                    <Col sm={6} lg={3}>
                      <SearchKeyword initialValue={categorySelect ?? undefined} onChangeCategory={setCategorySelect} />
                    </Col>
                    <Col sm={6} lg={4}>
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={`${t('Search by keyword')}...`}
                        value={keywordSearch}
                        onChange={(e) => setKeywordSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            searchData();
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="g-4 align-items-center">
                    {/* type search */}
                    <Col sm={6} lg={3}>
                      <Select
                        options={typeSearchLang}
                        value={typeSelect}
                        onChange={(e: any) => setTypeSelect(e)}
                        placeholder={<div>{`${t('Search by field')}...`}</div>}
                      />
                    </Col>
                    <Col sm={3} lg={2}>
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={`${t('From Range')}...`}
                        value={fromRangeSearch}
                        onChange={(e) => setFromRangeSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            searchData();
                          }
                        }}
                      />
                    </Col>
                    <Col sm={3} lg={2}>
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={`${t('To Range')}...`}
                        value={toRangeSearch}
                        onChange={(e) => setToRangeSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            searchData();
                          }
                        }}
                      />
                    </Col>
                    <Col sm={12} lg={5} className="hstack gap-1 justify-content-sm-center justify-content-md-between">
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={searchData}
                          disabled={isKeywordLoading}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t('Button Search')}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14 ms-1"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t('Button Reset')}
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-success fs-14"
                          onClick={() => setIsOpen((_prev) => true)}
                          disabled={countRowSelected <= 0}
                        >
                          <i className="ri-add-fill align-bottom me-1"></i>{" "}
                          {countRowSelected > 0 && (
                            <span className="position-absolute topbar-badge badge rounded-pill bg-danger"
                              style={{ transform: 'translate(0%, -70%)' }}
                            >
                              {countRowSelected}
                              <span className="visually-hidden">total keywords selected</span>
                            </span>)}
                          {t('Button Create Campaign')}
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    {/* <Col sm={12} lg={2} >
                      <div className="d-flex align-items-center">{t('Total Selected')}: <strong className="text-primary ms-2"> {countRowSelected}</strong></div>
                    </Col> */}
                    {/* <Col sm={12} lg={6} className="hstack gap-1 justify-content-sm-center justify-content-md-between">
                      <button
                        type="button"
                        className="btn btn-soft-success fs-14"
                        onClick={resetData}
                      >
                        <i className="ri-add-fill align-bottom me-1"></i>{" "}
                        {t('Button Create Campaign')}
                      </button>
                    </Col> */}
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card table-has-checkbox-select"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={keywords?.list?.length ? keywords.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={keywords?.pagination?.total}
                      customPageCount={Math.ceil(Number(keywords?.pagination?.total) / Number(keywords?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isKeywordLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={isOpen} centered={true} size="xl" className="modal-fullscreen-r" scrollable={true} >
        <ModalHeader toggle={onCloseClick}>
          {t('Button Create Campaign')}
        </ModalHeader>
        <ModalBody>
          <div style={{ minHeight: 'calc(100dvh - 200px)' }}>
            <CampaignFormByKeyword
              listKeyword={listKeyword || []}
              listType={listType}
              listMedia={listMedia}
              listTemplate={listTemplate}
              listCategoriesType={listCategoriesType}
              listCategoriesTrendIndex={listCategoriesTrendIndex}
              handleRefetchCategoriesTrendIndex={handleRefetchCategoriesTrendIndex}
              handleClearSelectedKeywords={handleClearSelectedKeywords}
            />
          </div>
        </ModalBody>
      </Modal>

    </React.Fragment>
  );
};

export default NaverKeyword;
