import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import statisticApi from 'api/statisticApi';

export const getNewsLogs = createAsyncThunk("Statistics/NewsLogs" , async (params: any = {}) => {
  try{
    const response = await statisticApi.newsLogs(params)
    return response;
  }catch (error) {
    return error;
  }
});

export const getNewsLogsByNormal =  async (params: any = {}) => {
  try{
    const response = await statisticApi.newsLogs(params)
    return response;
  }catch (error) {
    return error;
  }
};