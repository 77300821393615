import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import mediaApi from 'api/mediaApi';

export const getMedias = createAsyncThunk("Medias" , async (params: any = {}) => {
  try{
    const response = await mediaApi.medias(params)
    return response;
  }catch (error) {
    return error;
  }
});

export const getAllMedias =  async(params: any = {}) => {
  try{
    const response = await mediaApi.allMedias(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const postMedia = async (data: any = {}) => {
  try{
    const response = await mediaApi.postMedia(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putMedia = async (id: string | number , data: any = {}) => {
  try{
    const response = await mediaApi.putMedia(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteMedia = async (id: string | number , data: any = {}) => {
  try{
    const response = await mediaApi.deleteMedia(id, data);
    return response;
  }catch (error) {
    return error;
  }
};


export const getAllTypes =  async(params: any = {}) => {
  try{
    const response = await mediaApi.allTypes(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const refreshKey =  async(id:string | number, params: any = {}) => {
  try{
    const response = await mediaApi.refreshKey(id, params)
    return response;
  }catch (error) {
    return error;
  }
};
