import { createAsyncThunk } from "@reduxjs/toolkit";
import trendIndexApi from "api/trendIndexApi";
import "react-toastify/dist/ReactToastify.css";

export const getCategoriesTrendIndex = createAsyncThunk(
  "TrendIndex/getCategoriesTrendIndex",
  async (params: any = {}) => {
    try {
      const response = await trendIndexApi.categories(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getTrendIndexes = createAsyncThunk(
  "TrendIndex",
  async (params: any = {}) => {
    try {
      const response = await trendIndexApi.trendIndexes(params);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getTrendIndexKeywordsByNormal = async (params: any = {}) => {
  try {
    const response = await trendIndexApi.trendIndexes(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllCategoriesTrendIndexNormal = async (
  params: any = { type: "trend_index" }
) => {
  try {
    const response = await trendIndexApi.searchCategories(params);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteTrendIndex = async (id: string | number, data: any = {}) => {
  try {
    const response = await trendIndexApi.deleteTrendIndex(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCategoryTrendIndex = async (
  id: string | number,
  data: any = {}
) => {
  try {
    const response = await trendIndexApi.deleteCategoryTrendIndex(id, data);
    return response;
  } catch (error) {
    return error;
  }
};

export const postAnalysis = async (data: any = {}) => {
  try {
    const response = await trendIndexApi.postAnalysis(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const putTrendIndexCategory = async (
  id: string | number,
  data: any = {}
) => {
  try {
    const response = await trendIndexApi.putTrendIndexCategory(id, data);
    return response;
  } catch (error) {
    return error;
  }
};
