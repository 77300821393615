import { ICurrency } from "api/types/_currency";
import { ITopic } from "api/types/_topic";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
interface Option {
  label: string;
  value: string;

}

interface FilterCurrunciesProps {
  name?: string,
  dataList?: ICurrency[],
  initialValue?: Option[] | Option | undefined;
  onChangeSelect?: (params: Option) => void;
  placeholder?: string,
}

const FilterCurrencies = ({
  name = '',
  initialValue = [],
  dataList = [],
  onChangeSelect,
}: FilterCurrunciesProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  useEffect(() => {
    const result = dataList?.map((item) => ({ code: item?.code, label: `${item?.name} / ${item?.country}`, value: String(item?.code), country: item?.country })) || [] as Option[];
    setOptions((_prev: any) => result);
  }, [JSON.stringify(dataList)]);

  return (
    <React.Fragment>
      <Select
        cacheOptions
        options={options}
        isMulti={false}
        closeMenuOnSelect={true}
        name={name}
        value={initialValue}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{t('Select Currency Code')}...</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        formatOptionLabel={(option: any) => (<div className="d-flex">{option?.code} <div className="op ms-2"> {`${String(option?.label)}`}</div> </div>)}
        className="search-filter-currencies"
        classNamePrefix="name-prefix"
      />
    </React.Fragment>
  );
};

export default FilterCurrencies;
