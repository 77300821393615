import { Editor } from '@tinymce/tinymce-react';
import { IMedia } from 'api/types/_media';
import { IPreviewNews } from 'api/types/_news';
import { Option } from 'api/types/_public';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import ModalConfirm from 'components/Common/ModalConfirm';
import SearchFilterKeyword from 'components/Common/SearchFilterKeyword';
import SearchFilterMedia from 'components/Common/SearchFilterMedia';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import BreadCrumb from "../../components/Common/BreadCrumb";
import TableContainer from "../../components/Common/TableContainer";
import { deleteNews, downloadFileNews, downloadImageNews, getAllMedias, getScriptTrackingNews, getNews as onGetNews, putNews, putReCreateNews } from "../../store/thunks";
import CollapseTag from 'components/Common/CollapseTag';
import SearchFilterCampaign from 'components/Common/SearchFilterCampaign';
import { STATUS_KEYWORD_OPTIONS } from 'helpers/constans';

const typeQuery = {
  'title': StringParam,
  // 'audience': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'id';
registerLocale('en', en);
registerLocale('ko', ko);

const PLUGINS = [
  'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
  'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
  'insertdatetime', 'media', 'table'
];
const TOOLBAR = ` undo redo | 'bold italic forecolor | alignleft aligncenter  alignright alignjustify | bullist numlist outdent indent | removeformat | fullscreen`;

const NewsList = () => {
  const { t, i18n } = useTranslation();

  const STATUS_KEYWORD_OPTIONS_LANG = STATUS_KEYWORD_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const [query, setQuery]: any = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    start_date: withDefault(StringParam, ''),
    end_date: withDefault(StringParam, ''),
    partner_ids: withDefault(StringParam, ''),
    keyword_ids: withDefault(StringParam, ''),
    campaign_id: withDefault(StringParam, ''),
    status: withDefault(StringParam, ''),
    ...typeQuery
  });

  const rangeValue = useRef<any>();
  const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date || "", query?.end_date || ""]);
  const [startDate, endDate] = dateSearch;
  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
  const [fromRangeSearch, setFromRangeSearch] = useState<string>("");
  const [toRangeSearch, setToRangeSearch] = useState<string>("");

  const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);

  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);

  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [isLoadingDownloadFile, setIsLoadingDownloadFile] = useState<boolean>(false);
  const [keyLoading, setKeyLoading] = useState<number | null>(null);
  const [statusSearch, setStatusSearch] = useState<Option | null>(null);


  const [keyScriptTrackingLoading, setKeyScriptTrackingLoading] = useState<number | null>(null);
  const [isOpenCopyScriptTracking, setIsOpenCopyScriptTracking] = useState<boolean>(false);
  const [scriptTracking, setScriptTracking] = useState<string>('');

  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const [isOpenConfirmReCreate, setIsConfirmReCreate] = useState<boolean>(false);

  const [previewTemplate, setPreviewTemplate] = useState<IPreviewNews | null>(null);

  const [mediasSearch, setMediasSearch] = useState<Option[]>([]);
  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [campaignSearch, setCampaignSearch] = useState<Option | null>(null);

  const [keywordsSearch, setKeywordsSearch] = useState<Option[]>([]);

  const refTitleNews = useRef<any>(null);
  const refContentNews = useRef<any>(null);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.News;

  const NewsProperties = createSelector(
    selectLayoutState,
    (state) => ({
      news: state.news,
      isNewsSuccess: state.isNewsSuccess,
      isNewsLoading: state.isNewsLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { news, error, isNewsLoading } = useSelector(NewsProperties);



  useEffect(() => {
    dispatch(onGetNews(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  const searchData = () => {
    const rangeQuery = (fromRangeSearch && toRangeSearch) ? `gt:${fromRangeSearch}-lt:${toRangeSearch}` : undefined;
    setQuery({
      ...query,
      title: keywordSearch || undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      partner_ids: (mediasSearch || [])?.map((item) => item?.value).join(',') || '',
      keyword_ids: (keywordsSearch || [])?.map((item) => item?.value).join(',') || '',
      campaign_id: campaignSearch?.value || '',
      status: statusSearch?.value || '',
    });
  };

  const resetData = () => {
    setQuery({
      title: undefined,
      sort_by: undefined,
      category_id: undefined,
      partner_ids: undefined,
      keyword_ids: undefined,
      campaign_id: undefined,
      start_date: undefined,
      end_date: undefined,
      status: undefined
    }, "push")
    setKeywordSearch("");
    setFromRangeSearch("");
    setToRangeSearch("");
    setDateSearch(["", ""]);
    setMediasSearch([]);
    setKeywordsSearch([]);
    setCampaignSearch(null);
    setStatusSearch(null);
  };

  function onClosePreviewClick() {
    setIsOpenPreview((_prev) => !_prev);
  }

  function onCloseEditClick() {
    setIsOpenEdit((_prev) => !_prev);
  }

  function onCloseCopyScriptTrackingClick() {
    setIsOpenCopyScriptTracking((_prev) => !_prev);
  }

  const handlePreviewNews = async (item: IPreviewNews) => {
    setPreviewTemplate((_prev) => (item));
    setIsOpenPreview((_prev) => true);
  };

  const handleEditNews = async (item: IPreviewNews) => {
    setPreviewTemplate((_prev) => ({ ...item, isEdit: true }));
    setIsOpenEdit((_prev) => true);
  };


  const handleDownloadNews = async (item: IPreviewNews | null) => {
    if (item?.id) {
      try {
        setIsLoadingDownloadFile((_prev) => true);
        const response: any = await downloadFileNews(item?.id);
        if (response?.data) {
          setIsLoadingDownloadFile((_prev) => false);
          const link = document.createElement('a');
          link.href = response?.data;
          link.download = 'news';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          setIsLoadingDownloadFile((_prev) => false);
          toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
        }
      } catch (error: any) {
        setIsLoadingDownloadFile((_prev) => false);
        toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
        return error;
      }
    }
  }

  const handleGetScriptTrackingNews = async (item: IPreviewNews | null) => {
    if (item?.id) {
      try {
        setKeyScriptTrackingLoading((_prev) => Number(item?.id));
        const response: any = await getScriptTrackingNews(item?.id);
        if (response?.data) {
          setKeyScriptTrackingLoading((_prev) => null);
          setScriptTracking((_prev) => response?.data || '');
          setIsOpenCopyScriptTracking((_prev) => true);
        } else {
          setKeyScriptTrackingLoading((_prev) => null);
          toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
        }
      } catch (error: any) {
        setKeyScriptTrackingLoading((_prev) => null);
        toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
        return error;
      }
    }
  }

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', true, text);
    }
    toast(`${t('Copied Successfully')}`, CONFIG_OPTION_TOAST_NORMAL);
  };


  const handleDownloadImageNews = async (item: IPreviewNews | null) => {
    if (item?.image_url) {
      try {
        setKeyLoading((_prev) => Number(item?.id));
        const imageUrl = item?.image_url || '';
        fetch(imageUrl)
          .then(response => response.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setKeyLoading((_prev) => null);
          })
          .catch(error => {
            setKeyLoading((_prev) => null);
            toast(`Error downloading image`, CONFIG_OPTION_TOAST_ERROR);
          });
      } catch (error: any) {
        setKeyLoading((_prev) => null);
        toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
        return error;
      }
    }
  }

  const handleUpdateNews = async (item: IPreviewNews | null) => {
    if (item?.id) {
      try {
        setIsLoadingUpdate((_prev) => true);
        const data = {
          title: refTitleNews?.current?.value ?? '',
          content: refContentNews?.current?.getContent() ?? ''
        };
        const response: any = await putNews(item?.id, data);
        if (response?.data) {
          dispatch(onGetNews(query));
          setIsLoadingUpdate((_prev) => false);
          toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
          setPreviewTemplate((_prev) => null);
          setIsOpenEdit((_prev) => false);
        } else {
          setIsLoadingUpdate((_prev) => false);
          toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
        }
      } catch (error: any) {
        setIsLoadingUpdate((_prev) => false);
        toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
        return error;
      }
    }
  }


  // Begin::Re-Create
  const handleReCreateNews = (item: IPreviewNews) => {
    setPreviewTemplate((_prev) => (item));
    setIsConfirmReCreate((_prev) => true);
  };

  const handleActionReCreate = async () => {
    if (!(previewTemplate?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await putReCreateNews(previewTemplate?.id);
      if (response?.code === 200) {
        dispatch(onGetNews(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirmReCreate((_prev) => false);
        setPreviewTemplate((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmReCreateClick = () => {
    setPreviewTemplate((_prev) => null);
    setIsConfirmReCreate((_prev) => false);
  }
  // End::Re-Create

  // Begin::Delete
  const handleConfirmDelete = (item: IPreviewNews) => {
    setPreviewTemplate((_prev) => (item));
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(previewTemplate?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteNews(previewTemplate?.id);
      if (response?.code === 200) {
        dispatch(onGetNews(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setPreviewTemplate((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setPreviewTemplate((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: true,
        thWidth: 90,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Title News'),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div style={{ maxWidth: '30dvw' }}>
              <Link className='text-normal' to="#"
                onClick={(e) => { e.preventDefault(); handlePreviewNews(item) }}
              >
                {item?.title}
              </Link>
            </div>
          );
        },
      },
      // {
      //   Header: t('Topic'),
      //   accessor: "topic",
      //   filterable: true,
      //   sortable: false,
      //   Cell: (cell: any) => {
      //     const value: any = cell?.row?.original;
      //     const topic = value?.topic;
      //     return (topic?.name ? <span className='tag-style'>{topic?.name} </span> : <></>);
      //   }
      // },
      {
        Header: t('Campaign'),
        accessor: "campaign",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value?.name}</span>
          </>
        ),
      },
      {
        Header: t('Medias'),
        accessor: "partners",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const partners = value?.partners || [];
          let partnerName: string = "";
          for (let item of partners) {
            partnerName += `<span class='tag-style'>${item.name} </span>`;
          }
          return (
            <div dangerouslySetInnerHTML={{ __html: partnerName }} />
          );
        }
      },
      {
        Header: t('Keywords'),
        accessor: "keywords",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => {
          // const value: any = cell?.row?.original;
          // const keywords = value?.keywords;
          // let keywordName: string = "";
          // for (let item of keywords) {
          //   keywordName += `<span class='tag-style'>${item.keyword}</span>: ${item.rank}  <br/>`;
          // }
          // return (
          //   <div dangerouslySetInnerHTML={{ __html: keywordName }} />
          // );
          const value: any = cell?.row?.original;
          const keywords = (value?.keywords || [])?.map((item: any) => item?.keyword)?.filter((item: any) => !!item);
          return (<CollapseTag tags={keywords} isRank={false} totalShow={10} />);
        }
      },
      {
        Header: t("Status"),
        accessor: "status",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const status = value?.status;
          const color =
            status === 1 ? "success" : status === 2 ? "primary" : "danger";
          return (
            <div style={{ minWidth: "60px" }}>
              <span className={`badge bg-${color}`}>{`${t(
                `Status_Keyword_${status}`
              )}`}</span>
            </div>
          );
        },
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <span>{cell?.value}</span>
            </>
          )
        },
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Button Action'),
        thClass: 'text-center',
        thWidth: 150,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className={`list-inline flex-wrap hstack gap-2 mb-0 justify-content-end`} style={{ width: '150px' }}>
              {!!(item?.image_url) ? <li className="list-inline-item me-0" title={t('Button Downloading Image')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  download
                  onClick={(e) => { e.preventDefault(); handleDownloadImageNews(item) }}
                >
                  {(String(keyLoading) === String(cell?.row?.id)) ? <Spinner size="sm"></Spinner> : <i className="ri-image-fill align-bottom"></i>}
                </Link>
              </li> : <div className="btn btn-sm btn-soft-secondary edit-item-btn" style={{ opacity: 0.4 }}><i className="ri-image-fill align-bottom"></i></div>}
              <li className="list-inline-item me-0" title={t('Button Update News')}>
                <Link className="btn btn-sm btn-soft-secondary  edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handleEditNews(item) }}
                >
                  <i className="ri-pencil-fill align-bottom"></i>
                </Link>
              </li>
              <li className=" list-inline-item me-0" title={t('Preview')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handlePreviewNews(item) }}
                >
                  <i className="ri-eye-fill align-bottom"></i>
                </Link>
              </li>

              <li className="list-inline-item me-0" title={t('Button Refresh News')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handleReCreateNews(item) }}
                >
                  <i className="ri-refresh-line align-bottom"></i>
                </Link>
              </li>

              <li className=" list-inline-item me-0" title={t('Button Get Script Tracking News')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handleGetScriptTrackingNews(item) }}
                >
                  {(String(keyScriptTrackingLoading) === String(cell?.row?.id)) ? <Spinner size="sm"></Spinner> : <i className="ri-code-fill align-bottom"></i>}
                </Link>
              </li>

              <li className=" list-inline-item me-0" title={t('Button Downloading News As Word Format')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handleDownloadNews(item) }}
                >
                  <i className="ri-download-2-fill align-bottom"></i>
                </Link>
              </li>
              <li className="list-inline-item me-0" title={t('Button Delete News')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                >
                  <i className="ri-delete-bin-3-fill align-bottom"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [i18n?.language, keyLoading, keyScriptTrackingLoading]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);


  const handleCallAllOption = async () => {
    try {
      const [resTopic]: any = await Promise.all([getAllMedias()]);
      setListMedia((_prev) => resTopic?.data || []);
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);


  useEffect(() => {
    document.title = `${t('News')} - ${t('Campaign')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('News')} pageTitle={t('Campaign')} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center mb-2 mt-2">
                    <Col sm={12} md={3} lg={3} className='mt-2'>
                      <div>
                        <SearchFilterKeyword initialValue={keywordsSearch || []} onChangeSelect={(event) => {
                          setKeywordsSearch((_prev: any) => event);
                        }} />
                      </div>
                    </Col>
                    <Col sm={12} md={3} lg={3} className='mt-2'>
                      <Select
                        options={STATUS_KEYWORD_OPTIONS_LANG}
                        value={statusSearch || null}
                        onChange={(e: any) => setStatusSearch(e)}
                        placeholder={<div>{`${t('Status')}...`}</div>}
                        className="search-filter-category-type"
                        classNamePrefix="name-prefix"
                      />
                    </Col>
                    <Col sm={12} md={3} lg={3} className="mt-2 date-picker-wrapper-custom">
                      <DatePicker
                        selectsRange={true}
                        className="form-control search"
                        placeholderText={`${t('Date News')}...`}
                        startDate={startDate || null}
                        endDate={endDate || null}
                        dateFormat="yyyy-MM-dd"
                        isClearable={true}
                        locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                        onChange={handleChangePicker}
                      />
                    </Col>
                  </Row>
                  <Row className="g-4 align-items-center mt-0">
                    <Col sm={12} md={3} lg={3} className='mt-2'>
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={`${t('Title News')}...`}
                        value={keywordSearch}
                        onChange={(e) => setKeywordSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            searchData();
                          }
                        }}
                      />
                    </Col>
                    <Col sm={12} md={3} lg={3} className='mt-2'>
                      <div>
                        <SearchFilterMedia name="medias"
                          dataList={listMedia}
                          initialValue={mediasSearch || []}
                          onChangeSelect={(event: any) => {
                            setMediasSearch((_prev) => event);
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={3} lg={3} className='mt-2'>
                      <div>
                        <SearchFilterCampaign
                          limitShow={30}
                          initialValue={campaignSearch}
                          onChangeSelect={(event) => {
                            setCampaignSearch((_prev: any) => event);
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm={12} md={3} lg={3} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2">
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary me-1"
                          onClick={searchData}
                          disabled={isNewsLoading}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t('Button Search')}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t('Button Reset')}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={news?.list?.length ? news?.list : []}
                      customPageSize={query.limit}
                      customPageCount={Math.ceil(Number(news?.pagination?.total) / Number(news?.pagination?.limit))}
                      customPageIndex={query.page - 1}
                      totalRecords={news?.pagination?.total}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isNewsLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={isOpenPreview} centered={true} size="xl" scrollable={true} toggle={onClosePreviewClick} keyboard={true}>
        <ModalHeader toggle={onClosePreviewClick}>
          {t('Preview')}
        </ModalHeader>
        <ModalBody className="">
          <div className="row g-4">
            <Col md={12}>
              <div>
                <div className="row g-3">
                  <Col xxl={12} className="mt-0">
                    <div className="bg-primary-subtle position-relative"><div className="p-3 pt-4 card-body"><div className="text-center">
                      <h3 className="fw-semibold">{previewTemplate?.title || ''}</h3>
                      <p className="mb-0 text-muted">{t('Created at')}: {previewTemplate?.created_at || ''} </p>
                    </div>
                    </div>
                      {/* <div className="shape">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="1440" height="60" preserveAspectRatio="none" viewBox="0 0 1440 60"><g mask="url(&quot;#SvgjsMask1001&quot;)" fill="none"><path d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z" style={{ fill: 'var(--vz-secondary-bg)' }}></path></g><defs><mask id="SvgjsMask1001"><rect width="1440" height="60" fill="#ffffff"></rect></mask></defs></svg>
                      </div> */}
                    </div>
                  </Col>
                  <Col xxl={12}>
                    <div dangerouslySetInnerHTML={{ __html: previewTemplate?.content || '' }}></div>
                  </Col>
                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-end">
                      <button className="btn btn-success fs-14" type="button" color="light" disabled={isLoadingDownloadFile} onClick={() => handleDownloadNews(previewTemplate)}>
                        {isLoadingDownloadFile ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-download-2-fill align-bottom me-1"></i>}
                        {t('Button Downloading News As Word Format')}
                      </button>
                      <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onClosePreviewClick}>
                        <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                        {t('Button Close')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={isOpenEdit} centered={true} size="xl" scrollable={true} className="modal-fullscreen" toggle={onCloseEditClick} keyboard={true}>
        <ModalHeader toggle={onCloseEditClick}>
          {t('Button Update News')}
        </ModalHeader>
        <ModalBody className="">
          <div className="row g-4">
            <Col md={12}>
              <div>
                <div className="row g-3">
                  <Col xxl={12} className="mt-0">
                    <div className="bg-primary-subtle position-relative"><div className="p-3 pt-4 card-body"><div className="text-center">
                      <textarea className="fs-3 form-control fw-semibold text-center bg-none mb-3" style={{ borderRadius: '10px' }} defaultValue={previewTemplate?.title} rows={1} ref={refTitleNews} />
                      <p className="mb-0 text-muted">{t('Created at')}: {previewTemplate?.created_at || ''} </p>
                    </div>
                    </div>
                      {/* <div className="shape">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="1440" height="60" preserveAspectRatio="none" viewBox="0 0 1440 60"><g mask="url(&quot;#SvgjsMask1001&quot;)" fill="none"><path d="M 0,4 C 144,13 432,48 720,49 C 1008,50 1296,17 1440,9L1440 60L0 60z" style={{ fill: 'var(--vz-secondary-bg)' }}></path></g><defs><mask id="SvgjsMask1001"><rect width="1440" height="60" fill="#ffffff"></rect></mask></defs></svg>
                      </div> */}
                    </div>
                  </Col>
                  <Col xxl={12}>
                    <Editor
                      onInit={(evt, editor) => refContentNews.current = editor}
                      apiKey='your-api-key'
                      initialValue={previewTemplate?.content || ''}
                      init={{
                        height: 'calc(100dvh - 320px)',
                        menubar: false,
                        plugins: PLUGINS,
                        toolbar: TOOLBAR,
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        branding: false,
                        // I18n: i18n
                      }}
                    />
                  </Col>
                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-end">
                      <button className="btn btn-success fs-14" type="button" color="light" disabled={isLoadingUpdate} onClick={() => handleUpdateNews(previewTemplate)}>
                        {isLoadingUpdate ? <Spinner size="sm"></Spinner> : <i className="ri-login-circle-line align-bottom ms-2 me-1"></i>}
                        {t('Button Update News')}
                      </button>
                      <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onCloseEditClick}>
                        <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                        {t('Button Close')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={isOpenCopyScriptTracking} centered={true} size="xl" scrollable={true} toggle={onCloseCopyScriptTrackingClick} keyboard={true}>
        <ModalHeader toggle={onCloseCopyScriptTrackingClick}>
          {t('Script Tracking News')}
        </ModalHeader>
        <ModalBody className="">
          <div className="row g-4">
            <Col md={12}>
              <div>
                <div className="row g-3">
                  <Col xxl={12} className="m-0">
                    <div className='my-3'>
                      <div className="p-4" style={{ color: 'var(--vz-success)', backgroundColor: 'var(--vz-dark)' }}>{scriptTracking || ''}</div>
                    </div>
                  </Col>
                  <div className="col-lg-12">
                    <div className="hstack gap-2 justify-content-end">
                      <button className="btn btn-primary fs-14" type="button" color="light" onClick={() => copyTextToClipboard(scriptTracking || '')}>
                        <i className="ri-file-copy-line align-bottom me-1"></i>
                        {t('Button Copy Script Tracking')}
                      </button>
                      <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onCloseCopyScriptTrackingClick}>
                        <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                        {t('Button Close')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
      <ModalConfirm
        header={t('Button Delete News')}
        isOpen={isOpenConfirm}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmClick}
        onConfirm={handleActionDelete}
      />
      <ModalConfirm
        textButtonConfirm={t('Button Refresh News')}
        classButtonConfirm='primary'
        classIconButtonConfirm='ri-refresh-line'
        header={t('Button Refresh News')}
        title={t('Do you want to refresh content of news!')}
        content={t('After confirming the refresh action, the data will be updated and cannot be recovered. Do you want to proceed with the refresh action.')}
        isShowIcon={false}
        isOpen={isOpenConfirmReCreate}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmReCreateClick}
        onConfirm={handleActionReCreate}
      />
    </React.Fragment>
  );
};

export default NewsList;
