import { createSlice } from "@reduxjs/toolkit";
import { getNews } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { INews } from "api/types/_news";


export interface IState {
  news: ResponseData<INews[]> & PaginationResponse | null,
  isNewsLoading: boolean,
  isNewsSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  news: null,
  isNewsLoading: false,
  isNewsSuccess: false,

  error: {},
};

const NewsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //get Newss
    builder.addCase(getNews.pending, (state: IState, action: any) => {
      state.isNewsLoading = true
    });
    builder.addCase(getNews.fulfilled, (state: IState, action: any) => {
      state.news = action.payload.data;
      state.isNewsSuccess = true;
      state.isNewsLoading = false;
    });
    builder.addCase(getNews.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isNewsSuccess = false;
      state.isNewsLoading = false;
    });
  },
});

export default NewsSlice.reducer;