import { createSlice } from "@reduxjs/toolkit";


export interface IState {
  error: any,
};

export const initialState: IState = {
  error: {},
};

const ChatgptSlice = createSlice({
  name: "Chatgpt",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get 
  },
});

export default ChatgptSlice.reducer;