import { createSlice } from "@reduxjs/toolkit";
import { getCampaigns } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ICampaign } from "api/types/_campaign";


export interface IState {
  campaigns: ResponseData<ICampaign[]> & PaginationResponse | null,
  isCampaignLoading: boolean,
  isCampaignSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  campaigns: null,
  isCampaignLoading: false,
  isCampaignSuccess: false,

  error: {},
};

const CampaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //get campaigns
    builder.addCase(getCampaigns.pending, (state: IState, action: any) => {
      state.isCampaignLoading = true
    });
    builder.addCase(getCampaigns.fulfilled, (state: IState, action: any) => {
      state.campaigns = action.payload.data;
      state.isCampaignSuccess = true;
      state.isCampaignLoading = false;
    });
    builder.addCase(getCampaigns.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCampaignSuccess = false;
      state.isCampaignLoading = false;
    });
  },
});

export default CampaignSlice.reducer;