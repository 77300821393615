import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import topicApi from 'api/topicApi';

export const getTopics = createAsyncThunk("Topics" , async (params: any = {}) => {
  try{
    const response = await topicApi.Topics(params)
    return response;
  }catch (error) {
    return error;
  }
});

export const getAllTopics =  async(params: any = {}) => {
  try{
    const response = await topicApi.allTopics(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const postTopic = async (data: any = {}) => {
  try{
    const response = await topicApi.postTopic(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putTopic = async (id: string | number , data: any = {}) => {
  try{
    const response = await topicApi.putTopic(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteTopic = async (id: string | number , data: any = {}) => {
  try{
    const response = await topicApi.deleteTopic(id, data);
    return response;
  }catch (error) {
    return error;
  }
};
