import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import movieApi from 'api/movieApi';

export const getCategoriesMovie = createAsyncThunk("cars/getCategoriesCar" , async (params: any = {}) => {
  try{
    const response = await movieApi.categories(params)
    return response;
  }catch (error) {
    return error;
  }
})

export const getMovies = createAsyncThunk("movies" , async (params: any = {}) => {
  try{
    const response = await movieApi.movies(params)
    return response;
  }catch (error) {
    return error;
  }
})

export const importMovies = createAsyncThunk("movies/import" , async (data: any = {}) => {
  try{
    const response = await movieApi.importMovies(data);
    return response;
  }catch (error) {
    return error;
  }
});

export const importMoviesByNormal =  async (data: any = {}) => {
  try{
    const response = await movieApi.importMovies(data);
    return response;
  }catch (error) {
    return error;
  }
};
