import { createSlice } from "@reduxjs/toolkit";
import { getCategoriesMovie, getMovies, importMovies } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IMovie, ICategoryMovie } from "api/types/_movie";


export interface IState {
  categories: ResponseData<ICategoryMovie[]> & PaginationResponse | null,
  isCategoryLoading : boolean,
  isCategorySuccess : boolean,

  movies: ResponseData<IMovie[]> & PaginationResponse | null,
  isMovieLoading: boolean,
  isMovieSuccess: boolean,

  importMovies: ResponseData<IMovie[]> & PaginationResponse | null,
  isImportMovieLoading: boolean,
  isImportMovieSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  categories: null,
  isCategoryLoading : false,
  isCategorySuccess : false,

  movies: null,
  isMovieLoading: false,
  isMovieSuccess: false,

  importMovies: null,
  isImportMovieLoading: false,
  isImportMovieSuccess: false,

  error: {},
};

const movieSlice = createSlice({
  name: "movie",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    // get categories
    builder.addCase(getCategoriesMovie.pending, (state: IState, action: any) => {
      state.isCategoryLoading = true
    });
    builder.addCase(getCategoriesMovie.fulfilled, (state: IState, action: any) => {
      state.categories = action.payload.data;
      state.isCategorySuccess = true;
      state.isCategoryLoading = false;
    });

    builder.addCase(getCategoriesMovie.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCategorySuccess = false;
      state.isCategoryLoading = false;
    });

    //get movies
    builder.addCase(getMovies.pending, (state: IState, action: any) => {
      state.isMovieLoading = true
    });
    builder.addCase(getMovies.fulfilled, (state: IState, action: any) => {
      state.movies = action.payload.data;
      state.isMovieSuccess = true;
      state.isMovieLoading = false;
    });
    builder.addCase(getMovies.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMovieSuccess = false;
      state.isMovieLoading = false;
    });

    //import movies
    builder.addCase(importMovies.pending, (state: IState, action: any) => {
      state.isImportMovieLoading = true
    });
    builder.addCase(importMovies.fulfilled, (state: IState, action: any) => {
      state.importMovies = action.payload.data;
      state.isImportMovieSuccess = true;
      state.isImportMovieLoading = false;
    });
    builder.addCase(importMovies.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isImportMovieSuccess = false;
      state.isImportMovieLoading = false;
    });
  },
});

export default movieSlice.reducer;