import { Navigate } from "react-router-dom";

import { useProfile } from "../components/Hooks/UserHooks";


const AuthProtected = (props : any) =>{
  const { userProfile, loading } = useProfile();

  /*
    Navigate is un-auth access protected routes via url
    */

  if (!userProfile && loading) {
    return (
      <Navigate to={{ pathname: "/login"}} />
    );
  }

  return <>{props.children}</>;
};


export default AuthProtected;