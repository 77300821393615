import { IMedia } from 'api/types/_media';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import Select from "react-select";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { detailNews, getAllMedias, getNewsLogsByNormal, getNewsLogs as onGetNewsLogs } from "../../../store/thunks";


import SearchFilterMedia from 'components/Common/SearchFilterMedia';
import { IPreviewNews } from 'api/types/_news';
import { CONFIG_OPTION_TOAST_ERROR } from 'common/toast';
import { INewsLog } from 'api/types/_statistic';
import { PLATFORM_LOG_OPTIONS } from 'helpers/constans';
import CountUp from "react-countup";
interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'createdAt';

registerLocale('en', en);
registerLocale('ko', ko);


const NewsLogList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const PLATFORM_LOG_OPTIONS_LANG = PLATFORM_LOG_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const [query, setQuery]: any = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    media_id: withDefault(StringParam, ''),
    news_title: withDefault(StringParam, ''),
    keyword: withDefault(StringParam, ''),
    start_date: withDefault(StringParam, ''),
    end_date: withDefault(StringParam, ''),
    platform: withDefault(StringParam, ''),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date || "", query?.end_date || ""]);
  const [startDate, endDate] = dateSearch;

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [newsTitleSearch, setNewsTitleSearch] = useState<string>(query?.news_title || "");

  const [mediaSearch, setMediaSearch] = useState<Option | null>(null);
  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [platformSearch, setPlatformSearch] = useState<Option | null>(null);

  const [isOpenPreview, setIsOpenPreview] = useState<boolean>(false);
  const [previewNews, setPreviewNews] = useState<IPreviewNews | null>(null);

  const [previewNewsLog, setPreviewNewsLog] = useState<INewsLog | null>(null);

  // Inside your component

  const [newsLogs, setNewsLogs] = useState<{ list: INewsLog[], total: number }>({ list: [], total: 0 });
  const [isNewsLogLoading, setIsNewsLogLoading] = useState<boolean>(false);

  const handleQueryData = async () => {
    setIsNewsLogLoading((prev) => true);
    const res: any = await getNewsLogsByNormal(query);
    setNewsLogs((prev: any) => ({
      list: (prev?.list || []).concat(res?.data?.list || []),
      total: res?.data?.pagination?.total || 0
    }));
    setIsNewsLogLoading((prev) => false);
  };

  const handleLoadMore = () => {
    setQuery({
      ...query,
      page: query?.page + 1
    });
  }

  useEffect(() => {
    handleQueryData();
  }, [JSON.stringify(query)]);

  const searchData = () => {
    setNewsLogs((prev: any) => ({
      list: [],
      total: 0,
    }));
    setQuery({
      ...query,
      news_title: newsTitleSearch || undefined,
      keyword: keywordSearch || undefined,
      media_id: mediaSearch?.value || '',
      platform: platformSearch?.value || '',
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
    });
  };

  const resetData = () => {
    setNewsLogs((prev: any) => ({
      list: [],
      total: 0,
    }));
    setQuery({
      news_title: undefined,
      keyword: undefined,
      media_id: undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      start_date: undefined,
      end_date: undefined,
      platform: undefined
    }, "push")
    setKeywordSearch((_prev) => "");
    setNewsTitleSearch((_prev) => "");
    setMediaSearch(null);
    setDateSearch(["", ""]);
    setPlatformSearch(null);
  };

  function onClosePreviewClick() {
    setIsOpenPreview((_prev) => !_prev);
  }

  const handlePreviewNews = async (idNews: string | number) => {
    try {
      const resNews: any = await detailNews(String(idNews));
      if (resNews?.data) {
        setPreviewNews((_prev) => resNews?.data || []);
        setIsOpenPreview((_prev) => true);
      } else {
        toast(`${resNews}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };


  function onClosePreviewNewsLogClick() {
    setPreviewNewsLog((_prev) => null);
  }

  const handlePreviewNewsLog = async (newsLog: INewsLog | null = null) => {
    try {
      setPreviewNewsLog((_prev) => newsLog);
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };


  const formatDate = (strDate: string = '') => {
    return `${String(strDate).split('T')[0]} ${String(String(strDate).split('T')[1]).split('.')[0]}`;
  }
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('News Title'),
        accessor: "news_title",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div style={{ maxWidth: '30dvw' }}>
              <Link className='text-normal' to={item?.domain_url} target="_blank">
                {item?.news_title}
              </Link>
            </div>
          </>)
        },
      },
      {
        Header: t('Keyword'),
        accessor: "keyword",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="cursor-pointer">{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('Media'),
        accessor: "media",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="cursor-pointer">{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('Platform'),
        accessor: "platform",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('OS'),
        accessor: "os",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Browser'),
        accessor: "browser",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('IP'),
        accessor: "ip",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{formatDate(cell?.value)}</span>
          </>
        ),
      },
      {
        Header: t('Button Action'),
        thClass: 'text-center',
        thWidth: 60,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0">
              <li className=" list-inline-item me-0" title={t('Detail News Log')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handlePreviewNewsLog(item) }}
                >
                  <i className="ri-eye-fill align-bottom"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [i18n?.language]
  );

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);


  const handleCallAllOption = async () => {
    try {
      const [resTopic]: any = await Promise.all([getAllMedias()]);
      setListMedia((_prev) => resTopic?.data || []);
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    document.title = `${t('News Log')} - ${t('Statistics')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('News Log')} pageTitle={t('Statistics')} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-center mb-2 mt-2">
                    <Card className="card-animate mb-0 me-0 me-md-4 mt-2 bg-primary-subtle text-primary order-1 order-md-0 border-0" style={{ width: '200px' }}>
                      <CardBody>
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-primary text-truncate mb-0">{t('Total')}</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                              <span className="counter-value text-primary">
                                <CountUp
                                  start={0}
                                  end={newsLogs?.total || 0}
                                  duration={1}
                                />
                              </span></h4>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <div className="w-100">
                      <Row className="g-4 align-items-center mb-2 mt-2">
                        <Col sm={12} lg={3} className='mt-2'>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('Keyword')}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>
                        <Col sm={12} md={3} lg={3} className="mt-2 date-picker-wrapper-custom">
                          <DatePicker
                            selectsRange={true}
                            className="form-control search"
                            placeholderText={`${t('Date News')}...`}
                            startDate={startDate || null}
                            endDate={endDate || null}
                            dateFormat="yyyy-MM-dd"
                            isClearable={true}
                            locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                            onChange={handleChangePicker}
                          />
                        </Col>
                      </Row>
                      <Row className="g-4 align-items-center mb-2 mt-2">

                        <Col sm={12} md={3} lg={3} className='mt-2'>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t('News Title')}...`}
                            value={newsTitleSearch}
                            onChange={(e) => setNewsTitleSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </Col>
                        <Col sm={12} md={3} lg={3} className='mt-2'>
                          <div>
                            <SearchFilterMedia
                              name="medias"
                              isMulti={false}
                              dataList={listMedia}
                              initialValue={mediaSearch}
                              onChangeSelect={(event: any) => {
                                setMediaSearch((_prev) => event);
                              }}
                            />
                          </div>
                        </Col>
                        <Col sm={12} md={3} lg={3} className='mt-2'>
                          <Select
                            options={PLATFORM_LOG_OPTIONS_LANG}
                            value={platformSearch || null}
                            onChange={(e: any) => setPlatformSearch(e)}
                            placeholder={<div>{`${t('Platform')}...`}</div>}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                          />
                        </Col>
                        <Col sm={12} md={3} lg={3} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2">
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-1"
                              onClick={searchData}
                              disabled={isNewsLogLoading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t('Button Search')}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t('Button Reset')}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                </CardHeader>
                <CardBody className="pt-3">

                  <div>
                    <InfiniteScroll
                      dataLength={newsLogs?.list?.length || 0}
                      next={handleLoadMore}
                      scrollableTarget='scrollableDiv'
                      hasMore={newsLogs && newsLogs?.list?.length < newsLogs?.total ? true : false}
                      loader={''} // loader={<LoadingListNotify />}
                      scrollThreshold={'50%'}
                    >
                      <div className="mx-3 my-4">
                        <TableContainer
                          className="custom-header-css"
                          divClass="table-card"
                          tableClass="align-middle"
                          theadClass="table-light"
                          columns={columns}
                          data={newsLogs?.list?.length ? newsLogs?.list : []}
                          customPageSize={query.limit}
                          customPageIndex={query.page - 1}
                          totalRecords={newsLogs?.total}
                          customPageCount={1}
                          handleChangePage={handleChangePage}
                          manualSorting={true}
                          sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                          handleChangeSorting={handleChangeSorting}
                          isLoading={isNewsLogLoading}
                          isShowPagination={false}
                        />
                      </div>
                    </InfiniteScroll>
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={isOpenPreview} centered={true} size="xl" scrollable={true} toggle={onClosePreviewClick} keyboard={true}>
          <ModalHeader toggle={onClosePreviewClick}>
            {t('Preview')}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={12}>
                <div>
                  <div className="row g-3">
                    <Col xxl={12} className="mt-0">
                      <div className="bg-primary-subtle position-relative"><div className="p-3 pt-4 card-body"><div className="text-center">
                        <h3 className="fw-semibold">{previewNews?.title || ''}</h3>
                        <p className="mb-0 text-muted">{t('Created at')}: {previewNews?.created_at || ''} </p>
                      </div>
                      </div>
                      </div>
                    </Col>
                    <Col xxl={12}>
                      <div dangerouslySetInnerHTML={{ __html: previewNews?.content || '' }}></div>
                    </Col>
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onClosePreviewClick}>
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t('Button Close')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={!!previewNewsLog} centered={true} size="xl" scrollable={true} toggle={onClosePreviewNewsLogClick} keyboard={true}>
          <ModalHeader toggle={onClosePreviewNewsLogClick}>
            {t('Detail News Log')}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={12}>
                <div>
                  <div className="row g-3">
                    <Col xxl={12} className="mt-0">
                      <div className="tab-content border border-bottom-0">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">{t('ID')}</th>
                                <td>{previewNewsLog?.id}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('News ID')}</th>
                                <td>
                                  <Link className='text-normal' to="#" onClick={(e) => { e.preventDefault(); handlePreviewNews(previewNewsLog?.news_id || '') }}>
                                    {previewNewsLog?.news_id}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">{t('News Title')}</th>
                                <td>
                                  <Link className='text-normal' to="#" onClick={(e) => { e.preventDefault(); handlePreviewNews(previewNewsLog?.news_id || '') }}>
                                    {previewNewsLog?.news_title}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Keyword')}</th>
                                <td>{previewNewsLog?.keyword}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Media')}</th>
                                <td>{previewNewsLog?.media_name}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Platform')}</th>
                                <td>{previewNewsLog?.platform}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('OS')}</th>
                                <td>{previewNewsLog?.os}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('IP')}</th>
                                <td>{previewNewsLog?.ip}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Browser')}</th>
                                <td>{previewNewsLog?.browser}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Agent')}</th>
                                <td>{previewNewsLog?.agent}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Reference Link')}</th>
                                <td>
                                  <Link className='text-normal' to={previewNewsLog?.reference_link || ''} target="_blank">
                                    {previewNewsLog?.reference_link}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Domain Url')}</th>
                                <td>
                                  <Link className='text-normal' to={previewNewsLog?.domain_url || ''} target="_blank">
                                    {previewNewsLog?.domain_url}
                                  </Link>
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Created at')}</th>
                                <td>{formatDate(previewNewsLog?.created_at)}</td>
                              </tr>
                              <tr>
                                <th scope="row">{t('Updated at')}</th>
                                <td>{formatDate(previewNewsLog?.updated_at)}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onClosePreviewNewsLogClick}>
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t('Button Close')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
          </ModalBody>
        </Modal >
      </div >
    </React.Fragment >
  );
};

export default NewsLogList;