import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import roleApi from 'api/roleApi';

export const getRoles = createAsyncThunk("Roles" , async (params: any = {}) => {
  try{
    const response = await roleApi.roles(params)
    return response;
  }catch (error) {
    return error;
  }
});

export const getAllRoles =  async(params: any = {}) => {
  try{
    const response = await roleApi.allRoles(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const postRole = async (data: any = {}) => {
  try{
    const response = await roleApi.postRole(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putRole = async (id: string | number , data: any = {}) => {
  try{
    const response = await roleApi.putRole(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteRole = async (id: string | number , data: any = {}) => {
  try{
    const response = await roleApi.deleteRole(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const getAllPermissions =  async(params: any = {}) => {
  try{
    const response = await roleApi.allPermissions(params)
    return response;
  }catch (error) {
    return error;
  }
};