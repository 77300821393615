import { createSlice } from "@reduxjs/toolkit";
import { getNewsLogs } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { INewsLog } from "api/types/_statistic";


export interface IState {
  newsLogs: ResponseData<INewsLog[]> & PaginationResponse | null,
  isNewsLogLoading: boolean,
  isNewsLogSuccess: boolean,


  error: any,
};

export const initialState: IState = {
  newsLogs: null,
  isNewsLogLoading: false,
  isNewsLogSuccess: false,

  error: {},
};

const StatisticSlice = createSlice({
  name: "Statistic",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get NewsLogs
    builder.addCase(getNewsLogs.pending, (state: IState, action: any) => {
      state.isNewsLogLoading = true
    });
    builder.addCase(getNewsLogs.fulfilled, (state: IState, action: any) => {
      state.newsLogs = action.payload.data;
      state.isNewsLogSuccess = true;
      state.isNewsLogLoading = false;
    });
    builder.addCase(getNewsLogs.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isNewsLogSuccess = false;
      state.isNewsLogLoading = false;
    });
  },
});

export default StatisticSlice.reducer;