import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { ITopic } from "./types/_topic";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/topics';
const topicApi = {
  Topics(params: any):Promise<AxiosResponse<ResponseData<ITopic[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  allTopics(params: any = {}):Promise<AxiosResponse<ResponseData<ITopic[]>, any>>  {
    const url = `masters/topics`
    return api.get(url, params)
  },
  postTopic(dataForm: any) : Promise<AxiosResponse<ResponseData<ITopic>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putTopic(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ITopic>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteTopic(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ITopic>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
}
export default topicApi
