import { createSlice } from "@reduxjs/toolkit";
import { getMedias, getAllMedias } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { IMedia } from "api/types/_media";


export interface IState {
  medias: ResponseData<IMedia[]> & PaginationResponse | null,
  isMediaLoading: boolean,
  isMediaSuccess: boolean,

  allMedia: ResponseData<IMedia[]> | null,

  error: any,
};

export const initialState: IState = {
  medias: null,
  isMediaLoading: false,
  isMediaSuccess: false,

  allMedia: null,

  error: {},
};

const MediaSlice = createSlice({
  name: "Media",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Medias
    builder.addCase(getMedias.pending, (state: IState, action: any) => {
      state.isMediaLoading = true
    });
    builder.addCase(getMedias.fulfilled, (state: IState, action: any) => {
      state.medias = action.payload.data;
      state.isMediaSuccess = true;
      state.isMediaLoading = false;
    });
    builder.addCase(getMedias.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isMediaSuccess = false;
      state.isMediaLoading = false;
    });
  },
});

export default MediaSlice.reducer;