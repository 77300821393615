import { IMedia, IMediaCode, IType } from 'api/types/_media';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import * as Yup from 'yup';
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { deleteMedia, getAllTypes, getMedias as onGetMedias, postMedia, putMedia, refreshKey } from "../../../store/thunks";


import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import FilterTypes from 'components/Common/FilterTypes';
import ModalConfirm from 'components/Common/ModalConfirm';
import Types from 'components/Common/Types';
import { TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS } from 'helpers/constans';
interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'id';

registerLocale('en', en);
registerLocale('ko', ko);

const SECTION_DEFAULT: IMediaCode = {
  id: String(+new Date()),
  section_title: '',
  section_code: '',
  reporter: '',
  reporter_id: ''
};

const MediaList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [query, setQuery]: any = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    // order_by: withDefault(StringParam, `DESC`),
    // date_version: withDefault(StringParam, ''),
    name: withDefault(StringParam, ''),
    domain_ids: withDefault(StringParam, ''),
    type: withDefault(StringParam, ''),
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [isOpenConfirmRefreshKey, setIsConfirmRefreshKey] = useState<boolean>(false);
  const [isConfirmRefreshKeyLoading, setIsConfirmRefreshKeyLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<IMedia | null>(null);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
  const [formatSearch, setFormatSearch] = useState<Option | null>(null);
  const [typesSearch, setTypesSearch] = useState<Option[]>([]);

  const [passwordShow, setPasswordShow] = useState<boolean>(false);

  const [listType, setListType] = useState<IType[]>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Media;

  const MediaProperties = createSelector(
    selectLayoutState,
    (state) => ({
      medias: state.medias,
      isMediaSuccess: state.isMediaSuccess,
      isMediaLoading: state.isMediaLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { medias, error, isMediaLoading } = useSelector(MediaProperties);

  useEffect(() => {
    dispatch(onGetMedias(query));
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      name: keywordSearch || undefined,
      type: formatSearch?.value || undefined,
      domain_ids: (typesSearch || [])?.map((item) => item?.value).join(',') || '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
    });
  };

  const resetData = () => {
    setQuery({
      name: undefined,
      domain_ids: undefined,
      sort_by: undefined,
    }, "push")
    setKeywordSearch((_prev) => "");
    setFormatSearch((_prev) => null);
    setTypesSearch((_prev) => []);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Media Name'),
        accessor: "name",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="cursor-pointer" onClick={() => handleEdit(item)}>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('Description'),
        accessor: "description",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div className="cursor-pointer" onClick={() => handleEdit(item)}>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('Types Media'),
        accessor: "types",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const types = value?.domains || [];
          let tagName: string = "";
          for (let item of types) {
            tagName += `${item ? `<span class='tag-style'>${item?.name} </span>` : ''}`;
          }
          return (
            <div dangerouslySetInnerHTML={{ __html: tagName }} />
          );
        }
      },
      {
        Header: t('Media transmission format classification'),
        accessor: "type",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            {cell?.value ? <div className='tag-style'>{t(`Format_Type_[${cell?.value}]_For_Media`)}</div> : ''}
          </>
        ),
      },
      {
        Header: t('Total Section'),
        accessor: "partner_codes",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            {cell?.value ? <div>{(cell?.value || [])?.length}</div> : ''}
          </>
        ),
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Button Action'),
        thClass: 'text-center',
        thWidth: 140,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline hstack gap-1 mb-0" style={{ width: '80px' }}>
              <li className="list-inline-item" title={t('Button Detail Media')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handleEdit(item, true) }}
                >
                  <i className="ri-eye-fill align-bottom "></i>
                </Link>
              </li>
              <li className="list-inline-item" title={t('Button Update Media')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handleEdit(item) }}
                >
                  <i className="ri-pencil-fill align-bottom "></i>
                </Link>
              </li>
              <li className="list-inline-item" title={t('Button Delete Media')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                >
                  <i className="ri-delete-bin-3-fill align-bottom"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [i18n?.language]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  // Begin::Delete
  const handleConfirmDelete = (item: IMedia) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteMedia(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetMedias(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }

  const onCloseConfirmRefreshKeyClick = () => {
    setIsConfirmRefreshKey((_prev) => false);
  }
  // End::Delete

  // Form

  function onCloseClick() {
    setIsOpen((_prev) => !_prev);
    setTimeout(() => {
      formik.setErrors({});
    }, 300)
  }

  function onCreateClick() {
    setInitialValuesDefault((_prev) => null);
    handleResetForm();
    setIsOpen((_prev) => true);
  }

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        name: values?.name ?? '',
        description: values?.description ?? '',
        type: values?.format?.value ?? '',
        topic_names: [],
        domains: (values?.types || [])?.map((item: Tag) => item?.id) || [],
        media_codes: (values?.sections || [])?.map((item: any) => ({
          section_title: item?.section_title,
          section_code: item?.section_code,
          reporter: item?.reporter,
          reporter_id: item?.reporter_id
        })) || [],
      };
      const response: any = !!(initialValuesDefault?.id) ? await putMedia(initialValuesDefault?.id, data) : await postMedia(data);
      if (response?.data) {
        dispatch(onGetMedias(query));
        setIsLoading((_prev) => false);
        setIsOpen((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };


  const handleActionRefreshKey = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmRefreshKeyLoading((_prev) => true);
      const response: any = await refreshKey(initialValuesDefault?.id);
      if (response?.data) {
        dispatch(onGetMedias(query));
        setIsConfirmRefreshKeyLoading((_prev) => false);
        setIsConfirmRefreshKey((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        formik.setFieldValue('api_secret', response?.data?.api_secret);
        formik.setFieldValue('api_key', response?.data?.api_key);
      } else {
        setIsConfirmRefreshKeyLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmRefreshKeyLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const sectionSchema = Yup.object().shape({
    id: Yup.string(),
    section_title: Yup.string().required(`${t('Title is required')}`),
    section_code: Yup.string().required(`${t('Code is required')}`),
    reporter: Yup.string().required(`${t('Reporter is required')}`),
    reporter_id: Yup.string().required(`${t('Reporter ID is required')}`),
  });

  const validationSchema = Yup.object({
    name: Yup.string().required(`${t('Media Name is required')}`),
    description: Yup.string().nullable(),
    id: Yup.string().nullable(),
    api_secret: Yup.string().nullable(),
    api_key: Yup.string().nullable(),
    format: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }).required(`${t('Format is required')}`),
    types: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(`${t('Please provide a label')}`),
        text: Yup.string().required(`${t('Please provide a value')}`),
      })
    ).required(`${t('Types is required')}`).min(1, `${t('At least 1 type is required')}`),
    sections: Yup.array()
      .of(sectionSchema)
      .min(1, `${t('At least 1 section is required')}`)
      .required(`${t('At least 1 section is required')}`),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      id: '',
      api_secret: '',
      api_key: '',
      description: '',
      format: TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS[0],
      types: null,
      sections: [{ ...SECTION_DEFAULT, id: String(+new Date()) }]
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleEdit = (item: IMedia, isPreview: boolean = false) => {
    setPasswordShow((_prev) => false);
    setInitialValuesDefault((_prev) => ({ ...item, isPreview }));
    setIsOpen((_prev) => true);
    const vTags = (item?.domains)?.map((item: IType) => ({ id: item?.name, text: item?.name }));
    const vFormat = TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS?.filter((op) => op?.value === item?.type)[0];
    const vSections = (item?.partner_codes?.length ? item?.partner_codes : [{ ...SECTION_DEFAULT, id: String(+new Date()) }])?.map((item: IMediaCode) => (item));
    formik.setFieldValue('name', item?.name);
    formik.setFieldValue('id', item?.id);
    formik.setFieldValue('api_secret', item?.api_secret);
    formik.setFieldValue('api_key', item?.api_key);
    formik.setFieldValue('description', item?.description);
    formik.setFieldValue('format', vFormat);
    formik.setFieldValue('types', vTags);
    formik.setFieldValue('sections', vSections);
  }

  const handleResetForm = () => {
    formik.setFieldValue('name', '');
    formik.setFieldValue('id', '');
    formik.setFieldValue('api_secret', '');
    formik.setFieldValue('api_key', '');
    formik.setFieldValue('description', '');
    formik.setFieldValue('format', TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS[0]);
    formik.setFieldValue('types', null);
    formik.setFieldValue('sections', [{ ...SECTION_DEFAULT, id: String(+new Date()) }]);
    setTimeout(() => {
      formik.setErrors({});
    }, 300)
  };

  const handleCallAllOption = async () => {
    try {
      const [resType]: any = await Promise.all([getAllTypes()]);
      setListType((_prev) => resType?.data || []);
    } catch (error: any) {
      return error;
    }
  };

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', true, text);
    }
    toast(`${t('Copied Successfully')}`, CONFIG_OPTION_TOAST_NORMAL);
  };

  //@ts-ignore
  const initialValueTypes = formik?.values?.types as Tag[] || [];

  //@ts-ignore
  const suggestionsTypes = (listType || [])?.map((item) => ({ id: item?.name, text: item?.name })) as Tag[] || [];

  //@ts-ignore
  const initialValueSections = formik?.values?.sections || [];



  const handleRemoveSection = (section: any) => {
    const arr = [...initialValueSections]?.filter((item) => section?.id !== item?.id);
    formik.setFieldValue('sections', arr);
  };

  const handleAddSection = () => {
    const arr = [...initialValueSections].concat([{ ...SECTION_DEFAULT, id: String(+new Date()) }]);
    formik.setFieldValue('sections', arr);
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    document.title = `${t('Media')} - ${t('Media')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Media')} pageTitle={t('Media')} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className='mt-2'>
                    <Col lg={12} className="text-end  mb-2 mb-md-0 d-block d-md-none">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => onCreateClick()}
                      >
                        <i className="ri-upload-2-line align-bottom me-1"></i>{" "}
                        {t('Button Create Media')}
                      </button>
                    </Col>
                  </Row>
                  <Row className="g-4 align-items-center mb-2 mt-2">
                    <Col sm={12} md={2} lg={2} className='mt-2'>
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={`${t('Media Name')}...`}
                        value={keywordSearch}
                        onChange={(e) => setKeywordSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            searchData();
                          }
                        }}
                      />
                    </Col>
                    <Col sm={12} md={2} lg={2} className="mt-2">
                      <Select
                        options={TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS}
                        value={formatSearch || null}
                        onChange={(e: any) => setFormatSearch(e)}
                        placeholder={<div>{`${t('Transmission format')}...`}</div>}
                      />
                    </Col>
                    <Col sm={12} md={3} lg={3} className="mt-2">
                      <div>
                        <FilterTypes name="medias" dataList={listType} initialValue={typesSearch || []} onChangeSelect={(event: any) => {
                          setTypesSearch((_prev) => event);
                        }} />
                      </div>
                    </Col>
                    <Col sm={12} md={5} lg={5} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2">
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary me-1"
                          onClick={searchData}
                          disabled={isMediaLoading}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t('Button Search')}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t('Button Reset')}
                        </button>
                      </div>
                      <button
                        type="button"
                        className="btn btn-success d-none d-md-block"
                        onClick={() => onCreateClick()}
                      >
                        <i className="ri-add-fill align-bottom me-1"></i>{" "}
                        {t('Button Create Media')}
                      </button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={medias?.list?.length ? medias?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={medias?.pagination?.total}
                      customPageCount={Math.ceil(Number(medias?.pagination?.total) / Number(medias?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isMediaLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ModalConfirm
          header={t('Button Delete Media')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
        <Modal isOpen={isOpen} centered={true} scrollable={true} size="lg">
          <ModalHeader toggle={isLoading ? () => { } : onCloseClick}>{!!initialValuesDefault?.id ? t('Button Update Media') : t('Button Create Media')}</ModalHeader>
          <ModalBody className="py-3 px-5">
            <form onSubmit={formik.handleSubmit}>
              <div className="row g-3">
                <Col xxl={12}>
                  <label className="form-label">{t('Media Name')}<span className="text-danger"> *</span></label>
                  <input
                    className="form-control"
                    id="name"
                    name="name"
                    value={formik?.values?.name}
                    placeholder={`${t('Media Name')}...`}
                    onChange={(event: any) => formik.setFieldValue('name', event?.target?.value || '')}
                  />
                  {formik.touched.name && formik.errors.name ? (<div className="text-danger mt-2">{formik.errors.name}</div>) : null}
                </Col>
                {!!(initialValuesDefault?.isPreview) && (<>
                  <Col xxl={12}>
                    <label className="form-label">{t('Media ID')}</label>
                    <div className="d-flex">
                      <input
                        className="form-control"
                        id="id"
                        name="id"
                        disabled={true}
                        value={formik?.values?.id}
                        placeholder={`${t('Media ID')}...`}
                        onChange={(event: any) => formik.setFieldValue('id', event?.target?.value || '')}
                      />
                      <button className="btn btn-sm btn-soft-success ms-2 fs-14" onClick={() => copyTextToClipboard(formik?.values?.id)} type="button"><i className="ri-file-copy-line align-middle"></i></button>
                    </div>
                    {formik.touched.id && formik.errors.id ? (<div className="text-danger mt-2">{formik.errors.id}</div>) : null}
                  </Col>
                  <Col xxl={12}>
                    <label className="form-label">{t('Media API Key')}</label>
                    <div className="d-flex">
                      <input
                        className="form-control w-100"
                        id="api_key"
                        name="api_key"
                        disabled={true}
                        value={formik?.values?.api_key}
                        placeholder={`${t('Media API Key')}...`}
                        onChange={(event: any) => formik.setFieldValue('api_key', event?.target?.value || '')}
                      />
                      <button className="btn btn-sm btn-soft-success ms-2 fs-14" onClick={() => copyTextToClipboard(formik?.values?.api_key)} type="button"><i className="ri-file-copy-line align-middle"></i></button>
                    </div>
                    {formik.touched.api_key && formik.errors.api_key ? (<div className="text-danger mt-2">{formik.errors.api_key}</div>) : null}
                  </Col>
                  <Col xxl={12}>
                    <label className="form-label">{t('Media API Secret')}</label>
                    <div className="d-flex">
                      <div className="position-relative auth-pass-inputgroup w-100 ">
                        <input
                          className="form-control"
                          id="api_secret"
                          name="api_secret"
                          disabled={true}
                          type={passwordShow ? "text" : "password"}
                          value={formik?.values?.api_secret}
                          placeholder={`${t('Media API Secret')}...`}
                          onChange={(event: any) => formik.setFieldValue('api_secret', event?.target?.value || '')}
                        />
                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={() => setPasswordShow(!passwordShow)} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                      </div>
                      <button className="btn btn-sm btn-soft-primary ms-2 fs-14" onClick={() => setIsConfirmRefreshKey((prev) => true)} type="button"><i className="ri-refresh-line align-middle"></i></button>
                      <button className="btn btn-sm btn-soft-success ms-2 fs-14" onClick={() => copyTextToClipboard(formik?.values?.api_secret)} type="button"><i className="ri-file-copy-line align-middle"></i></button>
                    </div>

                    {formik.touched.api_secret && formik.errors.api_secret ? (<div className="text-danger mt-2">{formik.errors.api_secret}</div>) : null}
                  </Col>
                </>)
                }
                <Col xxl={12}>
                  <label className="form-label">{t('Description')}</label>
                  <input
                    className="form-control"
                    id="description"
                    name="description"
                    value={formik?.values?.description}
                    placeholder={`${t('Description')}...`}
                    onChange={(event: any) => formik.setFieldValue('description', event?.target?.value || '')}
                  />
                  {formik.touched.description && formik.errors.description ? (<div className="text-danger mt-2">{formik.errors.description}</div>) : null}
                </Col>
                <Col xxl={12}>
                  <label className="form-label">{t('Media transmission format classification')}<span className="text-danger"> *</span></label>
                  <Select
                    name="format"
                    options={TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS}
                    value={formik?.values?.format}
                    onChange={(event: any) => formik.setFieldValue('format', event)}
                    placeholder={<div>{`${t('Media transmission format classification')}...`}</div>}
                  />
                </Col>
                <Col xxl={12}>
                  <div>
                    <label className="form-label">{t('Types Media')}<span className="text-danger"> *</span></label>
                    <Types name="types" placeholder={`${t('Types Media')}...`} suggestions={suggestionsTypes || []} initialValue={initialValueTypes || []} onChangeTag={(event) => {
                      formik.setFieldValue('types', event);
                    }} />
                    {formik.touched.types && formik.errors.types ? (
                      <div className="text-danger mt-2">{formik.errors.types}</div>
                    ) : null}
                  </div>
                </Col>
                {/* Begin::List Section */}
                {/* formik?.values?.sections ||  */}
                <div>
                  {(initialValueSections)?.map((item: any, index: number) => (
                    <div className="p-4 position-relative mt-4" key={item?.id || index} style={{ border: '1px dashed var(--vz-primary)', backgroundColor: 'var(--vz-light)', borderRadius: '5px' }}>
                      {/* Begin::Item Template For Media */}
                      <div className="position-absolute top-0"
                        style={{
                          transform: 'translateY(-50%)',
                          padding: '5px 10px',
                          backgroundColor: 'var(--vz-primary-bg-subtle)',
                          color: 'var(--vz-primary)',
                          borderRadius: '5px',
                          border: '1px dashed var(--vz-primary)',
                          fontWeight: 500
                        }}
                      >
                        {t('Section')} {index + 1}
                      </div>
                      {!(initialValuesDefault?.isPreview) && (<div className="position-absolute end-0 top-0"
                        style={{
                          transform: 'translateY(-50%)',
                          padding: '5px 10px',
                          // backgroundColor: 'var(--vz-primary-bg-subtle)',
                          // color: 'var(--vz-primary)',
                          // borderRadius: '5px',
                          // border: '1px dashed var(--vz-primary)',
                          fontWeight: 500
                        }}
                      >
                        {(index > 0) && (<button
                          className="btn btn-sm btn-danger edit-item-btn"
                          type="button"
                          onClick={(e) => { e.preventDefault(); handleRemoveSection(item) }}
                        >
                          <i className="ri-close-circle-line align-bottom "></i>
                        </button>)}
                        {(initialValueSections?.length === (index + 1)) && (<button
                          className="btn btn-sm btn-success edit-item-btn ms-2"
                          type="button"
                          onClick={(e) => { e.preventDefault(); handleAddSection() }}
                        >
                          <i className="ri-add-circle-line align-bottom "></i>
                        </button>)}
                      </div>)}
                      <Row>
                        <Col md={3}>
                          <label className="form-label">{t('Section Title')}<span className="text-danger"> *</span></label>
                          <input
                            className="form-control"
                            id={`sections[${index}]section_title`}
                            name={`sections[${index}]section_title`}
                            value={(formik?.values?.sections?.[index] as any)?.section_title}
                            placeholder={`${t('Section Title')}...`}
                            onChange={(event: any) => formik.setFieldValue(`sections[${index}]section_title`, event?.target?.value || '')}
                          />
                          {(formik.touched?.sections?.[index] as any)?.section_title && (formik.errors?.sections?.[index] as any)?.section_title ? (
                            <div className="text-danger mt-2">{(formik.errors?.sections?.[index] as any)?.section_title}</div>
                          ) : null}
                        </Col>
                        <Col md={3}>
                          <label className="form-label">{t('Section Code')}<span className="text-danger"> *</span></label>
                          <input
                            className="form-control"
                            id={`sections[${index}]section_code`}
                            name={`sections[${index}]section_code`}
                            value={(formik?.values?.sections?.[index] as any)?.section_code}
                            placeholder={`${t('Section Code')}...`}
                            onChange={(event: any) => formik.setFieldValue(`sections[${index}]section_code`, event?.target?.value || '')}
                          />
                          {(formik.touched?.sections?.[index] as any)?.section_code && (formik.errors?.sections?.[index] as any)?.section_code ? (
                            <div className="text-danger mt-2">{(formik.errors?.sections?.[index] as any)?.section_code}</div>
                          ) : null}
                        </Col>
                        <Col md={3}>
                          <label className="form-label">{t('Reporter')}<span className="text-danger"> *</span></label>
                          <input
                            className="form-control"
                            id={`sections[${index}]reporter`}
                            name={`sections[${index}]reporter`}
                            value={(formik?.values?.sections?.[index] as any)?.reporter}
                            placeholder={`${t('Reporter')}...`}
                            onChange={(event: any) => formik.setFieldValue(`sections[${index}]reporter`, event?.target?.value || '')}
                          />
                          {(formik.touched?.sections?.[index] as any)?.reporter && (formik.errors?.sections?.[index] as any)?.reporter ? (
                            <div className="text-danger mt-2">{(formik.errors?.sections?.[index] as any)?.reporter}</div>
                          ) : null}
                        </Col>
                        <Col md={3}>
                          <label className="form-label">{t('Reporter Id')}<span className="text-danger"> *</span></label>
                          <input
                            className="form-control"
                            id={`sections[${index}]reporter_id`}
                            name={`sections[${index}]reporter_id`}
                            value={(formik?.values?.sections?.[index] as any)?.reporter_id}
                            placeholder={`${t('Reporter Id')}...`}
                            onChange={(event: any) => formik.setFieldValue(`sections[${index}]reporter_id`, event?.target?.value || '')}
                          />
                          {(formik.touched?.sections?.[index] as any)?.reporter_id && (formik.errors?.sections?.[index] as any)?.reporter_id ? (
                            <div className="text-danger mt-2">{(formik.errors?.sections?.[index] as any)?.reporter_id}</div>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  ))}

                </div>
                {/* End::List Section */}
                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    {!(initialValuesDefault?.isPreview) && (<button className="btn btn-primary fs-14" color="success" type="submit" disabled={isLoading}>
                      {isLoading ? <Spinner size="sm" className='me-2'></Spinner> : (!!(initialValuesDefault?.id) ? <i className="ri-login-circle-line align-bottom me-1"></i> : <i className="ri-add-fill align-bottom me-1"></i>)}
                      {!!(initialValuesDefault?.id) ? t('Button Update Media') : t('Button Create Media')}
                    </button>)}
                    <button className="btn btn-soft-secondary fs-14" color="light" type="button" onClick={onCloseClick} disabled={isLoading}>
                      <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                      {t('Button Close')}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
        <ModalConfirm
          textButtonConfirm={t('Button Refresh Key Media')}
          classButtonConfirm='primary'
          classIconButtonConfirm='ri-refresh-line'
          header={t('Button Refresh Key Media')}
          title={t('Do you want to refresh key of media!')}
          content={t('After confirming the refresh action, the data will be updated and cannot be recovered. Do you want to proceed with the refresh action.')}
          isShowIcon={false}
          isOpen={isOpenConfirmRefreshKey}
          isLoading={isConfirmRefreshKeyLoading}
          onClose={onCloseConfirmRefreshKeyClick}
          onConfirm={handleActionRefreshKey}
        />
      </div>
    </React.Fragment>
  );
};

export default MediaList;
