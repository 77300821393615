import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { ISettlement } from "./types/_settlement";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/settlements';
const SettlementApi = {
  settlements(params: any):Promise<AxiosResponse<ResponseData<ISettlement[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  allSettlements(params: any = {}):Promise<AxiosResponse<ResponseData<ISettlement[]>, any>>  {
    const url = `masters/Settlements`
    return api.get(url, params)
  },
  postSettlement(dataForm: any) : Promise<AxiosResponse<ResponseData<ISettlement>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putSettlement(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ISettlement>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteSettlement(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ISettlement>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
}
export default SettlementApi
