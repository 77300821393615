import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//Import Icons
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const Navdata = () => {
    const history = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch<any>();
    //state data
    const [isDashboard, setIsDashboard] = useState<boolean>(false);
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [isTables, setIsTables] = useState<boolean>(false);

    const [menuActive, setMenuActive] = useState<string>('DASHBOARD');

    // Multi Level
    const [isLevel1, setIsLevel1] = useState<boolean>(false);
    const [isLevel2, setIsLevel2] = useState<boolean>(false);

    const [isCurrentState, setIsCurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (isCurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (isCurrentState !== 'Auth') {
            setIsAuth(false);
        }

        if (isCurrentState !== 'Tables') {
            setIsTables(false);
        }
    }, [
        history,
        isCurrentState,
        isDashboard,
        isAuth,
        isTables,
        isLevel1,
        isLevel2,
    ]);

    const { pathname } = useLocation();

    const isEditCampaignPage: boolean = String(pathname)?.includes('/campaign/list/');

    useEffect(() => {
        if (isEditCampaignPage) {
            setMenuActive((prev) => 'EDIT_CAMPAIGN');
        }
    }, [isEditCampaignPage])

    const menuItems: any = [
        // {
        //     label: "Menu",
        //     isHeader: true,
        // },
        {
            id: "dashboard",
            label: t('Dashboards'),
            icon: <FeatherIcon icon="home" className="icon-dual" />,
            link: "/dashboard",
            stateVariables: (menuActive === 'DASHBOARD'),
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'DASHBOARD');
                setIsCurrentState('Dashboard');
                updateIconSidebar(e);
            },
        },
        {
            id: "multilevel",
            label: t('Keyword'),
            icon: <FeatherIcon icon="grid" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'KEYWORD');
                setIsCurrentState("Keyword");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'KEYWORD'),
            subItems: [
                // {
                //     id: "naver-category",
                //     label: t('Naver Category'),
                //     link: "/keyword/naver-category",
                //     // click: function (e: any) {
                //     //     e.preventDefault();
                //     //     // setIsLevel1(!isLevel1);
                //     // },
                //     stateVariables: isLevel1,
                // },
                {
                    id: "naver-keyword",
                    label: t('Naver Keyword'),
                    link: "/keyword/naver-keyword",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "car-category",
                    label: t('Car Category'),
                    link: "/keyword/car-category",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "car-keyword",
                    label: t('Car Keyword'),
                    link: "/keyword/car-keyword",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "movie-category",
                    label: t('Movie Category'),
                    link: "/keyword/movie-category",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "movie-keyword",
                    label: t('Movie Keyword'),
                    link: "/keyword/movie-keyword",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "exchange-rate-category",
                    label: t('Exchange Rate Category'),
                    link: "/keyword/exchange-rate-category",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "exchange-rate",
                    label: t('Exchange Rate'),
                    link: "/keyword/exchange-rate",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "trend-index-category",
                    label: t('Trend Index Category'),
                    link: "/keyword/trend-index-category",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "trend-index",
                    label: t('Trend Index'),
                    link: "/keyword/trend-index",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
            ],
        },
        {
            id: "multilevel4",
            label: t('Media'),
            icon: <FeatherIcon icon="command" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'MEDIA');
                setIsCurrentState("Media");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'MEDIA'),
            subItems: [
                {
                    id: "media",
                    label: t('Media'),
                    link: "/media",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "settlement",
                    label: t('Settlement'),
                    link: "/settlement",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
            ],
        },
        {
            id: "multilevel2",
            label: t('Campaign'),
            icon: <FeatherIcon icon="codesandbox" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'CAMPAIGN');
                setIsCurrentState("Campaign");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'CAMPAIGN'),
            subItems: [
                {
                    id: "campaign-create",
                    label: t('Create Campaign'),
                    link: "/campaign/create",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "campaign-list",
                    label: t('Campaign'),
                    link: "/campaign/list",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "news",
                    label: t('News'),
                    link: "/campaign/news",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
            ],
        },
        {
            id: "multilevel3",
            label: t('Template'),
            icon: <FeatherIcon icon="layers" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'TEMPLATE');
                setIsCurrentState("Template");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'TEMPLATE'),
            subItems: [
                {
                    id: "template-news-title",
                    label: t('Template News Title'),
                    link: "/template/news-title",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "template-news-content",
                    label: t('Template News Content'),
                    link: "/template/news-content",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "template-info-graphic",
                    label: t('Info-Graphic'),
                    link: "/template/info-graphic",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "template-variable-definition",
                    label: t('Variable Definition'),
                    link: "/template/variable-definition",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
            ],
        },
        {
            id: "multilevel5",
            label: t('Statistics'),
            icon: <FeatherIcon icon="bar-chart-2" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'STATISTICS');
                setIsCurrentState("Statistics");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'STATISTICS'),
            subItems: [
                {
                    id: "statistics",
                    label: t('News Log'),
                    link: "/statistics/news-log",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "news-statistics",
                    label: t('News Statistics'),
                    link: "/statistics/news-statistics",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
            ],
        },
        {
            id: "multilevel6",
            label: t('User'),
            icon: <FeatherIcon icon="users" className="icon-dual" />,
            link: "/#",
            click: function (e: any) {
                e.preventDefault();
                setMenuActive((prev) => 'USER');
                setIsCurrentState("User");
                updateIconSidebar(e);
            },
            stateVariables: (menuActive === 'USER'),
            subItems: [
                {
                    id: "user",
                    label: t('User'),
                    link: "/user/list",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
                {
                    id: "role-list",
                    label: t('Role'),
                    link: "/user/role",
                    // click: function (e: any) {
                    //     e.preventDefault();
                    //     // setIsLevel1(!isLevel1);
                    // },
                    stateVariables: isLevel1,
                },
            ],
        },

        // {
        //     id: "logout",
        //     label: "Logout",
        //     icon: <FeatherIcon icon="log-out" className="icon-log-out" />,
        //     link: "/logout",
        //     stateVariables: isDashboard,
        //     click: function (e: any) {
        //         e.preventDefault();
        //         setIsDashboard(!isDashboard);
        //         setIsCurrentState('Dashboard');
        //         updateIconSidebar(e);
        //     },
        // },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;