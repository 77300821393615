import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import carApi from 'api/carApi';

export const getModels = createAsyncThunk("models" , async (params: any = {}) => {
  try{
    const response = await carApi.models(params);
    return response;
  }catch (error) {
    return error;
  }
})

export const getAllModels = createAsyncThunk("models/getAllModels" , async (params: any = {}) => {
  try{
    const response = await carApi.allModels(params)
    return response;
  }catch (error) {
    return error;
  }
})

export const getBrands = createAsyncThunk("brands" , async (params: any = {}) => {
  try{
    const response = await carApi.brands(params)
    return response;
  }catch (error) {
    return error;
  }
})

export const getAllBrands = createAsyncThunk("models/getAllBrands" , async (params: any = {}) => {
  try{
    const response = await carApi.allBrands(params)
    return response;
  }catch (error) {
    return error;
  }
})

export const getCategoriesCar = createAsyncThunk("cars/getCategoriesCar" , async (params: any = {}) => {
  try{
    const response = await carApi.categories(params)
    return response;
  }catch (error) {
    return error;
  }
})

export const getCars = createAsyncThunk("cars" , async (params: any = {}) => {
  try{
    const response = await carApi.cars(params);
    return response;
  }catch (error) {
    return error;
  }
})


export const importCars = createAsyncThunk("cars/import" , async (data: any = {}) => {
  try{
    const response = await carApi.importCars(data);
    return response;
  }catch (error) {
    return error;
  }
})

export const getAllCategoriesCarNormal = async (params: any = {}) => {
  try{
    const response = await carApi.searchCategories(params)
    return response;
  }catch (error) {
    return error;
  }
}


export const importCarsByNormal = async (data: any = {}) => {
  try{
    const response = await carApi.importCars(data);
    return response;
  }catch (error) {
    return error;
  }
}

