import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";

interface CollapseTagProps {
    tags?: string[] | { keyword: string, rank: string | number }[],
    totalShow?: number,
    isRank?: boolean
}

const CollapseTag = ({
    tags = [],
    totalShow = 10,
    isRank = false,
}: CollapseTagProps) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const listShow = tags?.filter((item, index) => index < totalShow) || [];
    const listHidden = tags?.filter((item, index) => index >= totalShow) || [];
    return (
        <React.Fragment>
            {(tags?.length > 0) ? (<div className={`d-flex flex-wrap ${isRank && 'flex-column'}`}>
                {(listShow?.length > 0) && listShow?.map((item: any, index: number) => (<span key={index}><span className='tag-style'>{isRank ? `${item?.keyword}` : `${item}`}</span>{isRank && (<>: {item?.rank} <br /> </>)}</span>))}
                {isOpen && <React.Fragment>
                    {(listHidden?.length > 0) && listHidden?.map((item: any) => (<span><span className='tag-style'>{isRank ? `${item?.keyword}` : `${item}`}</span>{isRank && (<>: {item?.rank} <br /> </>)}</span>))}
                </React.Fragment>}
                {(listHidden?.length > 0) && <Link
                    to="#"
                    onClick={(e) => { e.preventDefault(); setIsOpen((prev) => !prev) }}
                    style={{ cursor: "pointer", verticalAlign: 'middle' }}
                    className="link-primary d-contents fs-12" >
                    <div className="d-flex align-items-center">
                        <span>{isOpen ? t('Hidden') : t('More')}</span><i className={`${isOpen ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'} fs-16`}></i>
                    </div>
                </Link>}
            </div>) : <></>}
        </React.Fragment>
    );
};

export default CollapseTag;