import { createSlice } from "@reduxjs/toolkit";
import { getSettlements, getAllSettlements } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ISettlement } from "api/types/_settlement";


export interface IState {
  settlements: ResponseData<ISettlement[]> & PaginationResponse | null,
  isSettlementLoading: boolean,
  isSettlementSuccess: boolean,

  allSettlement: ResponseData<ISettlement[]> | null,

  error: any,
};

export const initialState: IState = {
  settlements: null,
  isSettlementLoading: false,
  isSettlementSuccess: false,

  allSettlement: null,

  error: {},
};

const SettlementSlice = createSlice({
  name: "Settlement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Settlements
    builder.addCase(getSettlements.pending, (state: IState, action: any) => {
      state.isSettlementLoading = true
    });
    builder.addCase(getSettlements.fulfilled, (state: IState, action: any) => {
      state.settlements = action.payload.data;
      state.isSettlementSuccess = true;
      state.isSettlementLoading = false;
    });
    builder.addCase(getSettlements.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSettlementSuccess = false;
      state.isSettlementLoading = false;
    });
  },
});

export default SettlementSlice.reducer;