import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient"
import { PaginationResponse, ResponseData } from "./types/_public";
import { ICategoryMovie, IMovie } from "./types/_movie";
const api = new APIClient();

const path = '/movie';
const movieApi = {
  categories(params: any = {}):Promise<AxiosResponse<ResponseData<ICategoryMovie[]> & PaginationResponse, any>> {
    const url = `${path}/categories`
    return api.get(url, params)
  },
  movies(params: any):Promise<AxiosResponse<ResponseData<IMovie[]> & PaginationResponse, any>> {
    const url = `${path}/kobis`
    return api.get(url, params)
  },
  importMovies(dataForm: any) : Promise<AxiosResponse<ResponseData<IMovie[]> & PaginationResponse, any>>  {
    const formData = new FormData();
    formData.append('date_version', dataForm?.date_version || '');
    formData.append('files', dataForm?.file ? dataForm?.file as File : '');
    const url = `${path}/kobis/import`;
    return api.post(url, formData , {
      headers:{
        'Content-Type':  'multipart/form-data',
        'x-ms-blob-type': "BlockBlob"
      }
    })
  },
}
export default movieApi
