import { IType } from "api/types/_media";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
interface Option {
  label: string;
  value: string;
}

interface SearchFilterTypeProps {
  name?: string,
  dataList?: IType[],
  initialValue?: Option[] | Option | undefined;
  onChangeSelect?: (params: Option) => void;
}

const SearchFilterType = ({
  name = '',
  initialValue = [],
  dataList = [],
  onChangeSelect,
  // promiseOptions,
}: SearchFilterTypeProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  useEffect(() => {
    const result = dataList?.map((item) => ({ label: item?.name, value: String(item?.id) })) || [] as Option[];
    setOptions((_prev: any) => result);
  }, [JSON.stringify(dataList)]);

  return (
    <React.Fragment>
      <Select
        cacheOptions
        options={options}
        isMulti={false}
        closeMenuOnSelect={true}
        name={name}
        value={initialValue}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{t('Select Type')}...</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        formatOptionLabel={(option: any) => (<div className="d-flex">{option?.label}</div>)}
        className="search-filter-type"
        classNamePrefix="name-prefix"
      />
    </React.Fragment>
  );
};

export default SearchFilterType;
