import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container
} from "reactstrap";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import CampaignForm from "./CampaignForm";
import { IMedia, IType } from "api/types/_media";
import { ITemplate } from "api/types/_template";
import { ICategory } from "api/types/_category";
import { Option } from "api/types/_public";
import { getAllCategoriesNaverNormal, getAllCategoriesTrendIndexNormal, getAllCategoriesType, getAllMedias, getAllTemplates, getAllTypes } from "store/thunks";

const CampaignCreate = () => {
  const { t, i18n } = useTranslation();

  const [listType, setListType] = useState<IType[]>([]);

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [listTemplate, setListTemplate] = useState<ITemplate[]>([]);

  const [listCategoriesType, setListCategoriesType] = useState<Option[]>([]);

  const [listCategoriesNaver, setListCategoriesNaver] = useState<ICategory[]>([]);

  const [listCategoriesTrendIndex, setListCategoriesTrendIndex] = useState<ICategory[]>([]);

  const handleCallAllOption = async () => {
    try {
      const [
        resMedia,
        resTemplate,
        resType,
        resCategoriesType,
        resCategoriesNaver,
        resCategoriesTrendIndex,
      ]: any = await Promise.all([
        getAllMedias(),
        getAllTemplates(),
        getAllTypes(),
        getAllCategoriesType(),
        getAllCategoriesNaverNormal(),
        getAllCategoriesTrendIndexNormal(),
      ]);
      setListMedia((_prev) => resMedia?.data || []);
      setListTemplate((_prev) => resTemplate?.data || []);
      setListType((_prev) => resType?.data || []);
      setListCategoriesType(
        (_prev) =>
          resCategoriesType?.data?.map((item: any) => ({
            value: item?.type,
            label: item?.name,
          })) || []
      );
      setListCategoriesNaver((_prev) => resCategoriesNaver?.data || []);
      setListCategoriesTrendIndex(
        (_prev) => resCategoriesTrendIndex?.data || []
      );
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t("Campaign Create")} - ${t("Campaign")} | NewsHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("Campaign Create")}
            pageTitle={t("Campaign")}
          />
          <CampaignForm
            listType={listType}
            listMedia={listMedia}
            listTemplate={listTemplate}
            listCategoriesType={listCategoriesType}
            listCategoriesNaver={listCategoriesNaver}
            listCategoriesTrendIndex={listCategoriesTrendIndex}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CampaignCreate;