import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getParameters as onGetParameters, deleteParameter, putParameter, postParameter } from "../../../store/thunks";
import { IParameter } from 'api/types/_template';


import iconDelete from "../../../assets/images/gif/trash-bin.gif";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import ModalConfirm from 'components/Common/ModalConfirm';
import SearchFilterTopic from 'components/Common/SearchFilterTopic';
import { ITopic } from 'api/types/_topic';
import { getAllTopics } from 'store/topic/thunk';
import Topic from 'components/Common/Topics';
interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'id';

registerLocale('en', en);
registerLocale('ko', ko);


const VariableDefinitionList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [query, setQuery]: any = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    key: withDefault(StringParam, '')
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<IParameter | null>(null);

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [listTopic, setListTopic] = useState<ITopic[]>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Template;

  const ParameterProperties = createSelector(
    selectLayoutState,
    (state) => ({
      parameters: state.parameters,
      isParameterSuccess: state.isParameterSuccess,
      isParameterLoading: state.isParameterLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { parameters, error, isParameterLoading } = useSelector(ParameterProperties);

  useEffect(() => {
    dispatch(onGetParameters(query));
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      key: keywordSearch || undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
    });
  };

  const resetData = () => {
    setQuery({
      key: undefined,
      sort_by: undefined,
    }, "push")
    setKeywordSearch("");
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Variable'),
        accessor: "key",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Sample'),
        accessor: "sample",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Definition'),
        accessor: "description",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Active'),
        accessor: "is_active",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const active = value?.is_active;
          return (
            <div className="form-check form-switch form-switch-sm" dir="ltr">
              <input type="checkbox" className="form-check-input" name="is_active" id="customSwitchsizelg" checked={Boolean(active)}
                onChange={(event: any) => handleChangeStatusItem(value, event.target.checked)}
              />
            </div>
          );
        }
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Button Action'),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline hstack gap-1 mb-0" style={{ width: '80px' }}>
              <li className="list-inline-item" title={t('Button Update Template')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handleEdit(item) }}
                >
                  <i className="ri-pencil-fill align-bottom"></i>
                </Link>
              </li>
              <li className=" list-inline-item" title={t('Button Delete Template')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                >
                  <i className="ri-delete-bin-3-fill align-bottom"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [i18n?.language]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  // Begin::Delete
  const handleConfirmDelete = (item: IParameter) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteParameter(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetParameters(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  // Form

  function onCloseClick() {
    setIsOpen((_prev) => !_prev);
  }

  function onCreateClick() {
    setInitialValuesDefault((_prev) => null);
    handleResetForm();
    setIsOpen((_prev) => true);
  }

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        key: values?.key ?? '',
        sample: values?.sample ?? '',
        description: values?.description ?? '',
        value: '',
        is_active: Number(values?.is_active),
      };
      const response: any = !!(initialValuesDefault?.id) ? await putParameter(initialValuesDefault?.id, data) : await postParameter(data);
      if (response?.data) {
        dispatch(onGetParameters(query));
        setIsLoading((_prev) => false);
        setIsOpen((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleChangeStatusItem = async (item: any, status: boolean) => {
    try {
      const data = {
        is_active: Number(status),
      };
      const response: any = await putParameter(item?.id, data);
      if (response?.data) {
        dispatch(onGetParameters(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }

  const validationSchema = Yup.object({
    key: Yup.string().required(`${t('Key is required')}`),
    sample: Yup.string().nullable(),
    description: Yup.string().nullable(),
    is_active: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      key: '',
      sample: '',
      description: '',
      is_active: true,
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleEdit = (item: IParameter) => {
    setInitialValuesDefault((_prev) => item);
    setIsOpen((_prev) => true);
    formik.setFieldValue('key', item?.key);
    formik.setFieldValue('sample', item?.sample);
    formik.setFieldValue('description', item?.description);
    formik.setFieldValue('is_active', Boolean(item?.is_active));
  }

  const handleResetForm = () => {
    formik.setFieldValue('key', null);
    formik.setFieldValue('sample', null);
    formik.setFieldValue('description', null);
    formik.setFieldValue('is_active', true);
    setTimeout(() => {
      formik.setErrors({});
    }, 300)
  }

  const handleCallAllOption = async () => {
    try {
      const [resTopic]: any = await Promise.all([getAllTopics()]);
      setListTopic((_prev) => resTopic?.data || []);
    } catch (error: any) {
      return error;
    }
  };

  //@ts-ignore
  const initialValueIsActive = formik?.values?.is_active as boolean;


  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    document.title = `${t('Info-Graphic')} - ${t('Template')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Info-Graphic')} pageTitle={t('Template')} />
          <Row>
            <Col lg={12}>
              <div className='text-center mt-5'>
                {t('Under development')}
              </div>
            </Col>
          </Row>
        </Container>
        <ModalConfirm
          header={t('Button Delete Variable')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
        <Modal isOpen={isOpen} centered={true} size="lg">
          <ModalHeader toggle={isLoading ? () => { } : onCloseClick}></ModalHeader>
          <ModalBody className="py-3 px-5">
            <form onSubmit={formik.handleSubmit}>
              <div className="row g-3">
                <Col xxl={12}>
                  <label className="form-label">{t('Key')}<span className="text-danger"> *</span></label>
                  <input
                    className="form-control"
                    id="key"
                    name="key"
                    value={formik?.values?.key}
                    placeholder={`${t('Key')}...`}
                    onChange={(event: any) => formik.setFieldValue('key', event?.target?.value || '')}
                  />
                  {formik.touched.key && formik.errors.key ? (<div className="text-danger mt-2">{formik.errors.key}</div>) : null}
                </Col>
                <Col xxl={12}>
                  <label className="form-label">{t('Sample')}</label>
                  <input
                    className="form-control"
                    id="sample"
                    name="sample"
                    value={formik?.values?.sample}
                    placeholder={`${t('Sample')}...`}
                    onChange={(event: any) => formik.setFieldValue('sample', event?.target?.value || '')}
                  />
                  {formik.touched.sample && formik.errors.sample ? (<div className="text-danger mt-2">{formik.errors.sample}</div>) : null}
                </Col>
                <Col xxl={12}>
                  <label className="form-label">{t('Definition')}</label>
                  <input
                    className="form-control"
                    id="description"
                    name="description"
                    value={formik?.values?.description}
                    placeholder={`${t('Definition')}...`}
                    onChange={(event: any) => formik.setFieldValue('description', event?.target?.value || '')}
                  />
                  {formik.touched.description && formik.errors.description ? (<div className="text-danger mt-2">{formik.errors.description}</div>) : null}
                </Col>
                <Col xxl={12}>
                  <div className="form-check form-switch form-switch-lg" dir="ltr">
                    <label className="form-check-label" htmlFor="customSwitchsizelg">{t('Active')}</label>
                    <input type="checkbox" className="form-check-input" name="is_active" id="customSwitchsizelg" checked={initialValueIsActive}
                      onChange={(event: any) => formik.setFieldValue('is_active', event.target.checked)}
                    />
                  </div>
                  {formik.touched.is_active && formik.errors.is_active ? (<div className="text-danger mt-2">{formik.errors.is_active}</div>) : null}
                </Col>

                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    <button className="btn btn-secondary fs-14" color="light" onClick={onCloseClick} disabled={isLoading}>
                      <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                      {t('Button Close')}
                    </button>
                    <button className="btn btn-primary fs-14" color="success" type="submit" disabled={isLoading}>
                      {isLoading ? <Spinner size="sm" className='me-2'></Spinner> : (!!(initialValuesDefault?.id) ? <i className="ri-login-circle-line align-bottom me-1"></i> : <i className="ri-add-fill align-bottom me-1"></i>)}
                      {!!(initialValuesDefault?.id) ? t('Button Update Variable') : t('Button Create Variable')}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default VariableDefinitionList;