import Tags from '@yaireo/tagify/dist/react.tagify';
import "@yaireo/tagify/dist/tagify.css";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface Option {
  label: string;
  value: string;
}

interface HashtagInputProps {
  name?: string,
  placeholder?: string,
  initialValue: Option[],
  type?: 'hashtags'
  onChangeTag?: (params: Option[]) => void;
}

const HashtagInput = ({
  name = '',
  placeholder = '',
  initialValue = [],
  type = 'hashtags',
  onChangeTag,
}: HashtagInputProps) => {
  const { i18n } = useTranslation();

  const onChange = useCallback((event: any) => {
    const valueArr = event.detail.tagify.value?.map((item: any) => ({ value: String(item?.value).replace(/\s/g, ''), label: item?.value }));
    onChangeTag && onChangeTag(valueArr);
  }, [onChangeTag]);

  return (
    <div className={`${i18n?.language === 'ko' ? 'input-tagify-lang-ko' : 'input-tagify-lang-en'} ${type}`}>
      <Tags
        value={initialValue}
        className="form-control form-custom-input-tagify"
        placeholder=''
        onChange={onChange}
        showDropdown={false}
        settings={{
          trim: true,
        }}
      />
    </div>
  );
};

export default HashtagInput;
