import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IMedia, IType } from "./types/_media";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/partners';
const MediaApi = {
  medias(params: any):Promise<AxiosResponse<ResponseData<IMedia[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  allMedias(params: any = {}):Promise<AxiosResponse<ResponseData<IMedia[]>, any>>  {
    const url = `masters/partners`
    return api.get(url, params)
  },
  postMedia(dataForm: any) : Promise<AxiosResponse<ResponseData<IMedia>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putMedia(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IMedia>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteMedia(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IMedia>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  allTypes(params: any = {}):Promise<AxiosResponse<ResponseData<IType[]>, any>>  {
    const url = `masters/domains`
    return api.get(url, params)
  },
  refreshKey(id:string |  number, params: any = {}):Promise<AxiosResponse<ResponseData<IMedia>, any>>  {
    const url = `${path}/${id}/refresh`
    return api.get(url, params)
  },
}
export default MediaApi
