import { ICategory } from "api/types/_category";
import { IMedia, IType } from "api/types/_media";
import { Option } from "api/types/_public";
import { ITemplate } from "api/types/_template";
import { ICategoryTrendIndex } from "api/types/_trendIndex";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import ModalConfirm from "components/Common/ModalConfirm";
import CampaignFormByKeyword from "pages/Campaign/CampaignForm/CampaignFormByKeyword";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { createSelector } from "reselect";
import { postSuggestion } from "store/chatgpt/thunk";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import {
  deleteCategoryTrendIndex,
  getAllCategoriesTrendIndexNormal,
  getAllCategoriesType,
  getAllMedias,
  getAllTemplates,
  getAllTypes,
  getCategoriesTrendIndex as onGetCategoriesTrendIndex,
  postCategoryKeywords,
} from "../../../store/thunks";
import TrendIndexKeyword from "../Keyword";
import ChatGPT from "./ChatGPT";
import ModalEditTrendIndex from "./components/ModalEditTrendIndex";

const typeQuery = {
  keyword_count: StringParam,
};

const formatQuery = (query: any) => {
  return {
    page: query?.page_out,
    limit: query?.limit_out,
    name: query?.name_out,
  };
};

const TrendIndexCategory = () => {
  const { t, i18n } = useTranslation();
  const [query, setQuery] = useQueryParams({
    page_out: withDefault(NumberParam, 1),
    limit_out: withDefault(NumberParam, 30),
    name_out: StringParam,
    ...typeQuery,
  });
  const [keyword, setKeyword] = useState("");

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isKeyLoading, setIsKeyLoading] = useState<number | null>(null);

  const [showModalEditTrendIndex, setShowModalEditTrendIndex] =
    useState<boolean>(false);

  const [itemFocusEdit, setItemFocusEdit] = useState<any>();

  const [suggestion, setSuggestion] = useState<{
    category_id: string | number;
    keywords: string[];
  }>({ category_id: "", keywords: [] });

  const [optionsSelected, setOptionsSelected] = useState<string[]>([]);

  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] =
    useState<ICategoryTrendIndex | null>(null);

  const [isOpenDetailCategory, setIsOpenDetailCategory] =
    useState<boolean>(false);

  const [detailCategoryLoading, setDetailCategoryLoading] = useState<
    Option | undefined
  >(undefined);

  // Begin::List for create category

  const [isOpenCreateCampaign, setIsOpenCreateCampaign] =
    useState<boolean>(false);

  const [listType, setListType] = useState<IType[]>([]);

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [listTemplate, setListTemplate] = useState<ITemplate[]>([]);

  const [listCategoriesType, setListCategoriesType] = useState<Option[]>([]);

  const [listCategoriesTrendIndex, setListCategoriesTrendIndex] = useState<
    ICategory[]
  >([]);

  // End::List for create category

  // Begin::Chat GPT

  const [isOpenChatGPT, setIsOpenChatGPT] = useState<boolean>(false);

  // End::Chat GPT

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.TrendIndex;

  const TrendIndexCategoryProperties = createSelector(
    selectLayoutState,
    (state) => ({
      categories: state.categories,
      isCategorySuccess: state.isCategorySuccess,
      isCategoryLoading: state.isCategoryLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { categories, error, isCategoryLoading } = useSelector(
    TrendIndexCategoryProperties
  );

  const handleGetKeywordsSuggestion = async (
    keyword: string,
    keyIdLoading: number
  ) => {
    try {
      setIsKeyLoading((_prev) => keyIdLoading);
      const data = {
        keyword: keyword,
      };
      const response: any = await postSuggestion(data);
      if (response?.data) {
        setIsKeyLoading((_prev) => null);
        setSuggestion((prev) => ({
          category_id: keyIdLoading,
          keywords: String(response?.data).split(",") || [],
        }));
        setOptionsSelected((_prev) => []);
        setIsOpen((_prev) => true);
      } else {
        setIsKeyLoading((_prev) => null);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsKeyLoading((_prev) => null);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleAddKeywordsToCategory = async () => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        category_id: String(suggestion?.category_id) ?? "",
        keywords: optionsSelected || [],
        source: "chatgpt",
      };
      const response: any = await postCategoryKeywords(data);
      if (response?.data) {
        setIsLoading((_prev) => false);
        dispatch(onGetCategoriesTrendIndex(formatQuery(query)));
        setIsOpen((_prev) => false);
        setOptionsSelected((prev) => []);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  function onCloseClick() {
    setIsOpen((_prev) => !_prev);
  }

  const handleChecked = (item: string, e: any = null) => {
    setOptionsSelected((prev: any) => {
      if (item === "SELECT_ALL_OPTION") {
        return e.target.checked ? suggestion?.keywords : [];
      }
      if (prev?.includes(item)) {
        return prev?.filter((k: string) => k !== item);
      }
      return [...prev, item];
    });
  };

  const isChecked = (item: string) => {
    return optionsSelected?.includes(item);
  };

  const handleShowDetailCategory = async (itemLoading: Option) => {
    try {
      setDetailCategoryLoading((_prev) => itemLoading);
      setIsOpenDetailCategory((_prev) => true);
    } catch (error: any) {
      setDetailCategoryLoading((_prev) => undefined);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  function onCloseDetailCategoryClick() {
    setIsOpenDetailCategory((_prev) => !_prev);
    resetData();
    // dispatch(onGetCategoriesTrendIndex(formatQuery(query)));
  }

  useEffect(() => {
    dispatch(onGetCategoriesTrendIndex(formatQuery(query)));
  }, [dispatch, query]);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Id"),
        accessor: "id",
        sortable: false,
      },
      {
        Header: t("Name"),
        accessor: "name",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;

          const names = value?.group_categories.map((item: any) => item.name);
          const commaSeparatedString = names ? names.join(" > ") : "";
          let name = value.name;
          if (commaSeparatedString !== "") {
            name = commaSeparatedString + ` > ` + value.name;
          }
          return <div dangerouslySetInnerHTML={{ __html: name }} />;
        },
      },
      {
        Header: t("Total Keyword"),
        accessor: "keyword_count",
        filterable: false,
        sortable: false,
        thClass: "text-start",
        // thWidth: 130,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const names = value?.group_categories.map((item: any) => item.name);
          const commaSeparatedString = names ? names.join(" > ") : "";
          let name = value.name;
          if (commaSeparatedString !== "") {
            name = commaSeparatedString + ` > ` + value.name;
          }
          const option = {
            value: cell?.row?.original?.id,
            label: `${name} (${cell?.value ?? 0})`,
            keyword_count: cell?.value,
          };
          return (
            <Link
              className="btn btn-sm btn-soft-primary"
              to="#"
              onClick={(e) => {
                e.preventDefault();
                handleShowDetailCategory(option);
              }}
            >
              <i className="ri-share-box-line align-bottom"></i>
              {"  "}
              <span>{cell?.value ?? 0}</span>
            </Link>
          );
        },
      },
      {
        Header: t("Suggestion By ChatGPT"),
        accessor: "",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        thWidth: 240,
        Cell: (cell: any) => (
          <div className="text-center">
            <button
              type="button"
              className="btn btn-success btn-sm fs-14"
              disabled={!!isKeyLoading}
              onClick={() =>
                handleGetKeywordsSuggestion(
                  cell?.row?.original?.name,
                  cell?.row?.original?.id
                )
              }
            >
              {String(isKeyLoading) === String(cell?.row?.original?.id) ? (
                <Spinner size="sm"></Spinner>
              ) : (
                <i className="ri-bubble-chart-line align-bottom"></i>
              )}
              <span className="fs-13 ms-1 fw-400">{t("Get Suggestion")}</span>
            </button>
          </div>
        ),
      },
      {
        Header: t("Button Action"),
        thClass: "text-center",
        thWidth: 100,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul
              className="list-inline hstack gap-1 mb-0"
              style={{ width: "36px" }}
            >
              <li
                className="list-inline-item"
                title={t("Button Update Category")}
              >
                <Link
                  className="btn btn-sm btn-soft-secondary edit-item-btn"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEdit(item);
                  }}
                >
                  <i className="ri-pencil-fill align-bottom "></i>
                </Link>
              </li>
              <li
                className="list-inline-item"
                title={t("Button Delete Category")}
              >
                <Link
                  className="btn btn-sm btn-soft-secondary  edit-item-btn"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleConfirmDelete(item);
                  }}
                >
                  <i className="ri-delete-bin-3-fill align-bottom"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [i18n?.language, isKeyLoading]
  );

  // Begin::Delete
  const handleConfirmDelete = (item: ICategoryTrendIndex) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!initialValuesDefault?.id) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteCategoryTrendIndex(
        initialValuesDefault?.id
      );
      if (response?.code === 200) {
        dispatch(onGetCategoriesTrendIndex(formatQuery(query)));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  };
  // End::Delete

  // Begin::Search
  const handleSearchCategory = () => {
    setQuery({ name_out: keyword, page_out: 1 });
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page_out: page + 1 });
  }, []);

  const resetData = () => {
    setQuery(
      {
        name_out: undefined,
      },
      "push"
    );
    setKeyword("");
  };
  // End::Search

  // Begin:: Create Campaign
  function onCloseCreateCampaignClick() {
    setIsOpenCreateCampaign((_prev) => !_prev);
  }

  const handleCallAllOption = async () => {
    try {
      const [
        resType,
        resMedia,
        resCategoriesType,
        resTemplate,
        resCategoriesTrendIndex,
      ]: any = await Promise.all([
        getAllTypes(),
        getAllMedias(),
        getAllCategoriesType(),
        getAllTemplates(),
        getAllCategoriesTrendIndexNormal(),
      ]);
      setListType((_prev) => resType?.data || []);
      setListMedia((_prev) => resMedia?.data || []);
      setListCategoriesType(
        (_prev) =>
          resCategoriesType?.data?.map((item: any) => ({
            value: item?.type,
            label: item?.name,
          })) || []
      );
      setListTemplate((_prev) => resTemplate?.data || []);
      setListCategoriesTrendIndex(
        (_prev) => resCategoriesTrendIndex?.data || []
      );
    } catch (error: any) {
      return error;
    }
  };

  const handleRefetchCategoriesTrendIndex = async (itemLoading: any = null) => {
    itemLoading && setDetailCategoryLoading((_prev) => itemLoading);
    dispatch(onGetCategoriesTrendIndex(formatQuery(query)));
    try {
      const [resCategoriesTrendIndex]: any = await Promise.all([
        getAllCategoriesTrendIndexNormal(),
      ]);
      setListCategoriesTrendIndex(
        (_prev) => resCategoriesTrendIndex?.data || []
      );
    } catch (error: any) {
      return error;
    }
  };

  const handleEdit = (item: any) => {
    setItemFocusEdit(item);
    setShowModalEditTrendIndex((_prev) => true);
  };

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // End:: Create Campaign

  // Begin:: Chat GPT
  function onCloseChatGPTClick() {
    setIsOpenChatGPT((_prev) => false);
  }

  // End::Chat GPT

  useEffect(() => {
    document.title = `${t("Keyword")} - ${t("Trend Index Category")} | NewsHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("Trend Index Category")}
            pageTitle={t("Keyword")}
          />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <Col sm={12} lg={3}>
                      <div className="search-box">
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("Search by name")}...`}
                          value={keyword}
                          onChange={(e) => setKeyword(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleSearchCategory();
                            }
                          }}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <Col
                      sm={12}
                      lg={9}
                      className="hstack gap-1 justify-content-sm-center justify-content-md-between"
                    >
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={isCategoryLoading}
                          onClick={handleSearchCategory}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t("Button Search")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14 ms-1"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t("Button Reset")}
                        </button>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-soft-primary fs-14"
                          onClick={() => setIsOpenChatGPT((prev) => true)}
                        >
                          <i className="ri-question-answer-line align-bottom"></i>
                          <span className="fs-13 ms-1 fw-400">{t("Chat GPT")}</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-success fs-14 ms-4"
                          onClick={() =>
                            setIsOpenCreateCampaign((_prev) => true)
                          }
                        >
                          <i className="ri-add-fill align-bottom me-1"></i>{" "}
                          {t("Button Create Campaign")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      columns={columns}
                      data={categories?.list?.length ? categories?.list : []}
                      customPageSize={query.limit_out}
                      customPageIndex={query.page_out - 1}
                      totalRecords={categories?.pagination?.total}
                      customPageCount={Math.ceil(
                        categories?.pagination?.total /
                        categories?.pagination?.limit
                      )}
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      handleChangePage={handleChangePage}
                      isLoading={isCategoryLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={isOpen}
        id="firstmodal"
        centered
        size="xl"
        scrollable={true}
        toggle={onCloseClick}
        keyboard={true}
      >
        <ModalHeader toggle={isLoading ? () => { } : onCloseClick}>
          {t("Keywords Suggestion")}
        </ModalHeader>
        <ModalBody className="text-center">
          <div className="row g-4">
            <Col md={12} className="mt-2">
              <Col md={12} className="text-end">
                <div className="form-check form-switch form-switch-danger form-switch-md d-inline-block mb-3">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="SwitchCheck1"
                    checked={
                      Number(optionsSelected?.length) ===
                      Number(suggestion?.keywords?.length) &&
                      Number(suggestion?.keywords?.length) > 0
                    }
                    onChange={(e) => handleChecked("SELECT_ALL_OPTION", e)}
                  />
                  <Label className="form-check-label" for="SwitchCheck1">
                    {t("Select All Keywords")}{" "}
                    {`(${suggestion?.keywords?.length || 0})`}
                  </Label>
                </div>
              </Col>
              <div className="d-flex flex-row flex-wrap">
                {suggestion?.keywords?.map((item, index) => (
                  <ListGroupItem
                    key={index}
                    tag="label"
                    className="px-4 py-3 text-start item-keyword-suggestion"
                  >
                    <Input
                      className="form-check-input me-1"
                      type="checkbox"
                      value={item}
                      checked={isChecked(item)}
                      onChange={() => handleChecked(item)}
                    />
                    {item}
                  </ListGroupItem>
                ))}
              </div>
              <div className="d-flex justify-content-end mt-2">
                <button
                  className="btn btn-light fs-14 me-2"
                  type="button"
                  color="light"
                  onClick={onCloseClick}
                >
                  <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                  {t("Button Close")}
                </button>
                <button
                  type="button"
                  disabled={isLoading || optionsSelected?.length < 1}
                  className="btn btn-success d-none d-md-block"
                  onClick={() => handleAddKeywordsToCategory()}
                >
                  {optionsSelected?.length > 0 && (
                    <span
                      className="position-absolute topbar-badge badge rounded-pill bg-danger"
                      style={{ transform: "translate(0%, -70%)" }}
                    >
                      {optionsSelected?.length || 0}
                      <span className="visually-hidden">
                        total keywords selected
                      </span>
                    </span>
                  )}
                  {isLoading ? (
                    <Spinner size="sm" className="me-2"></Spinner>
                  ) : (
                    <i className="ri-login-circle-line align-bottom me-1"></i>
                  )}
                  {t("Button Save")}
                </button>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isOpenDetailCategory}
        id="firstmodal"
        centered
        size="xl"
        scrollable={true}
        // toggle={onCloseDetailCategoryClick}
        keyboard={true}
      >
        <ModalHeader toggle={isLoading ? () => { } : onCloseDetailCategoryClick}>
          {t("Keywords")}
        </ModalHeader>
        <ModalBody className="text-center">
          <div
            className="row g-4"
            style={{ minHeight: "calc(100dvh - 200px)" }}
          >
            <TrendIndexKeyword
              detailCategory={detailCategoryLoading || undefined}
              handleRefreshListCategory={handleRefetchCategoriesTrendIndex}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={isOpenCreateCampaign}
        centered={true}
        size="xl"
        className="modal-fullscreen-r"
        scrollable={true}
      >
        <ModalHeader toggle={onCloseCreateCampaignClick}>
          {t("Button Create Campaign")}
        </ModalHeader>
        <ModalBody>
          <div style={{ minHeight: "calc(100dvh - 200px)" }}>
            <CampaignFormByKeyword
              listKeyword={[]}
              listType={listType}
              listMedia={listMedia}
              listTemplate={listTemplate}
              listCategoriesType={listCategoriesType}
              listCategoriesTrendIndex={listCategoriesTrendIndex}
              handleRefetchCategoriesTrendIndex={
                handleRefetchCategoriesTrendIndex
              }
            // handleClearSelectedKeywords={handleClearSelectedKeywords}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={!!isOpenChatGPT}
        centered={true}
        size="xl"
        scrollable={false}
      >
        <ModalHeader toggle={onCloseChatGPTClick}>
          {t("View Chat GPT")}
        </ModalHeader>
        <ModalBody>
          <div style={{ maxHeight: "calc(100dvh - 200px)" }}>
            <ChatGPT
              title={""}
              style={{ height: "calc(100dvh - 200px)" }}
            />
          </div>
        </ModalBody>
      </Modal>

      <ModalConfirm
        header={t("Button Delete Category")}
        title={t("Are you sure you want to delete the category?")}
        isOpen={isOpenConfirm}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmClick}
        onConfirm={handleActionDelete}
      />
      <ModalEditTrendIndex
        isOpen={showModalEditTrendIndex}
        onClose={() => {
          setItemFocusEdit(undefined);
          setShowModalEditTrendIndex(false);
        }}
        item={itemFocusEdit}
        triggerRefresh={handleRefetchCategoriesTrendIndex}
      />
    </React.Fragment>
  );
};

export default TrendIndexCategory;
