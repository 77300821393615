import 'react-toastify/dist/ReactToastify.css';

import chatgptApi from 'api/chatgptApi';

export const postSuggestion = async (data: any = {}) => {
  try{
    const response = await chatgptApi.postSuggestion(data);
    return response;
  }catch (error) {
    return error;
  }
};


export const postGenerate = async (data: any = {}) => {
  try{
    const response = await chatgptApi.postGenerate(data);
    return response;
  }catch (error) {
    return error;
  }
};