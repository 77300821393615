import { createSlice } from "@reduxjs/toolkit";
import { getCategories, getKeywords, getAllCategories } from './thunk';
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ICategory, IKeyword } from "api/types/_naver";


export interface IState {
  categories: ResponseData<ICategory[]> & PaginationResponse | null,
  allCategories: ResponseData<ICategory[]> | null,
  isCategoryLoading : boolean,
  isCategorySuccess : boolean,
  keywords: ResponseData<IKeyword[]> & PaginationResponse | null,
  isKeywordLoading: boolean,
  isKeywordSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  categories: null,
  allCategories: null,
  isCategoryLoading : false,
  isCategorySuccess : false,
  keywords: null,
  isKeywordLoading: false,
  isKeywordSuccess: false,

  error: {},
};

const naverSlice = createSlice({
  name: "naver",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get category bu sub
    builder.addCase(getCategories.pending, (state: IState, action: any) => {
      state.isCategoryLoading = true
    });
    builder.addCase(getCategories.fulfilled, (state: IState, action: any) => {
      state.categories = action.payload.data;
      state.isCategorySuccess = true;
      state.isCategoryLoading = false;
    });

    builder.addCase(getCategories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCategorySuccess = false;
      state.isCategoryLoading = false;
    });
    //get all category
    builder.addCase(getAllCategories.fulfilled, (state: IState, action: any) => {
      state.allCategories = action.payload.data;
      state.isCategorySuccess = true;
    });

    builder.addCase(getAllCategories.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCategorySuccess = false;
    });

    // get keyword
    builder.addCase(getKeywords.pending, (state: IState, ) => {
      state.isKeywordLoading = true;
    });
    builder.addCase(getKeywords.fulfilled, (state: IState, action: any) => {
      state.keywords = action.payload.data;
      state.isKeywordSuccess = true;
      state.isKeywordLoading = false;
    });

    builder.addCase(getKeywords.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isKeywordSuccess = false;
      state.isKeywordLoading = false;
    });
  },
});

export default naverSlice.reducer;