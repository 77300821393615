import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient"
import { PaginationResponse, ResponseData } from "./types/_public";
import { ICategory, IKeyword } from "./types/_naver";
const api = new APIClient();

const path = '/naver-search-ad';
const naverApi = {
  categories(params: any = {}):Promise<AxiosResponse<ResponseData<ICategory[]> & PaginationResponse, any>> {
    const url = `${path}/categories`
    return api.get(url, params)
  },
  searchCategories(params: any = {}):Promise<AxiosResponse<ResponseData<ICategory[]>, any>>  {
    const url = `${path}/categories/all`
    return api.get(url, params)
  },
  keywords(params: any):Promise<AxiosResponse<ResponseData<IKeyword[]> & PaginationResponse, any>> {
    const url = `${path}/keywords`
    return api.get(url, params)
  },
  summaries(params: any = {}) {
    const url = `${path}/summaries`
    return api.get(url, params)
  },
}
export default naverApi
