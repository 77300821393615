import React, { useEffect } from "react";
import { WithContext as ReactTags } from 'react-tag-input';

export interface Tag {
  id: string;
  text: string;
}
interface HashtagProps {
  name?: string,
  initialValue?: Tag[];
  suggestions?: Tag[];
  placeholder?: string,
  onChangeTag?: (params: Tag[]) => void;
}

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Hashtag = ({
  name = '',
  initialValue = [],
  suggestions = [],
  placeholder = '',
  onChangeTag,
}: HashtagProps) => {

  const handleDelete = (i: number) => {
    onChangeTag && onChangeTag((initialValue || []).filter((tag, index) => index !== i));
  };

  const handleAddition = (tag: any) => {
    onChangeTag && onChangeTag([...initialValue, tag]);
  };

  const handleDeleteValue = (tag: any) => {
    onChangeTag && onChangeTag([]);
  };

  useEffect(() => {
    const tags = (suggestions || [])?.reduce((arr: Tag[], item: Tag) => {
      if (arr.some(e => e.id === item?.id)) {
        return arr;
      }
      return ([...arr, item]);
    }, (initialValue || []));
    onChangeTag && onChangeTag(tags);
  }, [JSON.stringify(suggestions)]);

  return (
    <div className='position-relative'>
      <ReactTags
        tags={initialValue}
        suggestions={[]}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        inputFieldPosition="top"
        autocomplete={true}
        allowDeleteFromEmptyInput={true}
        allowDragDrop={false}
        placeholder={placeholder}
        classNames={{
          tags: 'hashtags-custom',
          tagInputField: 'form-control',
        }}
      />
      {(initialValue?.length > 0) && (<span
        className="mdi mdi-close-circle search-widget-icon search-widget-icon-close position-absolute cursor-pointer"
        onClick={handleDeleteValue}
        style={{
          right: '8px',
          top: 5,
          fontSize: '20px',
          color: 'var(--vz-header-item-sub-color)'
        }}
      ></span>)}
    </div>
  );
};

export default Hashtag;
