import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { PaginationResponse, ResponseData } from "./types/_public";
import {
  ITrendIndex,
  ICategoryTrendIndex,
  TrendIndexCategory,
} from "./types/_trendIndex";
const api = new APIClient();

const path = "/trend-index";
const trendIndexApi = {
  categories(
    params: any = { type: "trend_index" }
  ): Promise<
    AxiosResponse<ResponseData<ICategoryTrendIndex[]> & PaginationResponse, any>
  > {
    const url = `categories`;
    return api.get(url, params);
  },
  trendIndexes(
    params: any
  ): Promise<
    AxiosResponse<ResponseData<ITrendIndex[]> & PaginationResponse, any>
  > {
    const url = `${path}/keywords`;
    return api.get(url, params);
  },
  searchCategories(
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<ICategoryTrendIndex[]>, any>> {
    const url = `masters/categories`;
    return api.get(url, params);
  },
  deleteTrendIndex(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<ITrendIndex>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm);
  },
  deleteCategoryTrendIndex(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<ICategoryTrendIndex>, any>> {
    const url = `categories/${id}`;
    return api.delete(url, dataForm);
  },
  postAnalysis(
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<ITrendIndex>, any>> {
    const url = `analysis`;
    return api.post(url, dataForm);
  },
  putTrendIndexCategory(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<TrendIndexCategory>, any>> {
    const url = `/categories/${id}`;
    return api.put(url, dataForm);
  },
};
export default trendIndexApi;
