import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IContent, IKeyword } from "./types/chatgpt";
const api = new APIClient();

const path = '/chatgpts';
const chatgptApi = {
  postSuggestion(dataForm: any) : Promise<AxiosResponse<IKeyword, any>>  {
    const url = `${path}/suggestion`;
    return api.post(url, dataForm)
  },
  postGenerate(dataForm: any) : Promise<AxiosResponse<IContent, any>>  {
    const url = `${path}/generate`;
    return api.post(url, dataForm)
  },
}
export default chatgptApi
