import { ICampaign } from "api/types/_campaign";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Spinner } from 'reactstrap';
import {
    getUser,
    postUser,
    putUser
} from "store/thunks";
import * as Yup from "yup";

//import images
import { IPermission } from "api/types/_role";

export interface Tag {
    id: string;
    text: string;
}

export interface Props {
    isModal?: boolean;
    id?: string;
    isCopy?: boolean,
    permissions?: IPermission[],
    triggerRefresh?: () => void;
}

registerLocale("en", en);
registerLocale("ko", ko);

const RoleForm = ({
    isModal = false,
    id = '',
    isCopy = false,
    triggerRefresh,
}: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);

    const [initialValuesDefault, setInitialValuesDefault] = useState<ICampaign | null>(null);

    const [activeTab, setActiveTab] = useState("1");
    const [textExperience, setTextExperience] = useState("You always want to make sure that your fonts work well together and try to limit the number of fonts you use to three or less. Experiment and play around with the fonts that you already have in the software you're working with reputable font websites. ");

    const tabChange = (tab: any) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleSubmit = async (values: any) => {

        try {
            setIsLoading((_prev) => true);
            const data = {
                role_id: values?.role ?? 1,
                first_name: values?.first_name ?? "",
                last_name: values?.last_name ?? "",
                email: values?.email ?? "",
                password: values?.password ?? "",
                // description: values?.description ?? "",
                is_active: values?.is_active ?? 1,
            };
            const response: any = id ? await putUser(id, data) : await postUser(data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchema = Yup.object({
        first_name: Yup.string().required(`${t("First Name is required")}`),
        last_name: Yup.string().required(`${t("Last Name is required")}`),
        email: Yup.string().email('Please enter a valid email address').required(`${t('Email is required')}`),
        password: Yup.string().min(8, 'Password must be at least 8 characters').required(`${t("Password is required")}`),
        is_active: Yup.number(),
        description: Yup.string().nullable(),
        joining_date: Yup.string().nullable()
    });

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            description: "",
            joining_date: "",
            is_active: 1
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const vFirstName = valueDefault?.first_name;
        const vLastName = valueDefault?.last_name;
        const vEmail = valueDefault?.email;
        const vPassword = valueDefault?.password;
        const vIsActive = valueDefault?.is_active;
        const vDescription = valueDefault?.description;
        const vJoiningDate = valueDefault?.created_at;
        setTimeout(() => {
            formik.setFieldValue("first_name", vFirstName);
            formik.setFieldValue("last_name", vLastName);
            formik.setFieldValue("email", vEmail);
            formik.setFieldValue("password", vPassword);
            formik.setFieldValue("description", vDescription);
            formik.setFieldValue("is_active", vIsActive);
            formik.setFieldValue("joining_date", vJoiningDate);
        }, 300);
    };

    const handleSubmitChangePassword = async (values: any) => {

        try {
            setIsLoading((_prev) => true);
            const data = {
                old_password: values?.old_password ?? "",
                new_password: values?.new_password ?? "",
            };
            const response: any = await putUser(id, data);
            if (response?.data) {
                setIsLoading((_prev) => false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
                triggerRefresh && triggerRefresh();
            } else {
                setIsLoading((_prev) => false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading((_prev) => false);
            toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
            return error;
        }
    };

    const validationSchemaChangePassword = Yup.object({
        old_password: Yup.string().required(`${t("Old Password is required")}`),
        new_password: Yup.string().required(`${t("News Password is required")}`),
        confirm_password: Yup.string().required(`${t("Confirm Password is required")}`),
    });

    const formikChangePassword = useFormik({
        initialValues: {
            old_password: "",
            new_password: "",
            confirm_password: "",
        },
        validationSchema: validationSchemaChangePassword,
        onSubmit: handleSubmitChangePassword,
    });

    const handleCallAllOption = async (idItem: string) => {
        try {
            if (!idItem) {
                return
            }
            setIsLoadingDetail((_prev) => true);
            const [resDetail]: any = await Promise.all([idItem ? getUser(idItem) : {},]);
            if (resDetail?.data) {
                setInitialValuesDefault((_prev) => resDetail?.data);
                handleSetValueForm(resDetail?.data);
                setIsLoadingDetail((_prev) => false);
            }
        } catch (error: any) {
            setIsLoadingDetail((_prev) => false);
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        document.title = `${t("User Create")} - ${t("User")} | NewsHub`;
        document.body.classList.remove("vertical-sidebar-enable");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n?.language]);

    return (
        <React.Fragment>
            {isLoadingDetail && <div style={{
                position: 'absolute',
                zIndex: 3,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgb(164 164 164 / 36%)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Spinner size="sm" color="primary"></Spinner>
            </div>}
            <div>
                <Container fluid>
                    Role
                </Container >
            </div>
            <ToastContainer closeButton={false} limit={1} />

        </React.Fragment >
    );
};

export default RoleForm;
