import { createSlice } from "@reduxjs/toolkit";
import { getCategoriesExchangeRate, getCurrencies, getExchangeRates } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ICurrency, ICurrencyRate, ICategoryExchangeRate } from "api/types/_currency";

export interface IState {
  categories: ResponseData<ICategoryExchangeRate[]> & PaginationResponse | null,
  isCategoryLoading : boolean,
  isCategorySuccess : boolean,

  currencies: ResponseData<ICurrency[]> & PaginationResponse | null,
  isCurrencyLoading: boolean,
  isCurrencySuccess: boolean,

  exchangeRates: ResponseData<ICurrencyRate[]> & PaginationResponse | null,
  isExchangeRatesLoading: boolean,
  isExchangeRatesSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  categories: null,
  isCategoryLoading : false,
  isCategorySuccess : false,

  currencies: null,
  isCurrencyLoading: false,
  isCurrencySuccess: false,

  exchangeRates: null,
  isExchangeRatesLoading: false,
  isExchangeRatesSuccess: false,

  error: {},
};

const CurrencySlice = createSlice({
  name: "Currency",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    // get categories
    builder.addCase(getCategoriesExchangeRate.pending, (state: IState, action: any) => {
      state.isCategoryLoading = true
    });
    builder.addCase(getCategoriesExchangeRate.fulfilled, (state: IState, action: any) => {
      state.categories = action.payload.data;
      state.isCategorySuccess = true;
      state.isCategoryLoading = false;
    });

    builder.addCase(getCategoriesExchangeRate.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCategorySuccess = false;
      state.isCategoryLoading = false;
    });

    //get Currencies
    builder.addCase(getCurrencies.pending, (state: IState, action: any) => {
      state.isCurrencyLoading = true
    });
    builder.addCase(getCurrencies.fulfilled, (state: IState, action: any) => {
      state.currencies = action.payload.data;
      state.isCurrencySuccess = true;
      state.isCurrencyLoading = false;
    });
    builder.addCase(getCurrencies.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCurrencySuccess = false;
      state.isCurrencyLoading = false;
    });

    //get Exchange Rate
    builder.addCase(getExchangeRates.pending, (state: IState, action: any) => {
      state.isExchangeRatesLoading = true
    });
    builder.addCase(getExchangeRates.fulfilled, (state: IState, action: any) => {
      state.exchangeRates = action.payload.data;
      state.isExchangeRatesSuccess = true;
      state.isExchangeRatesLoading = false;
    });
    builder.addCase(getExchangeRates.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isExchangeRatesSuccess = false;
      state.isExchangeRatesLoading = false;
    });
  },
});

export default CurrencySlice.reducer;