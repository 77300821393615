import { Option } from "api/types/_public";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Input, Row } from "reactstrap";
import { postCampaign } from "store/thunks";
import * as Yup from 'yup';
import TypingEffect from "./TypingEffect/TypingEffect";
import { postGenerate } from "store/chatgpt/thunk";
import FeatherIcon from "feather-icons-react";
import { clearInterval } from "timers";

export interface Tag {
  id: string;
  text: string;
}
interface Props {
  title?: string,
  style?: any,
}
interface IMessage {
  id: string,
  name: string,
  time: string | number,
  message: string,
  isByBot: boolean,
  status: 'TYPING' | 'END'
}


registerLocale('en', en);
registerLocale('ko', ko);

const ChatGPT = ({
  title = '',
  style = {},
}: Props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const messagesEndRef = useRef<any>();

  const inputRef = useRef<any>();

  const bottomRef = useRef<any>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [messages, setMessages] = useState<IMessage[]>([]);


  const handleSetHistoryChatGPT = (mess: IMessage[]) => {
    // Add a new message to the chat history
    const chatHistoryStore = localStorage.getItem("historyChatGPT");
    const chatHistory = chatHistoryStore ? JSON.parse(chatHistoryStore) : [];
    // Check if chat history length is greater than or equal to 10
    if (chatHistory.length >= 10) {
      const updatedHistory = chatHistory?.slice(2);
      updatedHistory?.concat(mess);
      localStorage.setItem('historyChatGPT', JSON.stringify(updatedHistory));
    } else {
      const updatedHistory = chatHistory?.concat(mess);
      localStorage.setItem('historyChatGPT', JSON.stringify(updatedHistory));
    }
  };

  const handleDeleteHistoryChatGPT = () => {
    // Delete history message of the chat history
    localStorage.removeItem("historyChatGPT");
    setMessages((prev) => []);
  };

  const handleSubmit = async (values: any) => {
    try {
      if (isLoading) {
        return;
      }
      setIsLoading((_prev) => true);
      const data = {
        content: values?.message ?? '',
      };
      const idMessNew = String(+new Date() + 100);

      const messUser: IMessage = {
        id: String(+new Date()),
        name: '',
        time: +new Date(),
        message: values?.message ?? '',
        isByBot: false,
        status: 'END'
      };
      const messBot: IMessage = {
        id: idMessNew,
        name: '',
        time: +new Date(),
        message: '',
        isByBot: true,
        status: 'TYPING'
      };

      const mess: IMessage[] = [messUser, messBot];
      formik.setFieldValue('message', '');
      setMessages((prev) => prev.concat(mess));
      const response: any = await postGenerate(data);
      if (response?.data) {
        // setIsLoading((_prev) => false);
        setMessages((prev) => prev?.map((item: any) => {
          if (item?.id === idMessNew) {
            return ({ ...item, status: 'TYPING', message: response?.data });
          }
          return item;
        }));
        handleSetHistoryChatGPT([messUser, { ...messBot, status: 'END', message: response?.data }]);
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleEndTyping = useCallback((idMess: string) => {
    setMessages((prev) => prev.map((item) => {
      if (item?.id === idMess) {
        return { ...item, status: 'END' }
      }
      return item;
    }));
    setIsLoading((_prev) => false);
  }, [])

  const validationSchema = Yup.object({
    message: Yup.string().required(`${t('Message is required')}`),
  });

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  //@ts-ignore
  const initialValueMessage = formik?.values?.message as Option;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const historyChatGPT = localStorage.getItem("historyChatGPT");
    if (historyChatGPT) {
      const history = JSON.parse(historyChatGPT);
      setMessages((prev) => history);
    }
    const handleScroll = setTimeout(() => {
      if (bottomRef.current) {
        bottomRef.current?.scrollIntoView({ block: 'end' });
      }
    }, 300);
    return () => {
      clearTimeout(handleScroll);
    }
  }, []);

  return (
    <React.Fragment>
      <Row>
        <div className="w-100 overflow-hidden px-0 border" style={{ borderRadius: '4px' }}>
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative">
              <div className="position-relative d-flex flex-column" style={style}>
                {/* Top */}
                <div className="p-3">
                  <div className="align-items-center row">
                    <div className="col-8 col-sm-4">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1 overflow-hidden">
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                              <img
                                src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/chatgpt-icon.png"
                                className="rounded-circle avatar-xs"
                                alt=""
                              />
                              <span className="user-status" />
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                              <h5 className="text-truncate mb-0 fs-16">
                                <a
                                  className="text-reset username"
                                  data-bs-toggle="offcanvas"
                                  href="#userProfileCanvasExample"
                                  aria-controls="userProfileCanvasExample"
                                >
                                  {t('Bot Name')}
                                </a>
                              </h5>
                              <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                <small>{t('Online')}</small>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 col-sm-8">
                      <ul className="list-inline user-chat-nav text-end mb-0">
                        <li className="list-inline-item d-none d-lg-inline-block m-0">
                          <button
                            type="button"
                            className="btn btn-ghost-secondary btn-icon"
                            onClick={() => handleDeleteHistoryChatGPT()}
                          >
                            <FeatherIcon icon="trash-2" className="icon-sm" />
                          </button>
                        </li>
                        <li className="list-inline-item m-0">
                          <div className="dropdown">
                            <button
                              aria-haspopup="true"
                              className="btn btn-ghost-secondary btn-icon"
                              aria-expanded="false"
                            >
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-more-vertical icon-sm"
                              >
                                <g>
                                  <circle cx={12} cy={12} r={1} />
                                  <circle cx={12} cy={5} r={1} />
                                  <circle cx={12} cy={19} r={1} />
                                </g>
                              </svg>
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* Body */}
                <div className="position-relative overflow-y-scroll bg-box-chat-gpt" id="users-chat" style={{ flexGrow: 1, }} ref={messagesEndRef}>
                  <div className="chat-conversation p-3 p-lg-4" id="chat-conversation" style={{ height: 'auto' }}>
                    {/* <div id="elmLoader" /> */}
                    <ul
                      className="list-unstyled chat-conversation-list "
                      id="users-conversation"
                    >
                      {(messages?.length > 0) ? (
                        messages?.map((message) => (
                          <li className={`chat-list ${message?.isByBot ? 'left' : 'right'}`} key={message?.id}>
                            <div className="conversation-list">
                              {message?.isByBot && (<div className="chat-avatar">
                                <img
                                  src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/chatgpt-icon.png"
                                  alt=""
                                />
                              </div>)}
                              <div className="user-chat-content">
                                <div className="ctext-wrap mb-0">
                                  <div className="ctext-wrap-content shadow-none">
                                    {(message?.isByBot && message?.status === 'TYPING') ?
                                      <TypingEffect ref={messagesEndRef} className="mb-0 ctext-content" handleEnd={handleEndTyping} id={message?.id} content={message?.message || ''} /> :
                                      <div className="mb-0 ctext-content" style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: message?.message || '' }}></div>
                                    }
                                  </div>
                                </div>
                                {/* <div className="conversation-name">
                                  <small className="text-muted time">{message?.time || ''}</small>{" "}
                                  <span className="text-success check-message-icon">
                                    <i className="ri-check-double-line align-bottom" />
                                  </span>
                                </div> */}
                              </div>
                            </div>
                          </li>
                        ))) : (<></>)}
                    </ul>
                    <div ref={bottomRef} />
                  </div>
                </div>
                {/* Footer */}
                <div className="chat-input-section p-3 p-lg-4">
                  <form id="chatinput-form" onSubmit={formik.handleSubmit}>
                    <div className="g-0 align-items-center row">
                      <div className="col">
                        <input
                          type="text"
                          ref={inputRef}
                          name="message"
                          className="form-control chat-input bg-light border-light fs-13"
                          id="chat-input"
                          autoComplete="off"
                          value={String(initialValueMessage ?? '')}
                          placeholder={`${t('Enter your message')}...`}
                          onChange={(event: any) => formik.setFieldValue('message', event?.target?.value || '')}
                          autoFocus={true}
                        />
                      </div>
                      <div className="col-auto">
                        <div className="chat-input-links ms-2">
                          <div className="links-list-item">
                            <button
                              type="submit"
                              className="chat-send waves-effect waves-light fs-13 btn btn-primary"
                              disabled={!String(initialValueMessage).trim() || !!isLoading}
                            >
                              {!!isLoading ? (<i className="ri-stop-fill align-bottom" />) : (<i className="ri-send-plane-2-fill align-bottom" />)}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Row>
    </React.Fragment >
  );
};

export default ChatGPT;