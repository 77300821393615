import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { putTrendIndexCategory } from "store/thunks";

interface ModalConfirmProps {
  item: any;
  header?: string;
  textButtonClose?: string;
  isShowButtonClose?: boolean;
  textButtonConfirm?: string;
  classButtonConfirm?: string;
  isShowButtonConfirm?: boolean;
  isOpen?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  triggerRefresh?: () => void;
}

const ModalConfirm = ({
  header,
  textButtonClose,
  isShowButtonClose = true,
  textButtonConfirm,
  isShowButtonConfirm = true,
  isOpen = false,
  onClose,
  item,
  triggerRefresh,
}: ModalConfirmProps) => {
  const { t } = useTranslation();

  const handleCloseConfirmClick = () => {
    onClose && onClose();
  };

  const [newName, setNewName] = React.useState(item?.name);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleActionConfirm = async (values: any) => {
    setIsLoading(true);
    const response: any = await putTrendIndexCategory(item.id, {
      name: newName,
    });
    setIsLoading(false);
    if (response) {
      onClose && onClose();
      triggerRefresh && triggerRefresh();
    }
  };

  useEffect(() => {
    setNewName(item?.name);
  }, [item]);

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} centered={true} size="md" keyboard={true}>
        <ModalHeader toggle={isLoading ? () => { } : onClose}>
          {header ?? t("Update Trend Index Category")}
        </ModalHeader>
        <ModalBody className="">
          <div className="row g-4">
            <Col sm={12}>
              <div className="row g-3">
                <Col xxl={12}>
                  <label className="form-label">{t("Name")} <span className="text-danger"> *</span></label>
                  <input
                    className="form-control"
                    id="Name"
                    name="Name"
                    value={newName}
                    placeholder={`${t("Name")}...`}
                    onChange={(e) => setNewName(e.target.value)}
                    autoFocus
                  />
                </Col>
                <div className="mt-4 text-center">
                  <div className="hstack gap-2 justify-content-end">
                    {isShowButtonConfirm && (
                      <button
                        className={`btn btn-primary fs-14`}
                        type="button"
                        onClick={handleActionConfirm}
                        color="success"
                        disabled={
                          item?.name === newName || !newName || isLoading
                        }
                      >
                        {isLoading ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-login-circle-line align-bottom me-1"></i>}
                        {textButtonConfirm ?? t("Button Save")}
                      </button>
                    )}
                    {isShowButtonClose && (
                      <button
                        className="btn btn-soft-secondary fs-14"
                        type="button"
                        color="light"
                        onClick={handleCloseConfirmClick}
                        disabled={isLoading}
                      >
                        <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                        {textButtonClose ?? t("Button Close")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ModalConfirm;
