import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import currencyApi from 'api/currencyApi';

export const getCurrencies = createAsyncThunk("Currencies" , async (params: any = {}) => {
  try{
    const response = await currencyApi.currencies(params)
    return response;
  }catch (error) {
    return error;
  }
});

export const getCategoriesExchangeRate = createAsyncThunk("ExchangeRate/getCategoriesExchangeRate" , async (params: any = {}) => {
  try{
    const response = await currencyApi.categories(params)
    return response;
  }catch (error) {
    return error;
  }
})

export const getExchangeRates = createAsyncThunk("ExchangeRates" , async (params: any = {}) => {
  try{
    const response = await currencyApi.currencyRates(params)
    return response;
  }catch (error) {
    return error;
  }
});

export const getAllCurrencies = async (params: any = {}) => {
  try{
    const response = await currencyApi.allCurrencies(params)
    return response;
  }catch (error) {
    return error;
  }
}

export const postCurrency = async (data: any = {}) => {
  try{
    const response = await currencyApi.postCurrency(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteCurrency = async (id: string | number , data: any = {}) => {
  try{
    const response = await currencyApi.deleteCurrency(id, data);
    return response;
  }catch (error) {
    return error;
  }
};