import { Navigate } from "react-router-dom";

//Dashboard
import DashboardCrm from "../pages/Dashboard";

//pages
import ComingSoon from '../pages/Pages/ComingSoon/ComingSoon';
import Settings from '../pages/Pages/Profile/Settings/Settings';

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import CampaignForm from "pages/Campaign/CampaignForm";
import CampaignList from "pages/Campaign/CampaignList";
import CarCategory from "pages/Car/Category";
import CarKeyword from "pages/Car/Keyword";
import CurrencyCategory from "pages/ExchangeRate/Category";
import Currency from "pages/ExchangeRate/Currency";
import ExchangeRateKeyword from "pages/ExchangeRate/Keyword";
import Media from "pages/Media/Media";
import Settlement from "pages/Media/Settlement";
import MovieCategory from "pages/Movie/Category";
import MovieKeyword from "pages/Movie/Keyword";
import NaverCategory from "pages/Naver/Category";
import NaverKeyword from "pages/Naver/Keyword";
import News from "pages/News";
import InfoGraphic from "pages/Template/InfoGraphic";
import TemplateListNewsContent from "pages/Template/TemplateListNewsContent";
import TemplateListNewsTitle from "pages/Template/TemplateListNewsTitle";
import VariableDefinition from "pages/Template/VariableDefinition";
import UserProfile from "../pages/Authentication/user-profile";
import TrendIndexKeyword from "pages/TrendIndex/Keyword";
import TrendIndexCategory from "pages/TrendIndex/Category";
import CampaignCreate from "pages/Campaign/CampaignForm";
import NewsLog from "pages/Statistic/NewsLog";
import NewsStatistic from "pages/Statistic/NewsStatistic";
import UserList from "pages/User/User";
import RoleList from "pages/User/Role";


const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardCrm /> },

  //Pages
  { path: "/pages-profile-settings", component: <Settings /> },
  //User Profile
  { path: "/profile", component: <UserProfile /> },

  { path: "/keyword/naver-category", component: <NaverCategory /> },

  { path: "keyword/naver-keyword", component: <NaverKeyword /> },

  { path: "keyword/car-category", component: <CarCategory /> },

  { path: "keyword/car-keyword", component: <CarKeyword /> },

  { path: "keyword/movie-category", component: <MovieCategory /> },

  { path: "keyword/movie-keyword", component: <MovieKeyword /> },

  { path: "keyword/exchange-rate-category", component: <CurrencyCategory /> },

  { path: "keyword/exchange-rate", component: <ExchangeRateKeyword /> },

  { path: "keyword/trend-index-category", component: <TrendIndexCategory /> },

  { path: "keyword/trend-index", component: <TrendIndexKeyword /> },

  { path: "campaign/list/:id", component: <CampaignForm /> },

  { path: "campaign/create", component: <CampaignCreate /> },

  { path: "campaign/list", component: <CampaignList /> },

  { path: "campaign/news", component: <News /> },

  { path: "template/news-title", component: <TemplateListNewsTitle /> },

  { path: "template/news-content", component: <TemplateListNewsContent /> },

  { path: "template/variable-definition", component: <VariableDefinition /> },

  { path: "template/info-graphic", component: <InfoGraphic /> },

  { path: "media", component: <Media /> },

  { path: "settlement", component: <Settlement /> },

  { path: "/exchange/currency", component: <Currency /> },

  { path: "/statistics/news-log", component: <NewsLog /> },

  { path: "/statistics/news-statistics", component: <NewsStatistic /> },

  { path: "/user/list", component: <UserList /> },

  { path: "/user/role", component: <RoleList /> },

  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  //AuthenticationInner pages
  { path: "/pages-coming-soon", component: <ComingSoon /> },
];

export { authProtectedRoutes, publicRoutes };
