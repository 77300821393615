import { ITemplate } from 'api/types/_template';
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import SearchParameter from 'components/Common/SearchParameter';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import * as Yup from 'yup';
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { deleteTemplate, getAllCategoriesType, getAllParameters as onGetAllParameters, getTemplates as onGetTemplates, postTemplate, putTemplate } from "../../../store/thunks";

import CategoryType from 'components/Common/CategoryType';
import ModalConfirm from 'components/Common/ModalConfirm';
import ScheduleType from 'components/Common/ScheduleType';
import { SCHEDULE_OPTIONS } from 'helpers/constans';

interface Option {
  label: string;
  value: string;
}

const typeQuery = {
  'id': StringParam,
  'schedule': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'id';
registerLocale('en', en);
registerLocale('ko', ko);

const TemplateListNewsTitle = () => {
  const { t, i18n } = useTranslation();

  const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.reduce((arr: any, item: any) => {
    return item?.value !== 'now' ? ([...arr, ({ value: item?.value, label: t(item?.label) })]) : arr;
  }, []);

  const [query, setQuery]: any = useQueryParams({
    type: withDefault(StringParam, `title`),
    category_type: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    content: StringParam,
    ...typeQuery
  });

  const rangeValue = useRef<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFullView, setIsFullView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [initialValuesDefault, setInitialValuesDefault] = useState<ITemplate | null>(null);

  const [categoryTypeSearch, setCategoryTypeSearch] = useState<Option | null>(null);

  const [scheduleTypeSearch, setScheduleTypeSearch] = useState<Option | null>(null);

  const [listCategoriesType, setListCategoriesType] = useState<Option[]>([]);

  const listCategoriesTypeLang = listCategoriesType?.map((item: any) => ({ ...item, label: t(`Category_Type_[${item?.value}]_For_Campaign`) }));

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Template;

  const TemplateProperties = createSelector(
    selectLayoutState,
    (state) => ({
      templates: state.templates,
      parameters: state.allParameter,
      isTemplateSuccess: state.isTemplateSuccess,
      isTemplateLoading: state.isTemplateLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { templates, parameters, error, isTemplateLoading } = useSelector(TemplateProperties);

  const searchData = () => {
    setQuery({
      ...query,
      content: keywordSearch || undefined,
      category_type: categoryTypeSearch?.value || '',
      schedule: scheduleTypeSearch?.value || '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
    });
  };

  const resetData = () => {
    setQuery({
      type: 'title',
      content: '',
      category_type: undefined,
      schedule: undefined,
      sort_by: TYPE_SELECT_DEFAULT,
    }, "push")
    setKeywordSearch("");
    setCategoryTypeSearch(null);
    setScheduleTypeSearch(null);
  };

  // Form
  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        type: 'title',
        name: values?.name ?? '',
        category_type: values?.category_type?.value ?? '',
        schedule: values?.schedule?.value ?? '',
        content: values?.content ?? '',
        sample_content: '',
      };
      const response: any = !!(initialValuesDefault?.id) ? await putTemplate(initialValuesDefault?.id, data) : await postTemplate(data);
      if (response?.data) {
        dispatch(onGetTemplates(query));
        setIsLoading((_prev) => false);
        setIsOpen((_prev) => false);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
        handleResetForm();
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchema = Yup.object({
    category_type: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }).required(`${t('Category Type is required')}`),
    schedule: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }).required(`${t('Schedule is required')}`),
    name: Yup.string().required(`${t('Template Name is required')}`),
    content: Yup.string().required(`${t('Title is required')}`),
    sample_content: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      category_type: null,
      schedule: null,
      name: '',
      content: '',
      sample_content: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleEditTemplate = (valueDefault: ITemplate) => {
    setInitialValuesDefault((_prev) => valueDefault);
    setIsOpen((_prev) => true);
    const vCategoryType = listCategoriesTypeLang.filter((item) => String(valueDefault?.category_type) === String(item?.value))[0] || listCategoriesTypeLang[0];
    const vSchedule = SCHEDULE_OPTIONS_LANG.filter((item: any) => String(valueDefault?.schedule) === String(item?.value))[0] || SCHEDULE_OPTIONS_LANG[0];
    formik.setFieldValue('category_type', vCategoryType);
    formik.setFieldValue('schedule', vSchedule);
    formik.setFieldValue('name', valueDefault?.name);
    formik.setFieldValue('content', valueDefault?.content);
    formik.setFieldValue('sample_content', valueDefault?.sample_content);
    setTimeout(() => {
      formik.setErrors({});
    }, 300)
  }

  const handleResetForm = () => {
    formik.setFieldValue('category_type', listCategoriesTypeLang[0]);
    formik.setFieldValue('schedule', SCHEDULE_OPTIONS_LANG[0]);
    formik.setFieldValue('name', null);
    formik.setFieldValue('content', null);
    formik.setFieldValue('sample_content', null);
    setTimeout(() => {
      formik.setErrors({});
    }, 300)
  }

  const handleCreateTemplate = () => {
    setInitialValuesDefault((_prev) => null);
    setIsOpen((_prev) => true);
    handleResetForm();
  }

  // Begin::Delete
  const handleConfirmDelete = (item: ITemplate) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteTemplate(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetTemplates(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete

  const onCloseClick = (e: any) => {
    e.preventDefault();
    setIsOpen((_prev) => false);
    setInitialValuesDefault((_prev) => null);
    setTimeout(() => {
      formik.setFieldValue('category_type', listCategoriesTypeLang[0]);
      formik.setFieldValue('schedule', SCHEDULE_OPTIONS_LANG[0]);
      formik.setFieldValue('name', null);
      formik.setFieldValue('content', null);
      formik.setFieldValue('sample_content', null);
    }, 300);
  };

  // Effect
  useEffect(() => {
    dispatch(onGetTemplates(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  useEffect(() => {
    dispatch(onGetAllParameters({}));
  }, []);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Category Type'),
        accessor: "category_type",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ minWidth: '80px' }}>{cell?.value ? t(`Category_Type_[${cell?.value}]_For_Campaign`) : ''}</div>
            </>
          )
        },
      },
      {
        Header: t('Template Name'),
        accessor: "name",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="limit-text-to-two-line cursor-pointer" onClick={() => handleEditTemplate(item)}>{cell?.value}</div>
            </>
          )
        },
      },
      {
        Header: t('Schedule'),
        accessor: "schedule",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '60px' }}>{SCHEDULE_OPTIONS_LANG?.filter((item: any) => (String(item?.value) === String(cell?.value)))[0]?.label || ''}</div>
          </>
        ),
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Button Action'),
        thClass: 'text-center',
        thWidth: 105,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline hstack gap-1 mb-0" style={{ width: '80px' }}>
              <li className="list-inline-item" title={t('Button Update Template')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handleEditTemplate(item) }}
                >
                  <i className="ri-pencil-fill align-bottom"></i>
                </Link>
              </li>
              <li className=" list-inline-item" title={t('Button Delete Template')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                >
                  <i className="ri-delete-bin-3-fill align-bottom"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [i18n?.language, listCategoriesTypeLang, SCHEDULE_OPTIONS_LANG]
  );


  const handleCallAllOption = async () => {
    try {
      const [resCategoriesType]: any = await Promise.all([getAllCategoriesType()]);
      setListCategoriesType((_prev) => (resCategoriesType?.data?.map((item: any) => ({ value: item?.type, label: item?.name })) || []));
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  useEffect(() => {
    if (categoryTypeSearch?.value) {
      setCategoryTypeSearch((_prev: any) =>
        listCategoriesTypeLang?.filter((e: any) => e.value === categoryTypeSearch?.value)[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Template News Title')} - ${t('Template')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Template News Title')} pageTitle={t('Template')} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg={12} className="text-end  mb-2 mb-md-0 d-block d-md-none">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={handleCreateTemplate}
                      >
                        <i className="ri-add-fill align-bottom me-1"></i>{" "}
                        {t('Button Create Template')}
                      </button>
                    </Col>
                  </Row>
                  <Row className="g-4 align-items-center mb-2">
                    <Col sm={12} md={2} lg={2}>
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={`${t('Template')}...`}
                        value={keywordSearch}
                        onChange={(e) => setKeywordSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            searchData();
                          }
                        }}
                      />
                    </Col>
                    <Col sm={12} md={2} lg={2}>
                      <Select
                        options={listCategoriesTypeLang}
                        value={categoryTypeSearch || null}
                        onChange={(e: any) => setCategoryTypeSearch(e)}
                        placeholder={<div>{`${t('Category Type')}...`}</div>}
                        className="search-filter-category-type"
                        classNamePrefix="name-prefix"
                      />
                    </Col>
                    <Col sm={12} md={2} lg={2}>
                      <Select
                        options={SCHEDULE_OPTIONS_LANG}
                        value={scheduleTypeSearch || SCHEDULE_OPTIONS_LANG[0]}
                        onChange={(e: any) => setScheduleTypeSearch(e)}
                        placeholder={<div>{`${t('Schedule')}...`}</div>}
                        className="search-filter-category-type"
                        classNamePrefix="name-prefix"
                      />
                    </Col>
                    <Col sm={12} lg={6} className="hstack gap-1 justify-content-sm-center justify-content-md-between">
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary me-1"
                          onClick={searchData}
                          disabled={isTemplateLoading}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t('Button Search')}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t('Button Reset')}
                        </button>
                      </div>
                      <button
                        type="button"
                        className="btn btn-success d-none d-md-block"
                        onClick={handleCreateTemplate}
                      >
                        <i className="ri-add-fill align-bottom me-1"></i>{" "}
                        {t('Button Create Template')}
                      </button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={templates?.list?.length ? templates?.list : []}
                      customPageSize={query.limit}
                      customPageCount={Math.ceil(Number(templates?.pagination?.total) / Number(templates?.pagination?.limit))}
                      customPageIndex={query.page - 1}
                      totalRecords={templates?.pagination?.total}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isTemplateLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={isOpen} centered={true} size="xl" className="modal-fullscreen" scrollable={true}>
          <ModalHeader toggle={isLoading ? () => { } : onCloseClick}>
            {t('Template News Title')}
          </ModalHeader>
          <ModalBody className="">
            <div className="row g-4">
              <Col md={isFullView ? 1 : 5} className="order-sm-2 order-md-1 position-relative">
                <SearchParameter data={parameters || []} style={{ minHeight: 'calc(100dvh - 100px)' }} />
                <div style={{
                  position: 'absolute',
                  top: '0px',
                  right: 0,
                  width: '45px',
                  height: '45px',
                  fontSize: '30px',
                  textAlign: 'center',
                  verticalAlign: 'middle',
                  backgroundColor: 'var(--vz-primary)',
                  color: 'var(--vz-white)',
                  transform: 'translateX(-12px)',
                  borderTopLeftRadius: '22px',
                  borderBottomLeftRadius: '22px',
                  cursor: 'pointer',
                  boxShadow: '0px 0px 20px 0px rgba(76, 87, 125, 0.02)'
                }}
                  onClick={() => setIsFullView((prev) => !prev)}
                >
                  {isFullView ? <i className="mdi mdi-arrow-right-circle-outline" /> : <i className="mdi mdi-arrow-left-circle-outline" />}
                </div>
              </Col>
              <Col md={isFullView ? 11 : 7} className="order-sm-1 order-md-2">
                <form onSubmit={formik.handleSubmit}>
                  <div className="row g-3">
                    <Col sm={7} md={7} lg={7} className="d-flex justify-content-between align-items-end">
                      <div style={{ width: '100%' }}>
                        <label className="form-label">{t('Category Type')}<span className="text-danger"> *</span></label>
                        <CategoryType name="category_type" dataList={listCategoriesTypeLang} placeholder={`${t('Select Category Type')}...`} initialValue={formik?.values?.category_type || listCategoriesTypeLang[0]} onChangeSelect={(event) => {
                          formik.setFieldValue('category_type', event);
                        }} />
                        {formik.touched.category_type && formik.errors.category_type ? (
                          <div className="text-danger mt-2">{formik.errors.category_type}</div>
                        ) : null}
                      </div>
                    </Col>
                    <Col sm={5} md={5} lg={5}>
                      <div>
                        <label className="form-label">
                          {t("Schedule")}
                          <span className="text-danger"> *</span>
                        </label>
                        <ScheduleType
                          name="schedule"
                          dataList={SCHEDULE_OPTIONS_LANG}
                          placeholder={`${t('Select Schedule')}...`}
                          initialValue={formik?.values?.schedule || SCHEDULE_OPTIONS_LANG[0]}
                          onChangeSelect={(event) => {
                            formik.setFieldValue('schedule', event);
                          }}
                        />
                        {formik.touched.schedule && formik.errors.schedule ? (<div className="text-danger mt-2">{formik?.errors?.schedule}</div>) : null}
                      </div>
                    </Col>
                    <Col xxl={12}>
                      <label className="form-label">{t('Template Name')}<span className="text-danger"> *</span></label>
                      <input
                        className="form-control"
                        id="name"
                        name="name"
                        value={formik?.values?.name}
                        placeholder={`${t('Template Name')}...`}
                        onChange={(event: any) => formik.setFieldValue('name', event?.target?.value || '')}
                      />
                      {formik.touched.name && formik.errors.name ? (<div className="text-danger mt-2">{formik.errors.name}</div>) : null}
                    </Col>
                    <Col xxl={12}>
                      <label className="form-label">{t('Title News')}<span className="text-danger"> *</span></label>
                      <input
                        className="form-control"
                        id="content"
                        name="content"
                        value={formik?.values?.content}
                        placeholder={`${t('Title News')}...`}
                        onChange={(event: any) => formik.setFieldValue('content', event?.target?.value || '')}
                      />
                      {formik.touched.content && formik.errors.content ? (<div className="text-danger mt-2">{formik.errors.content}</div>) : null}
                    </Col>
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <button className="btn btn-primary fs-14" color="success" type="submit" disabled={isLoading}>
                          {isLoading ? <Spinner size="sm" className='me-2'></Spinner> : (!!(initialValuesDefault?.id) ? <i className="ri-login-circle-line align-bottom me-1"></i> : <i className="ri-add-fill align-bottom me-1"></i>)}
                          {!!(initialValuesDefault?.id) ? t('Button Update Template') : t('Button Create Template')}
                        </button>
                        <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onCloseClick} disabled={isLoading}>
                          <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                          {t('Button Close')}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </div>
          </ModalBody>
        </Modal>

        <ModalConfirm
          header={t('Button Delete Template')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
      </div>
    </React.Fragment>
  );
};

export default TemplateListNewsTitle;