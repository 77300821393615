import React, { useEffect, useState } from 'react';
import CountUp from "react-countup";
import { Card, CardBody, Col } from 'reactstrap'
import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getDashboard as onGetDashboard } from 'store/dashboard/thunk';
import { useTranslation } from 'react-i18next';
import { IDashboard } from 'api/types/_dashboard';

const Widgets = () => {
    const { t } = useTranslation();
    const [data, setData] = useState<IDashboard>();


    const dispatch: any = useDispatch();
    const selectLayoutState = (state: any) => state.Dashboard;
    const naverDashboardProperties = createSelector(
        selectLayoutState,
        (state) => ({
            dashboard: state.dashboard,
            isDashboardSuccess: state.isDashboardSuccess,
            error: state.error,
        })
    );
    // Inside your component
    const {
        dashboard, error
    } = useSelector(naverDashboardProperties);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await dispatch(onGetDashboard({}));
                if (response?.payload?.data) {
                    setData(response?.payload?.data);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [dispatch]);



    const dataDashboard = [
        // {
        //     id: 1,
        //     cardColor: "primary",
        //     label: t('Categories'),
        //     badge: "ri-arrow-right-up-line",
        //     percentage: data?.keyword_categories?.last_updated,
        //     counter: data?.keyword_categories?.count,
        //     link: "View net earnings",
        //     icon: "bx bx-category",
        //     decimals: 0,
        //     prefix: "",
        //     suffix: "",
        //     bgcolor: "success"
        // },
        {
            id: 2,
            cardColor: "primary",
            label: t('DashBoard Naver Keyword'),
            badge: "ri-arrow-right-down-line",
            percentage: data?.keywords?.last_updated,
            counter: data?.keywords?.count,
            link: "View all orders",
            icon: "bx bx-search-alt ",
            decimals: 0,
            prefix: "",
            suffix: "",
            bgcolor: "primary-subtle text-primary"
        },
        {
            id: 3,
            cardColor: "secondary",
            label: t('DashBoard Car Model'),
            badge: "ri-arrow-right-down-line",
            percentage: data?.car_models?.last_updated,
            counter: data?.car_models?.count,
            link: "View all orders",
            icon: "bx bx-car",
            decimals: 0,
            prefix: "",
            suffix: "",
            bgcolor: "primary-subtle text-primary"
        },
        {
            id: 4,
            cardColor: "secondary",
            label: t('DashBoard Movie'),
            badge: "ri-arrow-right-down-line",
            percentage: data?.movies?.last_updated,
            counter: data?.movies?.count,
            link: "View all orders",
            icon: "bx bxs-camera-movie",
            decimals: 0,
            prefix: "",
            suffix: "",
            bgcolor: "primary-subtle text-primary"
        },
        {
            id: 6,
            cardColor: "secondary",
            label: t('DashBoard News'),
            badge: "ri-arrow-right-down-line",
            percentage: data?.news?.last_updated,
            counter: data?.news?.count,
            link: "View all orders",
            icon: "bx bxs-news",
            decimals: 0,
            prefix: "",
            suffix: "",
            bgcolor: "primary-subtle text-primary"
        },
        {
            id: 6,
            cardColor: "secondary",
            label: t('DashBoard Template'),
            badge: "ri-arrow-right-down-line",
            percentage: data?.templates?.last_updated,
            counter: data?.templates?.count,
            link: "View all orders",
            icon: "bx bx-layer",
            decimals: 0,
            prefix: "",
            suffix: "",
            bgcolor: "success-subtle text-success"
        },
        {
            id: 6,
            cardColor: "secondary",
            label: t('DashBoard Campaign'),
            badge: "ri-arrow-right-down-line",
            percentage: data?.campaigns?.last_updated,
            counter: data?.campaigns?.count,
            link: "View all orders",
            icon: "bx bx-bookmarks",
            decimals: 0,
            prefix: "",
            suffix: "",
            bgcolor: "success-subtle text-success"
        },
        {
            id: 5,
            cardColor: "secondary",
            label: t('DashBoard Trend Index Category'),
            badge: "ri-arrow-right-down-line",
            percentage: data?.trend_index_categories?.last_updated,
            counter: data?.trend_index_categories?.count,
            link: "View all orders",
            icon: "bx bx-category",
            decimals: 0,
            prefix: "",
            suffix: "",
            bgcolor: "success-subtle text-success"
        },
        {
            id: 6,
            cardColor: "secondary",
            label: t('DashBoard Trend Index'),
            badge: "ri-arrow-right-down-line",
            percentage: data?.trend_indexs?.last_updated,
            counter: data?.trend_indexs?.count,
            link: "View all orders",
            icon: "bx bx-search-alt",
            decimals: 0,
            prefix: "",
            suffix: "",
            bgcolor: "success-subtle text-success"
        },
    ];
    return (
        <React.Fragment>
            {dataDashboard.map((item: any, key: any) => (
                <Col sm={6} md={6} xl={3} key={key}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{item.label}</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={item.prefix}
                                            suffix={item.suffix}
                                            separator={item.separator}
                                            end={item.counter}
                                            decimals={item.decimals}
                                            duration={2}
                                        />
                                    </span></h4>
                                    <span className="">{t('Updated')}: {item.percentage}</span>
                                </div>
                                <div className="avatar-sm flex-shrink-0">
                                    <span className={"avatar-title rounded fs-3 bg-" + item.bgcolor}>
                                        <i className={`${item.icon}`}></i>
                                    </span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>))}
        </React.Fragment>
    );
};

export default Widgets;