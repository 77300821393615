import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { INewsLog} from "./types/_statistic";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = '/trackings';
const statisticApi = {
  newsLogs(params: any):Promise<AxiosResponse<ResponseData<INewsLog[]> & PaginationResponse, any>> {
    const url = `${path}/news`
    return api.get(url, params)
  },
}
export default statisticApi
