import { ICampaign } from 'api/types/_campaign';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { deleteCampaign, getAllCategoriesNaverNormal, getAllCategoriesTrendIndexNormal, getAllCategoriesType, getAllMedias, getAllTemplates, getAllTypes, getCampaigns as onGetCampaigns } from "../../../store/thunks";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from 'common/toast';
import HashtagInput from 'components/Common/HashtagInput';
import ModalConfirm from 'components/Common/ModalConfirm';
import SearchFilterKeyword from 'components/Common/SearchFilterKeyword';
import { SCHEDULE_MONTHLY_OPTIONS, SCHEDULE_OPTIONS, SCHEDULE_WEEKLY_OPTIONS, STATUS_CAMPAIGN, STATUS_CAMPAIGN_OPTIONS } from 'helpers/constans';
import { Option } from 'api/types/_public';
import CollapseTag from 'components/Common/CollapseTag';
import KeywordInput from 'components/Common/KeywordInput';
import CampaignForm from '../CampaignForm/CampaignForm';
import { IMedia, IType } from 'api/types/_media';
import { ITemplate } from 'api/types/_template';
import { ICategory } from 'api/types/_category';
import SearchFilterMedia from 'components/Common/SearchFilterMedia';

const typeQuery = {
  'sales': StringParam,
  'audience': StringParam,
  'screen': StringParam,
  'status': StringParam,
  'schedule': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'audience';

registerLocale('en', en);
registerLocale('ko', ko);


const CampaignList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [query, setQuery]: any = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    // sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    // order_by: withDefault(StringParam, `DESC`),
    name: StringParam,
    category_type: withDefault(StringParam, ''),
    keyword_ids: withDefault(StringParam, ''),
    hashtags: withDefault(StringParam, ''),
    partner_ids: withDefault(StringParam, ''),
    start_date: withDefault(StringParam, ''),
    end_date: withDefault(StringParam, ''),
    ...typeQuery
  });

  const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const STATUS_CAMPAIGN_OPTIONS_LANG = STATUS_CAMPAIGN_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const rangeValue = useRef<any>();
  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] = useState<ICampaign | null>(null);

  const [dateSearch, setDateSearch] = useState<any[]>([query?.start_date || "", query?.end_date || ""]);
  const [startDate, endDate] = dateSearch;

  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
  const [fromRangeSearch, setFromRangeSearch] = useState<string>("");
  const [toRangeSearch, setToRangeSearch] = useState<string>("");

  const [campaignEdit, setCampaignEdit] = useState<ICampaign & { isCopy?: boolean } | null>(null);

  const [categoryTypeSearch, setCategoryTypeSearch] = useState<Option | null>(null);

  const [scheduleTypeSearch, setScheduleTypeSearch] = useState<Option | null>(null);

  const [statusSearch, setStatusSearch] = useState<Option | null>(null);

  const [keywordsSearch, setKeywordsSearch] = useState<Option[]>([]);

  const [hashtagsSearch, setHashtagsSearch] = useState<Option[]>([]);

  const [mediasSearch, setMediasSearch] = useState<Option[]>([]);




  // Begin::List Option Edit Campaign
  const [listType, setListType] = useState<IType[]>([]);

  const [listMedia, setListMedia] = useState<IMedia[]>([]);

  const [listTemplate, setListTemplate] = useState<ITemplate[]>([]);

  const [listCategoriesType, setListCategoriesType] = useState<Option[]>([]);

  const [listCategoriesNaver, setListCategoriesNaver] = useState<ICategory[]>([]);

  const [listCategoriesTrendIndex, setListCategoriesTrendIndex] = useState<ICategory[]>([]);

  const listCategoriesTypeLang = listCategoriesType?.map((item: any) => ({ ...item, label: t(`Category_Type_[${item?.value}]_For_Campaign`) }))?.filter((item) => (item?.type !== 'exchange_rate'));
  // End::List Option Edit Campaign

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Campaign;

  const campaignProperties = createSelector(
    selectLayoutState,
    (state) => ({
      campaigns: state.campaigns,
      isCampaignSuccess: state.isCampaignSuccess,
      isCampaignLoading: state.isCampaignLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { campaigns, error, isCampaignLoading } = useSelector(campaignProperties);

  useEffect(() => {
    dispatch(onGetCampaigns(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      name: keywordSearch || undefined,
      // sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      category_type: categoryTypeSearch?.value || '',
      status: statusSearch?.value || '',
      schedule: scheduleTypeSearch?.value || '',
      keyword_ids: (keywordsSearch || [])?.map((item) => item?.value).join(',') || '',
      hashtags: (hashtagsSearch || [])?.map((item) => item?.value).join(',') || '',
      partner_ids: (mediasSearch || [])?.map((item) => item?.value).join(',') || '',
      start_date: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      end_date: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
    });
  };

  const resetData = () => {
    setQuery({
      name: undefined,
      // sort_by: undefined,
      category_type: undefined,
      status: undefined,
      schedule: undefined,
      keyword_ids: undefined,
      hashtags: undefined,
      partner_ids: undefined,
      start_date: undefined,
      end_date: undefined,
    }, "push")
    setKeywordSearch("");
    setFromRangeSearch("");
    setToRangeSearch("");
    setDateSearch(["", ""]);
    setCategoryTypeSearch(null);
    setScheduleTypeSearch(null);
    setStatusSearch(null);
    setKeywordsSearch([]);
    setHashtagsSearch([]);
    setMediasSearch([]);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Campaign Name'),
        accessor: "name",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Category Type'),
        accessor: "category_type",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '120px' }}>{cell?.value ? t(`Category_Type_[${cell?.value}]_For_Campaign`) : ''}</div>
          </>
        ),
      },
      {
        Header: t('Medias'),
        accessor: "partners",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const partners = value?.partners;
          let partnerName: string = "";
          for (let item of partners) {
            partnerName += `<span class='tag-style'>${item.name} </span>`;
          }
          return (
            <div dangerouslySetInnerHTML={{ __html: partnerName }} />
          );
        }
      },
      {
        Header: t('Keywords'),
        accessor: "keywords",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const keywords = (value?.keywords || [])?.map((item: any) => item.keyword)?.filter((item: string) => !!item);
          return (<CollapseTag totalShow={10} tags={keywords} />);
        }
      },
      {
        Header: t('Hashtags'),
        accessor: "tag_related_news",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const tag_related_news = ((value?.tag_related_news || '').split(',') || [])?.filter((item: string) => !!item);
          return (<CollapseTag totalShow={10} tags={tag_related_news} />);
        }
      },
      {
        Header: t('Schedule'),
        accessor: "schedule",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '60px' }}>{SCHEDULE_OPTIONS_LANG?.filter((item: any) => (String(item?.value) === String(cell?.value)))[0]?.label || ''}</div>
          </>
        ),
      },
      {
        Header: t('Date'),
        accessor: "date",
        filterable: true,
        sortable: false,
        thWidth: 60,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          let day = item?.date || '';
          if (item?.schedule === 'weekly') {
            day = SCHEDULE_WEEKLY_OPTIONS?.filter((it: any) => String(it?.value) === String(item?.date))[0]?.label || '';
          } else if (item?.schedule === 'monthly') {
            day = SCHEDULE_MONTHLY_OPTIONS?.filter((it: any) => String(it?.value) === String(item?.date))[0]?.label || '';
          }
          return (<>
            <div style={{ minWidth: '80px' }}>
              {day ? t(`${day}`) : item?.date}
            </div>
          </>);
        },
      },
      {
        Header: t('Time'),
        accessor: "time",
        filterable: true,
        sortable: false,
        thWidth: 60,
        Cell: (cell: any) => (
          <>
            <div style={{ minWidth: '60px' }}>{cell?.value || ''}</div>
          </>
        ),
      },
      {
        Header: t('Status'),
        accessor: "status",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const status = value?.status;
          const color = (status === STATUS_CAMPAIGN.WAITING) ? 'primary' : (status === STATUS_CAMPAIGN.RUNNING ? 'success' : 'danger');
          return (<div style={{ minWidth: '60px' }}><span className={`badge bg-${color}`}>{`${t(`Status_Campaign_${status}`)}`}</span></div>);
        }
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Button Action'),
        thClass: 'text-center',
        thWidth: 100,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0 w-100 justify-content-end">
              <li className="list-inline-item" title={t('Button Update Campaign')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setCampaignEdit((prev) => item)
                  }}>
                  <i className="ri-pencil-fill align-bottom"></i>
                </Link>
              </li>
              <li className="list-inline-item" title={t('Button Copy Campaign')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); setCampaignEdit((prev) => ({ ...item, isCopy: true })) }}
                >
                  <i className="ri-file-copy-fill align-bottom"></i>
                </Link>
              </li>
              <li className="list-inline-item" title={t('Button Delete Campaign')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); handleConfirmDelete(item) }}
                >
                  <i className="ri-delete-bin-3-fill align-bottom"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [i18n?.language]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  // Begin::Delete
  const handleConfirmDelete = (item: ICampaign) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!(initialValuesDefault?.id)) { return; };
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteCampaign(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetCampaigns(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  }
  // End::Delete


  // Begin::Update Campaign
  const onCloseClick = () => {
    setCampaignEdit((_prev) => null);
  }

  //End::Update Campaign

  const handleCallAllOption = async () => {
    try {
      const [
        resMedia,
        resTemplate,
        resType,
        resCategoriesType,
        resCategoriesNaver,
        resCategoriesTrendIndex,
      ]: any = await Promise.all([
        getAllMedias(),
        getAllTemplates(),
        getAllTypes(),
        getAllCategoriesType(),
        getAllCategoriesNaverNormal(),
        getAllCategoriesTrendIndexNormal(),
      ]);
      setListMedia((_prev) => resMedia?.data || []);
      setListTemplate((_prev) => resTemplate?.data || []);
      setListType((_prev) => resType?.data || []);
      setListCategoriesType(
        (_prev) =>
          resCategoriesType?.data?.map((item: any) => ({
            value: item?.type,
            label: item?.name,
          })) || []
      );
      setListCategoriesNaver((_prev) => resCategoriesNaver?.data || []);
      setListCategoriesTrendIndex(
        (_prev) => resCategoriesTrendIndex?.data || []
      );
    } catch (error: any) {
      return error;
    }
  };

  const triggerRefresh = () => {
    onCloseClick();
    dispatch(onGetCampaigns(query));
  }

  useEffect(() => {
    handleCallAllOption();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (categoryTypeSearch?.value) {
      setCategoryTypeSearch((_prev: any) =>
        listCategoriesTypeLang?.filter((e: any) => e.value === categoryTypeSearch?.value)[0]
      );
    }
    if (scheduleTypeSearch?.value) {
      setScheduleTypeSearch((_prev: any) => SCHEDULE_OPTIONS_LANG?.filter((e: any) => e.value === scheduleTypeSearch?.value)[0]);
    }

    if (statusSearch?.value) {
      setStatusSearch((_prev: any) => STATUS_CAMPAIGN_OPTIONS_LANG?.filter((e: any) => e.value === statusSearch?.value)[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Campaign')} - ${t('Campaign')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Campaign')} pageTitle={t('Campaign')} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg={12} className="text-end  mb-2 mb-md-0 d-block d-md-none">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => navigate('/campaign/create')}
                      >
                        <i className="ri-upload-2-line align-bottom me-1"></i>{" "}
                        {t('Button Create Campaign')}
                      </button>
                    </Col>
                  </Row>
                  <div className="align-items-center mb-2">
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={12} md={2} lg={2} className='mt-2'>
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t('Campaign Name')}...`}
                          value={keywordSearch}
                          onChange={(e) => setKeywordSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </Col>
                      <Col sm={12} md={2} lg={2} className='mt-2'>
                        <Select
                          options={listCategoriesTypeLang}
                          value={categoryTypeSearch || null}
                          onChange={(e: any) => setCategoryTypeSearch(e)}
                          placeholder={<div>{`${t('Category Type')}...`}</div>}
                          className="search-filter-category-type"
                          classNamePrefix="name-prefix"
                        />
                      </Col>
                      <Col sm={12} md={2} lg={2} className='mt-2'>
                        <Select
                          options={SCHEDULE_OPTIONS_LANG}
                          value={scheduleTypeSearch || null}
                          onChange={(e: any) => setScheduleTypeSearch(e)}
                          placeholder={<div>{`${t('Schedule')}...`}</div>}
                          className="search-filter-category-type"
                          classNamePrefix="name-prefix"
                        />
                      </Col>
                      <Col sm={12} md={2} lg={2} className='mt-2'>
                        <Select
                          options={STATUS_CAMPAIGN_OPTIONS_LANG}
                          value={statusSearch || null}
                          onChange={(e: any) => setStatusSearch(e)}
                          placeholder={<div>{`${t('Status')}...`}</div>}
                          className="search-filter-category-type"
                          classNamePrefix="name-prefix"
                        />
                      </Col>
                    </Row>
                    <Row className="g-4 align-items-center mt-0">
                      <Col sm={12} md={2} lg={2} className='mt-2'>
                        <div>
                          <SearchFilterMedia
                            name="medias"
                            dataList={listMedia}
                            initialValue={mediasSearch || []}
                            onChangeSelect={(event: any) => {
                              setMediasSearch((_prev) => event);
                            }}
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={2} lg={2} className='mt-2'>
                        <div>
                          <SearchFilterKeyword initialValue={keywordsSearch || []} onChangeSelect={(event) => {
                            setKeywordsSearch((_prev: any) => event);
                          }} />
                        </div>
                      </Col>
                      <Col sm={12} md={2} lg={2} className='mt-2'>
                        <div>
                          <HashtagInput initialValue={hashtagsSearch || []} placeholder={`${t('Hashtags')}...`} onChangeTag={(event) => {
                            setHashtagsSearch((_prev: any) => event);
                          }} />
                        </div>
                      </Col>
                      <Col sm={12} md={2} lg={2} className="mt-2 date-picker-wrapper-custom">
                        <DatePicker
                          selectsRange={true}
                          className="form-control search"
                          placeholderText={`${t('Date News')}...`}
                          startDate={startDate || null}
                          endDate={endDate || null}
                          dateFormat="yyyy-MM-dd"
                          isClearable={true}
                          locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                          onChange={handleChangePicker}
                        />
                      </Col>
                      <Col sm={12} lg={4} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                            disabled={isCampaignLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t('Button Search')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t('Button Reset')}
                          </button>
                        </div>
                        <button
                          type="button"
                          className="btn btn-success d-none d-md-block"
                          onClick={() => navigate('/campaign/create')}
                        >
                          <i className="ri-add-fill align-bottom me-1"></i>{" "}
                          {t('Button Create Campaign')}
                        </button>
                      </Col>
                    </Row>
                  </div>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={campaigns?.list?.length ? campaigns?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={campaigns?.pagination?.total}
                      customPageCount={Math.ceil(Number(campaigns?.pagination?.total) / Number(campaigns?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isCampaignLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={!!campaignEdit?.id}
          centered={true}
          size="xl"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseClick}>
            <>{!!(campaignEdit?.isCopy) ? t("Button Create Campaign") : t("Button Update Campaign")}</>  <>{!(campaignEdit?.isCopy) && (' - ' + campaignEdit?.name || '')}</>
          </ModalHeader>
          <ModalBody>
            <div style={{ minHeight: "calc(100dvh - 200px)" }}>
              <CampaignForm
                isModal={true}
                id={String(campaignEdit?.id || '')}
                isCopy={!!(campaignEdit?.isCopy)}
                listType={listType}
                listMedia={listMedia}
                listTemplate={listTemplate}
                listCategoriesType={listCategoriesType}
                listCategoriesNaver={listCategoriesNaver}
                listCategoriesTrendIndex={listCategoriesTrendIndex}
                triggerRefresh={triggerRefresh}
              />
            </div>
          </ModalBody>
        </Modal>
        <ModalConfirm
          header={t('Button Delete Campaign')}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
      </div>
    </React.Fragment>
  );
};

export default CampaignList;
