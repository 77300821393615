import { SCHEDULE_OPTIONS } from "helpers/constans";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
interface Option {
  label: string;
  value: string;
  content?: string;
  sample_content?: string;
  image_chart?: string;
  schedule?: string;
}
interface SearchTemplateProps {
  name?: string;
  templates?: any[];
  type?: "title" | "body";
  initialValue?: Option;
  filterByCategoryType?: string | number;
  filterBySchedule?: string | number;
  onChangeSelect?: (params: Option) => void;
  placeholder?: string;
}

const SearchTemplate = ({
  name = "",
  initialValue,
  templates,
  type = "title",
  filterByCategoryType = "",
  filterBySchedule = "",
  onChangeSelect,
  placeholder,
}: SearchTemplateProps) => {
  const { t } = useTranslation();
  const SCHEDULE_OPTIONS_LANG = SCHEDULE_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
  const [option, setOption] = useState<Option>();
  const [optionChoose, setOptionChoose] = useState<Option | null>(
    initialValue ?? null
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  const onClickToggleModal = () => {
    if (!isOpen) {
      setOptionChoose((_prev) => initialValue ?? null);
    }
    setIsOpen((_prev) => !_prev);
  };

  const onClickSetValue = () => {
    if (optionChoose) {
      onChangeSelect && onChangeSelect(optionChoose);
    }
    onClickToggleModal();
  };

  const options: Option[] = useMemo(() => {
    return (
      templates?.reduce((arr, item: any) => {
        if (
          item?.type === type &&
          item?.category_type === filterByCategoryType &&
          (item?.schedule === filterBySchedule || filterBySchedule === 'now')
        ) {
          return [
            ...arr,
            {
              label: item?.name,
              value: item?.id,
              content: item?.content,
              sample_content: item?.sample_content,
              image_chart: item?.chart?.image_url,
              schedule: item?.schedule,
            },
          ];
        }
        return arr;
      }, []) || []
    );
  }, [templates, filterByCategoryType, filterBySchedule, type]);

  useEffect(() => {
    if (initialValue?.value) {
      const op = options?.filter((item) => String(item?.value) === String(initialValue?.value))[0] || null;
      setOption((_prev) => (op));
      setOnChangeOption(op);
    }
  }, [initialValue, JSON.stringify(options)]);

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-end position-relative">
        <Select
          options={options}
          value={option}
          name={name}
          onChange={(e: any) => setOnChangeOption(e)}
          placeholder={<div>{placeholder || ""}</div>}
          loadingMessage={() => <div>{t("Loading")}...</div>}
          noOptionsMessage={() => <div>{t("No Options")}</div>}
          className="w-100"
        />
        <button
          className="btn btn-light fs-14 box-hover-primary"
          color="success"
          type="button"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2,
            backgroundColor: "transparent",
          }}
          onClick={onClickToggleModal}
        >
          {/* <i className="ri-eye-fill align-bottom"></i> */}
        </button>
      </div>
      <Modal
        isOpen={isOpen}
        centered={true}
        size="xl"
        style={{ maxHeight: "80vh" }}
      >
        <ModalHeader toggle={onClickToggleModal}>
          {type === "title" ? t("Title Template") : t("Content Template")}
        </ModalHeader>
        <ModalBody>
          <div className="row g-4">
            <Col md={12}>
              <div
                className={`table-light w-100 rounded-3 h-100 overflow-auto overflow-x-hidden ${type === 'title' ? 'overflow-y-auto' : 'overflow-y-hidden'}`}
                style={{
                  maxHeight: "80vh",
                  minHeight: "calc(100dvh - 180px)",
                  border: "1px solid var(--vz-table-border-color)",
                }}
              >
                <Row
                  className="cursor-pointer"
                  style={{
                    position: "sticky",
                    top: -1,
                    zIndex: 9,
                    backgroundColor: "var(--vz-table-bg)",
                  }}
                >
                  <Col
                    xs={4}
                    className="px-4 py-3 text-muted fs-15 fw-semibold"
                    style={{
                      borderRight: "1px solid var(--vz-table-border-color)",
                    }}
                  >
                    {type === "title"
                      ? t("Title Template Preview")
                      : t("Content Template Preview")}
                  </Col>
                  <Col
                    xs={8}
                    className="px-4 py-3 text-muted fs-15 fw-semibold"
                    style={{}}
                  >
                    {type === "title"
                      ? t("Title Sample Preview")
                      : t("Content Sample Preview")}
                  </Col>
                </Row>
                {type === "title" ? (
                  <>
                    {options?.map((item, index: number) => (
                      <Row
                        key={item?.value}
                        className="cursor-pointer table-hover-td-content-preview"
                        style={{
                          ...(item?.value === optionChoose?.value
                            ? { backgroundColor: "rgba(37,160,226,0.3)" }
                            : index % 2 === 0
                              ? { backgroundColor: "var(--vz-input-bg-custom)" }
                              : { backgroundColor: "var(--vz-table-bg)" }),
                          ...(index === options?.length - 1
                            ? {
                              borderBottom:
                                "1px solid var(--vz-table-border-color)",
                            }
                            : {}),
                        }}
                        onClick={() => setOptionChoose(item)}
                      >
                        <Col
                          xs={4}
                          className="px-4 py-3"
                          style={{
                            borderRight:
                              "1px solid var(--vz-table-border-color)",
                          }}
                        >
                          <div
                            className="d-flex justify-content-start align-items-center form-check me-3"
                            style={{
                              verticalAlign: "middle",
                              display: "inline-flex",
                            }}
                          >
                            <input
                              className="form-check-input me-2"
                              type="radio"
                              name="flexRadioDefault"
                              id={`flexRadioDefault${index}`}
                              checked={item?.value === optionChoose?.value}
                              style={{ minWidth: "16px" }}
                            />
                            <label
                              className="form-check-label position-relative"
                              htmlFor={`flexRadioDefault${index}`}
                            >
                              {item?.label}
                              {item?.schedule && (<span
                                className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                style={{ transform: "translate(80%, -80%)" }}
                              >
                                {SCHEDULE_OPTIONS_LANG?.filter((item: any) => (String(item?.value) === String(item?.schedule)))[0]?.label || ''}
                              </span>)}
                            </label>

                          </div>
                        </Col>
                        <Col xs={8} className="px-4 py-3">
                          {item?.sample_content}
                        </Col>
                      </Row>
                    ))}
                  </>
                ) : (
                  <Row>
                    <Col
                      xs={4}
                      style={{
                        borderRight: "1px solid var(--vz-table-border-color)",
                        maxHeight: 'calc(100dvh - 245px)',
                        minHeight: 'calc(100dvh - 245px)',
                        overflowY: 'scroll',
                      }}
                    >
                      {options?.map((item, index: number) => (
                        <Row
                          key={item?.value}
                          className="cursor-pointer table-hover-td-content-preview"
                          style={{
                            ...(item?.value === optionChoose?.value
                              ? { backgroundColor: "rgba(37,160,226,0.3)" }
                              : index % 2 === 0
                                ? { backgroundColor: "var(--vz-input-bg-custom)" }
                                : { backgroundColor: "var(--vz-table-bg)" }),
                            ...(index === options?.length - 1
                              ? {
                                borderBottom:
                                  "1px solid var(--vz-table-border-color)",
                              }
                              : {}),
                          }}
                          onClick={() => setOptionChoose(item)}
                        >
                          <Col xs={12} className="px-4 py-3">
                            <div
                              className="d-flex justify-content-between align-items-center form-check"
                              style={{
                                verticalAlign: "middle",
                                display: "inline-flex",
                              }}
                            >
                              <div className="d-flex justify-content-start">
                                <input
                                  className="form-check-input me-2"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id={`flexRadioDefault${index}`}
                                  checked={item?.value === optionChoose?.value}
                                  style={{ minWidth: "16px" }}
                                />
                                <label
                                  className="form-check-label position-relative"
                                  htmlFor={`flexRadioDefault${index}`}
                                >
                                  {item?.label}
                                  {item?.schedule && (<span
                                    className="position-absolute topbar-badge badge rounded-pill bg-danger"
                                    style={{ transform: "translate(80%, -80%)" }}
                                  >
                                    {SCHEDULE_OPTIONS_LANG?.filter((item: any) => (String(item?.value) === String(item?.schedule)))[0]?.label || ''}
                                  </span>)}
                                </label>
                              </div>
                              {item?.image_chart && <img src={item?.image_chart || ''} alt='chart' style={{ objectFit: 'contain', width: '60px' }} />}
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                    <Col xs={8} className=" py-2">
                      <div
                        className="px-2 w-100"
                        style={{
                          maxHeight: '80vh',
                          height: "calc(100dvh - 250px)",
                          overflowY: "scroll",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: optionChoose?.sample_content || "",
                        }}
                      ></div>
                    </Col>
                  </Row>
                )}
              </div>
              <div className="text-end mt-3">
                <button
                  className="btn btn-primary fs-14"
                  type="button"
                  onClick={onClickSetValue}
                >
                  <i className="ri-check-double-fill align-bottom me-1"></i>
                  {t("Button Choose")}
                </button>
                <button
                  className="btn btn-light fs-14 ms-4"
                  type="button"
                  onClick={onClickToggleModal}
                >
                  <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                  {t("Button Close")}
                </button>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default SearchTemplate;
