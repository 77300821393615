import { createSlice } from "@reduxjs/toolkit";
import { getTopics, getAllTopics } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ITopic } from "api/types/_topic";


export interface IState {
  topics: ResponseData<ITopic[]> & PaginationResponse | null,
  isTopicLoading: boolean,
  isTopicSuccess: boolean,

  allTopic: ResponseData<ITopic[]> | null,

  error: any,
};

export const initialState: IState = {
  topics: null,
  isTopicLoading: false,
  isTopicSuccess: false,

  allTopic: null,

  error: {},
};

const TopicSlice = createSlice({
  name: "Topic",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Topics
    builder.addCase(getTopics.pending, (state: IState, action: any) => {
      state.isTopicLoading = true
    });
    builder.addCase(getTopics.fulfilled, (state: IState, action: any) => {
      state.topics = action.payload.data;
      state.isTopicSuccess = true;
      state.isTopicLoading = false;
    });
    builder.addCase(getTopics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isTopicSuccess = false;
      state.isTopicLoading = false;
    });
  },
});

export default TopicSlice.reducer;