import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient"
import { PaginationResponse, ResponseData } from "./types/_public";
import { ICategoryExchangeRate, ICurrency } from "./types/_currency";
const api = new APIClient();

const path = '/currencies';
const currencyApi = {
  categories(params: any = {}):Promise<AxiosResponse<ResponseData<ICategoryExchangeRate[]> & PaginationResponse, any>> {
    const url = `${path}/categories`
    return api.get(url, params)
  },
  currencies(params: any):Promise<AxiosResponse<ResponseData<ICurrency[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  currencyRates(params: any):Promise<AxiosResponse<ResponseData<ICurrency[]> & PaginationResponse, any>> {
    const url = `currency-rates`
    return api.get(url, params)
  },
  allCurrencies(params: any = {}):Promise<AxiosResponse<ResponseData<ICurrency[]>, any>> {
    const url = `masters/currencies`
    return api.get(url, params)
  },
  postCurrency(dataForm: any) : Promise<AxiosResponse<ResponseData<ICurrency>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putCurrency(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ICurrency>, any>>  {
    const url = `${path}/${id}`;
    return api.post(url, dataForm)
  },
  deleteCurrency(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ICurrency>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
}
export default currencyApi;