import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import settlementApi from 'api/settlementApi';

export const getSettlements = createAsyncThunk("Settlements" , async (params: any = {}) => {
  try{
    const response = await settlementApi.settlements(params)
    return response;
  }catch (error) {
    return error;
  }
});

export const getAllSettlements =  async(params: any = {}) => {
  try{
    const response = await settlementApi.allSettlements(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const postSettlement = async (data: any = {}) => {
  try{
    const response = await settlementApi.postSettlement(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putSettlement = async (id: string | number , data: any = {}) => {
  try{
    const response = await settlementApi.putSettlement(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteSettlement = async (id: string | number , data: any = {}) => {
  try{
    const response = await settlementApi.deleteSettlement(id, data);
    return response;
  }catch (error) {
    return error;
  }
};
