import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import campaignApi from 'api/campaignApi';

export const getCampaigns = createAsyncThunk("campaigns" , async (params: any = {}) => {
  try{
    const response = await campaignApi.campaigns(params)
    return response;
  }catch (error) {
    return error;
  }
});

export const getCampaignsByNormal = async (params: any = {}) => {
  try{
    const response = await campaignApi.campaigns(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const getDetailCampaign = async (id:string | number , params: any = {}) => {
  try{
    const response = await campaignApi.campaign(id, params)
    return response;
  }catch (error) {
    return error;
  }
};

export const postCampaign = async (data: any = {}) => {
  try{
    const response = await campaignApi.postCampaign(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putCampaign = async (id: string | number ,  data: any = {}) => {
  try{
    const response = await campaignApi.putCampaign(id, data);
    return response;
  }catch (error) {
    return error;
  }
};

export const deleteCampaign = async (id: string | number , data: any = {}) => {
  try{
    const response = await campaignApi.deleteCampaign(id, data);
    return response;
  }catch (error) {
    return error;
  }
};