import { useState } from "react";
import { getLoggedInUserInfo } from "../../helpers/api_helper";

const useProfile = () => {
  const [userProfile] = useState<any>(getLoggedInUserInfo())
  const [loading] = useState<boolean>(userProfile ? false : true);

  return { userProfile, loading };
};

export { useProfile };
