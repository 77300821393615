import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'reactstrap';
//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import { useTranslation } from 'react-i18next';

const SearchOptionPages = () => {
    const { t } = useTranslation();
    const [value, setValue] = useState("");
    const onChangeData = (value: any) => {
        setValue(value);
    };

    const menuItems: any = [
        {
            label: t('Dashboards'),
            link: "/dashboard",
            icon: <i className=" ri-dashboard-line align-middle fs-18 text-muted me-2"></i>,
        },
        // {
        //     label: t('Naver Category'),
        //     link: "/keyword/naver-category",
        //     icon: <i className="ri-donut-chart-fill align-middle fs-18 text-muted me-2"></i>,
        // },
        {
            label: t('Naver Keyword'),
            link: "/keyword/naver-keyword",
            icon: <i className="ri-donut-chart-fill align-middle fs-18 text-muted me-2"></i>,
        },
        {
            label: t('Car Category'),
            link: "/keyword/car-category",
            icon: <i className="ri-car-line align-middle fs-18 text-muted me-2"></i>,
        },
        {
            label: t('Car Keyword'),
            link: "/keyword/car-keyword",
            icon: <i className="ri-car-line align-middle fs-18 text-muted me-2"></i>,
        },
        {
            label: t('Movie Category'),
            link: "/keyword/movie-category",
            icon: <i className="ri-movie-2-line align-middle fs-18 text-muted me-2"></i>,
        },
        {
            label: t('Movie Keyword'),
            link: "/keyword/movie-keyword",
            icon: <i className="ri-movie-2-line align-middle fs-18 text-muted me-2"></i>,
        },
        {
            label: t('Exchange Rate Category'),
            link: "/keyword/exchange-rate-category",
            icon: <i className="ri-exchange-funds-fill align-middle fs-18 text-muted me-2"></i>,
        },
        {
            label: t('Exchange Rate'),
            link: "/keyword/exchange-rate",
            icon: <i className="ri-exchange-funds-fill align-middle fs-18 text-muted me-2"></i>,
        },
        {
            label: t('Trend Index Category'),
            link: "/keyword/trend-index-category",
            icon: <i className="ri-blaze-line align-middle fs-18 text-muted me-2"></i>,
        },
        {
            label: t('Trend Index'),
            link: "/keyword/trend-index",
            icon: <i className="ri-blaze-line align-middle fs-18 text-muted me-2"></i>,
        },
        {
            label: t('Media'),
            link: "/media",
            icon: <i className="ri-building-2-line align-middle fs-18 text-muted me-2"></i>,
        },
        {
            label: t('Settlement'),
            link: "/settlement",
            icon: <i className="ri-donut-chart-fill align-middle fs-18 text-muted me-2"></i>,
        },
        {
            label: t('Create Campaign'),
            link: "/campaign/create",
            icon: <i className="ri-bookmark-line align-middle fs-18 text-muted me-2"></i>
        },
        {
            label: t('Campaign'),
            link: "/campaign/list",
            icon: <i className="ri-bookmark-line align-middle fs-18 text-muted me-2"></i>
        },
        {
            label: t('News'),
            link: "/campaign/news",
            icon: <i className=" ri-newspaper-line align-middle fs-18 text-muted me-2"></i>
        },
        {
            label: t('Template News Title'),
            link: "/template/news-title",
            icon: <i className="ri-layout-left-2-line align-middle fs-18 text-muted me-2"></i>
        },
        {
            label: t('Template News Content'),
            link: "/template/news-content",
            icon: <i className="ri-layout-right-2-line align-middle fs-18 text-muted me-2"></i>
        },
        {
            label: t('Info-Graphic'),
            link: "/template/info-graphic",
            icon: <i className="ri-pie-chart-line align-middle fs-18 text-muted me-2"></i>
        },
        {
            label: t('Variable Definition'),
            link: "/template/variable-definition",
            icon: <i className="ri-at-line align-middle fs-18 text-muted me-2"></i>
        },
        {
            label: t('News Log'),
            link: "/statistics/news-log",
            icon: <i className="ri-at-line align-middle fs-18 text-muted me-2"></i>
        },
        {
            label: t('News Statistics'),
            link: "/statistics/news-statistics",
            icon: <i className="ri-at-line align-middle fs-18 text-muted me-2"></i>
        },
        {
            label: t('User'),
            link: "/user/list",
            icon: <i className="ri-group-line align-middle fs-18 text-muted me-2"></i>
        },
        {
            label: t('Role'),
            link: "/user/role",
            icon: <i className="ri-user-star-line align-middle fs-18 text-muted me-2"></i>
        },
    ];

    useEffect(() => {
        var searchOptions: any = document.getElementById("search-close-options");
        var dropdown: any = document.getElementById("search-dropdown");
        var searchInput: any = document.getElementById("search-options");

        searchInput.addEventListener("focus", function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown.classList.add("show");
                searchOptions.classList.remove("d-none");
            } else {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });

        searchInput.addEventListener("keyup", function () {
            var inputLength = searchInput.value.length;
            if (inputLength > 0) {
                dropdown.classList.add("show");
                searchOptions.classList.remove("d-none");
            } else {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });

        searchOptions.addEventListener("click", function () {
            searchInput.value = "";
            dropdown.classList.remove("show");
            searchOptions.classList.add("d-none");
        });

        document.body.addEventListener("click", function (e: any) {
            if (e.target.getAttribute('id') !== "search-options") {
                dropdown.classList.remove("show");
                searchOptions.classList.add("d-none");
            }
        });
    }, []);

    const checkIsIncludeText = (text: string, search: string) => {
        return String(text).toUpperCase().includes(String(search).toUpperCase());
    };

    return (
        <React.Fragment>
            <form className="app-search d-none d-sm-block">
                <div className="position-relative">
                    <Input type="text" className="form-control" placeholder={`${t('Button Search')}...`}
                        id="search-options"
                        autoComplete="off"
                        value={value}
                        onChange={e => {
                            onChangeData(e.target.value);
                        }} />
                    <span className="mdi mdi-magnify search-widget-icon"></span>
                    <span className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                        id="search-close-options"></span>
                </div>
                <div className="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                    <SimpleBar style={{ height: "320px" }}>

                        <div className="dropdown-header mt-2">
                            <h6 className="text-overflow text-muted mb-1 text-uppercase">{t('Pages')}</h6>
                        </div>

                        {menuItems?.map((item: any, index: number) => (
                            <Link key={index} to={item?.link} className={`dropdown-item notify-item ${checkIsIncludeText(item?.label || '', value || '') ? '' : 'd-none'}`}>
                                {item?.icon}
                                <span className="">{item?.label}</span>
                            </Link>
                        ))}
                    </SimpleBar>
                </div>
            </form>
        </React.Fragment>
    );
};

export default SearchOptionPages;