import { forwardRef, useState, useEffect } from 'react';
import './TypingEffect.scss'
import React from 'react';
const TypingEffect = forwardRef(({ id = '', content = '', className = '', handleEnd }: { id: string, content: string, className?: string, handleEnd?: (id: string) => void }, ref: any) => {

  const [completedTyping, setCompletedTyping] = useState<boolean>(false);
  const [displayResponse, setDisplayResponse] = useState<string>('');

  useEffect(() => {

    setCompletedTyping(false);

    let i = 0;

    const stringResponse = content;

    const intervalId = setInterval(() => {
      setDisplayResponse(stringResponse.slice(0, i));
      i++;
      if (ref) {
        ref.current.scrollIntoView({ behavior: "smooth" });
        ref.current.scrollTop = ref.current.scrollHeight;
      }
      if (i > stringResponse.length) {
        clearInterval(intervalId);
        setCompletedTyping(true);
        (handleEnd && String(content).trim() !== '') && handleEnd(id);
      }
    }, 10);

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, id]);

  return (
    <div className={className} style={{whiteSpace: 'pre-line'}}>
      {displayResponse}
      {(displayResponse || !completedTyping || String(content).trim() === '') && (
        <svg
          viewBox="8 4 8 16"
          xmlns="http://www.w3.org/2000/svg"
          className="typing-cursor"
        >
          <rect x="10" y="6" width="4" height="12" fill="var(--vz-heading-color)" />
        </svg>)}
    </div>
  );
});

export default React.memo(TypingEffect);