import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient"
import { PaginationResponse, ResponseData } from "./types/_public";
import { ICampaign } from "./types/_campaign";
const api = new APIClient();

const path = '/campaigns';
const campaignApi = {
  campaigns(params: any):Promise<AxiosResponse<ResponseData<ICampaign[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  campaign(id:number | string, params: any = {}):Promise<AxiosResponse<ICampaign, any>>{
    const url = `${path}/${id}`
    return api.get(url, params)
  },
  postCampaign(dataForm: any) : Promise<AxiosResponse<ResponseData<ICampaign>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putCampaign(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ICampaign>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteCampaign(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ICampaign>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
}
export default campaignApi
