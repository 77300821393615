import { ICurrency } from 'api/types/_currency';
import { Option } from 'api/types/_public';
import BreadCrumb from 'components/Common/BreadCrumb';
import FilterCurrencies from 'components/Common/FilterCurruncies';
import TableContainer from 'components/Common/TableContainer';
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import { getAllCurrencies, getExchangeRates as onGetExchangeRates } from "../../../store/thunks";

const typeQuery = {
  'cur_unit': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'version_id';
registerLocale('en', en);
registerLocale('ko', ko);

const TODAY_DATE_DEFAULT = `${moment().format("Y-MM-DD")}`;

const ExchangeRateKeyword = () => {
  const { t, i18n } = useTranslation();

  const [query, setQuery]: any = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    date_version: withDefault(StringParam, TODAY_DATE_DEFAULT),
    ...typeQuery
  });

  const rangeValue = useRef<any>();
  const [dateSearch, setDateSearch] = useState<string>(query?.date_version || TODAY_DATE_DEFAULT);

  const [currencySearch, setCurrencySearch] = useState<Option | null>(null);
  const [listCurrencies, setListCurrencies] = useState<ICurrency[]>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.ExchangeRate;

  const ExchangeRateProperties = createSelector(
    selectLayoutState,
    (state) => ({
      exchangeRates: state.exchangeRates,
      isExchangeRatesSuccess: state.isExchangeRatesSuccess,
      isExchangeRatesLoading: state.isExchangeRatesLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { exchangeRates, isExchangeRatesLoading } = useSelector(ExchangeRateProperties);

  useEffect(() => {
    dispatch(onGetExchangeRates(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  const searchData = () => {
    setQuery({
      ...query,
      cur_unit: currencySearch?.value ?? undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      date_version: dateSearch,
    });
  };

  const resetData = () => {
    setQuery({
      title: undefined,
      sort_by: undefined,
      cur_unit: undefined,
    }, "push")
    setCurrencySearch(null);
    setDateSearch(TODAY_DATE_DEFAULT);
  };


  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('version_date'),
        accessor: "version_date",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <div style={{ width: '90px' }}>{cell?.value}</div>
            </>
          )
        },
      },
      {
        Header: t('Country'),
        accessor: "currency",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value?.country}</span>
          </>
        ),
      },
      {
        Header: t('Currency Name'),
        accessor: "cur_nm",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const currency = value?.currency;
          return (<>
            <span>{currency?.name}</span>
          </>);
        },
      },
      {
        Header: t('cur_unit'),
        accessor: "cur_unit",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('deal_bas_r'),
        accessor: "deal_bas_r",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('bkpr'),
        accessor: "bkpr",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('kftc_bkpr'),
        accessor: "kftc_bkpr",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('ttb'),
        accessor: "ttb",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('tts'),
        accessor: "tts",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('ten_dd_efee_r'),
        accessor: "ten_dd_efee_r",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('yy_efee_r'),
        accessor: "yy_efee_r",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM-DD") : '');
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleCallAllOption = async () => {
    try {
      const [resCurrencies]: any = await Promise.all([getAllCurrencies()]);
      setListCurrencies((_prev) => resCurrencies?.data || []);
    } catch (error: any) {
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    document.title = `${t('Exchange Rate')} - ${t('Keyword')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Exchange Rate')} pageTitle={t('Keyword')} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center mb-2 mt-2">
                    <Col sm={12} lg={3} className="date-picker-wrapper-custom mt-2">
                      <DatePicker
                        className="form-control search"
                        placeholderText={`${t('version_date')}...`}
                        value={dateSearch || undefined}
                        dateFormat="yyyy-MM-dd"
                        isClearable={true}
                        maxDate={new Date()}
                        locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                        onChange={handleChangePicker}
                      />
                    </Col>
                    <Col sm={12} lg={3} className='mt-2'>
                      <div>
                        <FilterCurrencies dataList={listCurrencies} initialValue={currencySearch || []} onChangeSelect={(event: any) => {
                          setCurrencySearch((_prev) => event);
                        }} />
                      </div>
                    </Col>
                    <Col sm={12} lg={3} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2">
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary me-1"
                          onClick={searchData}
                          disabled={isExchangeRatesLoading}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t('Button Search')}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t('Button Reset')}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={exchangeRates?.list?.length ? exchangeRates?.list : []}
                      customPageSize={query.limit}
                      customPageCount={Math.ceil(Number(exchangeRates?.pagination?.total) / Number(exchangeRates?.pagination?.limit))}
                      customPageIndex={query.page - 1}
                      totalRecords={exchangeRates?.pagination?.total}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isExchangeRatesLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ExchangeRateKeyword;
