import { createAsyncThunk } from "@reduxjs/toolkit";
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import naverApi from 'api/naverApi';

export const getCategories = createAsyncThunk("category/getCategories" , async (params: any = {}) => {
  try{
    const response = await naverApi.categories(params)
    return response;
  }catch (error) {
    return error;
  }
})

export const getAllCategories = createAsyncThunk("category/getAllCategories" , async (params: any = {}) => {
  try{
    const response = await naverApi.searchCategories(params)
    return response;
  }catch (error) {
    return error;
  }
})

export const getKeywords = createAsyncThunk("category/keywords" , async (params: any = {}) => {
  try{
    const response = await naverApi.keywords(params)
    return response;
  }catch (error) {
    return error;
  }
})


export const getKeywordsByNormal = async (params: any = {}) => {
  try{
    const response = await naverApi.keywords(params)
    return response;
  }catch (error) {
    return error;
  }
};

export const getAllCategoriesNaverNormal = async (params: any = {}) => {
  try{
    const response = await naverApi.searchCategories(params)
    return response;
  }catch (error) {
    return error;
  }
}
