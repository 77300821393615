import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient"
import { PaginationResponse, ResponseData } from "./types/_public";
import { IChart, IParameter, ITemplate } from "./types/_template";
const api = new APIClient();

const path = '/templates';
const pathVariable = '/template-variables';
const pathChart = 'template-charts';
const templateApi = {
  templates(params: any):Promise<AxiosResponse<ResponseData<ITemplate[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
  allTemplates(params: any = {}):Promise<AxiosResponse<ResponseData<ITemplate[]>, any>>  {
    const url = `masters/templates`
    return api.get(url, params)
  },
  postTemplate(dataForm: any) : Promise<AxiosResponse<ResponseData<ITemplate>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },
  putTemplate(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ITemplate>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },
  deleteTemplate(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<ITemplate>, any>>  {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm)
  },
  parameters(params: any):Promise<AxiosResponse<ResponseData<IParameter[]> & PaginationResponse, any>> {
    const url = `${pathVariable}`
    return api.get(url, params)
  },
  allParameters(params: any):Promise<AxiosResponse<ResponseData<IParameter[]> & PaginationResponse, any>> {
    const url = `masters/parameters`
    return api.get(url, params)
  },
  postParameter(dataForm: any) : Promise<AxiosResponse<ResponseData<IParameter>, any>>  {
    const url = `${pathVariable}`;
    return api.post(url, dataForm)
  },
  putParameter(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IParameter>, any>>  {
    const url = `${pathVariable}/${id}`;
    return api.put(url, dataForm)
  },
  deleteParameter(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IParameter>, any>>  {
    const url = `${pathVariable}/${id}`;
    return api.delete(url, dataForm)
  },
  allCharts(params: any):Promise<AxiosResponse<ResponseData<IChart[]> & PaginationResponse, any>> {
    const url = `masters/template-charts`
    return api.get(url, params)
  },
  postChart(dataForm: any) : Promise<AxiosResponse<ResponseData<IChart>, any>>  {
    const url = `${pathChart}`;
    return api.post(url, dataForm)
  },
  putChart(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IChart>, any>>  {
    const url = `${pathChart}/${id}`;
    return api.put(url, dataForm)
  },
  deleteChart(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IChart>, any>>  {
    const url = `${pathChart}/${id}`;
    return api.delete(url, dataForm)
  },
}
export default templateApi
