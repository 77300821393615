import React from "react";
import { IParameter } from "api/types/_template";
import { CONFIG_OPTION_TOAST_COPY, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface SearchParameterProps {
  data?: IParameter[];
  style?: any
}

const SearchParameter = ({
  data = [],
  style = {},
}: SearchParameterProps) => {
  const { t } = useTranslation();

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', true, text);
    }
    toast(`${t('Copied Successfully')}`, CONFIG_OPTION_TOAST_COPY);
  };

  return (
    <React.Fragment>
      <div className="table-responsive w-100 rounded-3" style={{ maxHeight: 'calc(100dvh - 150px)', ...style }}>
        <table className="table table-borderless table-light table-hover table-nowrap rounded-3 align-middle mb-0">
          <thead className="table-light" style={{ position: 'sticky', top: 0 }}>
            <tr className="text-muted">
              <th scope="col">{t('Parameter Key')}</th>
              <th scope="col" style={{ width: "20%" }}>{t('Parameter Example')}</th>
              <th scope="col" >{t('Parameter Description')}</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item: IParameter) => (
              !!(item?.is_active) && (<tr key={item?.scope}>
                <td>
                  <span className="cursor-pointer" style={{ verticalAlign: 'middle', display: 'inline-flex', }} onClick={() => copyTextToClipboard(item?.key)}>
                    {item?.key}
                    <i className=" ri-file-copy-line text-primary ms-1" style={{ fontSize: '1rem' }}></i>
                  </span>
                </td>
                <td>{item?.sample}</td>
                <td>{item?.description}</td>
              </tr>)
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment >
  );
};

export default SearchParameter;
