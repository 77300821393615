import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getUsers as onGetUsers } from "../../../store/thunks";
import UserForm from './UserForm';
import { IUser } from 'api/types/_user';


interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = 'id';

registerLocale('en', en);
registerLocale('ko', ko);


const UserList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [query, setQuery]: any = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    email: withDefault(StringParam, ''),
  });
  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");

  const [mediasSearch, setMediasSearch] = useState<Option[]>([]);

  const [userEdit, setUserEdit] = useState<IUser & { isCopy?: boolean } | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.User;

  const UserProperties = createSelector(
    selectLayoutState,
    (state) => ({
      users: state.users,
      isUserSuccess: state.isUserSuccess,
      isUserLoading: state.isUserLoading,
      error: state.error,
    })
  );

  // Inside your component
  const { users, error, isUserLoading } = useSelector(UserProperties);

  useEffect(() => {
    dispatch(onGetUsers(query));
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      email: keywordSearch || undefined,
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
    });
  };

  const resetData = () => {
    setQuery({
      email: undefined,
      sort_by: TYPE_SELECT_DEFAULT,
    }, "push")
    setKeywordSearch((_prev) => "");
    setMediasSearch([]);
  };


  // Begin::Update User
  const onCloseClick = () => {
    setUserEdit((_prev) => null);
    setIsOpen((_prev) => false)
  }

  const triggerRefresh = () => {
    onCloseClick();
    dispatch(onGetUsers(query));
  }
  //End::Update User


  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: "id",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Name'),
        accessor: "first_name",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div>
              {item?.first_name || ''} {item?.last_name || ''}
            </div>
          </>)
        },
      },
      {
        Header: t('Username'),
        accessor: "username",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('Email'),
        accessor: "email",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div>{cell?.value}</div>
          </>)
        },
      },
      {
        Header: t('Role'),
        accessor: "role",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (<>
            <div>{item?.role?.name || ''}</div>
          </>)
        },
      },
      {
        Header: t('Active'),
        accessor: "is_active",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const active = value?.is_active;
          return (
            <div className="form-check form-switch form-switch-sm" dir="ltr">
              <input type="checkbox" className="form-check-input" name="is_active" id="customSwitchsizelg" checked={Boolean(active)}
              // onChange={(event: any) => handleChangeStatusItem(value, event.target.checked)}
              />
            </div>
          );
        }
      },
      {
        Header: t('Created at'),
        accessor: "created_at",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Updated at'),
        accessor: "updated_at",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value}</span>
          </>
        ),
      },
      {
        Header: t('Button Action'),
        thClass: 'text-center',
        thWidth: 90,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline flex-wrap justify-content-center hstack gap-2 mb-0">
              <li className=" list-inline-item me-0" title={t('Button Update User')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); setUserEdit((prev) => item) }}
                >
                  <i className="ri-pencil-fill align-bottom"></i>
                </Link>
              </li>
              {/* <li className="list-inline-item" title={t('Button Update User')}>
                <Link className="btn btn-sm btn-soft-secondary edit-item-btn" to="#"
                  onClick={(e) => { e.preventDefault(); setUserEdit((prev) => item) }}
                >
                  <i className="ri-file-copy-fill align-bottom"></i>
                </Link>
              </li> */}
            </ul>
          );
        },
      },
    ],
    [i18n?.language, query]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);


  useEffect(() => {
    document.title = `${t('User')} - ${t('User')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('User')} pageTitle={t('User')} />
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center mb-2 mt-2">
                    <Col sm={12} lg={3} className='mt-2'>
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={`${t('Email')}...`}
                        value={keywordSearch}
                        onChange={(e) => setKeywordSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            searchData();
                          }
                        }}
                      />
                    </Col>
                    <Col sm={12} lg={9} className="hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2">
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary me-1"
                          onClick={searchData}
                          disabled={isUserLoading}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t('Button Search')}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t('Button Reset')}
                        </button>
                      </div>
                      <button
                        type="button"
                        className="btn btn-success me-1"
                        onClick={() => setIsOpen((_prev) => true)}
                      >
                        <i className="ri-add-fill align-bottom me-1"></i>{" "}
                        {t("Button Create User")}
                      </button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={users?.list?.length ? users?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={users?.pagination?.total}
                      customPageCount={Math.ceil(Number(users?.pagination?.total) / Number(users?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isUserLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={!!userEdit?.id || isOpen}
          centered={true}
          size="xl"
          scrollable={true}
        >
          <ModalHeader toggle={onCloseClick}>
            <>{!!(userEdit?.id) ? t("Button Update User") : t("Button Create User")}</>  <>{(userEdit?.id) && (' - ' + (userEdit?.first_name || '') + (userEdit?.last_name || '') || '')}</>
          </ModalHeader>
          <ModalBody>
            <div style={{ minHeight: "calc(100dvh - 200px)" }}>
              <UserForm
                isModal={true}
                id={String(userEdit?.id || '')}
                isCopy={!!(userEdit?.isCopy)}
                triggerRefresh={triggerRefresh}
              />
            </div>
          </ModalBody>
        </Modal>
      </div >
    </React.Fragment >
  );
};

export default UserList;