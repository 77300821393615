import { IBrand, IModel } from "api/types/_car";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import en from 'date-fns/locale/en-US';
import ko from 'date-fns/locale/ko';
import { useFormik } from 'formik';
import { typeSearchCar, } from "helpers/constans";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody, CardHeader, Col, Container, Input, Modal, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import { createSelector } from "reselect";
import { NumberParam, StringParam, useQueryParams, withDefault, } from "use-query-params";
import * as Yup from 'yup';
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { importCarsByNormal, getAllBrands as onGetAllBrands, getAllModels as onGetAllModels, getCars as onGetCars } from "../../../store/thunks";
interface Option {
  label: string;
  value: string;
}

const typeQuery = {
  'total_sales': StringParam,
  'manufacture': StringParam,
  'used': StringParam,
  'export': StringParam,
  'name': StringParam,
}

const TYPE_SELECT_DEFAULT: string = 'total_sales';
const OPTION_NULL: Option | null = null;
registerLocale('en', en);
registerLocale('ko', ko);

const CarKeyword = () => {
  const { t, i18n } = useTranslation();

  const [query, setQuery]: any = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    date_version: withDefault(StringParam, ''),
    brand_id: NumberParam,
    series_id: NumberParam,
    ...typeQuery
  });

  const typeSearchLang = typeSearchCar?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));
  const [typeSelect, setTypeSelect] = useState(typeSearchLang?.find((e: any) => e.value === query.sort_by));

  const rangeValue = useRef<any>();
  const [dateSearch, setDateSearch] = useState<string>(query?.date || "");
  const [brandSelect, setBrandSelect] = useState<Option | null>(OPTION_NULL);
  const [modelSelect, setModelSelect] = useState<Option | null>(OPTION_NULL);
  const [fromRangeSearch, setFromRangeSearch] = useState<string>("");
  const [toRangeSearch, setToRangeSearch] = useState<string>("");

  const [show, setShow] = useState(false);

  const validationSchema = Yup.object({
    file: Yup.mixed().required(`${t('Please select a file')}`).test('fileFormat', `${t('Unsupported file format')}`, (value: any) => {
      if (value) {
        return (
          value.type === 'application/vnd.ms-excel' ||
          value.type === 'text/csv' ||
          value.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
      }
      return false;
    }),
    date_version: Yup.string().required(`${t('Date version is required')}`),
  });

  const [isImportCarLoading, setIsImportCarLoading] = useState<boolean>(false);

  const handleSubmit = async (values: any) => {
    try {
      setIsImportCarLoading((_prev) => true);
      const response: any = await importCarsByNormal(values);
      if (response?.data) {
        toast(`${t('Import data process successful')}...`, CONFIG_OPTION_TOAST_NORMAL);
        setShow((_prev) => false);
        resetData();
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }

      setIsImportCarLoading((_prev) => false);

    } catch (error) {
      setIsImportCarLoading((_prev) => false);
      return error;
    }
  };


  const formik = useFormik({
    initialValues: {
      file: null,
      date_version: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  function onCloseClick() {
    setShow((_prev) => !_prev);
    // formik.setFieldValue('file', null);
    // formik.setFieldValue('date_version', '');
    formik.setErrors({});
  }

  const dispatch: any = useDispatch();



  const selectLayoutState = (state: any) => {
    return state.Car;
  };

  const carProperties = createSelector(
    selectLayoutState,
    (state) => ({
      cars: state.cars,
      allModels: state.allModels,
      allBrands: state.allBrands,
      isCarSuccess: state.isCarSuccess,
      isCarLoading: state.isCarLoading,
      error: state.error,
    })
  );


  // Inside your component
  const { cars, allBrands, allModels, error, isCarLoading } = useSelector(carProperties);

  const brandOptions: Option[] = useMemo(() => {
    const list = allBrands?.list?.map((item: IBrand) => ({ value: String(item?.id), label: item?.name }));
    return list;
  }, [allBrands]);

  const modelOptions: Option[] = useMemo(() => {
    const list = allModels?.list?.reduce((arr: Option[], item: IModel) => {
      if (brandSelect?.value === String(item?.brand_id)) {
        return [...arr, { value: String(item?.id), label: item?.name }];
      } else {
        return arr;
      }
    }, []);
    const result = list;
    setModelSelect((_prev) => result?.[0] ?? null);
    return result;
  }, [allModels, brandSelect?.value]);

  const searchData = () => {
    const rangeQuery = (fromRangeSearch && toRangeSearch) ? `gt:${fromRangeSearch}-lt:${toRangeSearch}` : undefined;
    setQuery({
      ...query,
      [typeSelect?.value]: rangeQuery,
      sort_by: typeSelect?.value || TYPE_SELECT_DEFAULT,
      page: 1,
      brand_id: brandSelect?.value,
      series_id: modelSelect?.value,
      date_version: dateSearch,
    });
  };

  const resetData = () => {
    setQuery({
      name: undefined,
      sort_by: undefined,
      [typeSelect?.value]: undefined,
      category_id: undefined,
    }, "push");
    setTypeSelect(typeSearchLang?.find((e: any) => e.value === TYPE_SELECT_DEFAULT));
    setFromRangeSearch("");
    setToRangeSearch("");
    setDateSearch("");
    setBrandSelect(OPTION_NULL);
    setModelSelect(OPTION_NULL);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('Date Car'),
        accessor: "date_version",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Naver Search (Monthly)'),
        accessor: "naver_search_monthly",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Total sales'),
        accessor: "total_sales",
        filterable: true,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Brand'),
        accessor: "brand_id",
        filterable: false,
        Cell: (cell: any) => (
          <>
            <span>{cell?.row?.original?.brand?.name ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Eng Model'),
        accessor: "name",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <span>{cell?.value ?? "-"}</span>
            </>
          )
        },
      },
      {
        Header: t('Kor Model1'),
        accessor: "name_korea_1",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <span>{cell?.value ?? "-"}</span>
            </>
          )
        },
      },
      {
        Header: t('Kor Model2'),
        accessor: "name_korea_2",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <span>{cell?.value ?? "-"}</span>
            </>
          )
        },
      },
      {
        Header: t('Local'),
        accessor: "used",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Export'),
        accessor: "export",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value ?? "-"}</span>
          </>
        ),
      },
      {
        Header: t('Manufacture'),
        accessor: "manufacture",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => (
          <>
            <span>{cell?.value ?? "-"}</span>
          </>
        ),
      },
    ],
    [i18n?.language]
  );


  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 })
  }, []);

  const handleChangePicker = (value: Date | null) => {
    setDateSearch((_prev) => value ? moment(new Date(value)).format("Y-MM") : '');
  }

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return ({ ..._prev, ...sortBy });
    });
  }, []);

  useEffect(() => {
    dispatch(onGetAllModels({}));
    dispatch(onGetAllBrands({}));
  }, []);

  useEffect(() => {
    dispatch(onGetCars(
      query.sort_by && rangeValue.current ?
        { ...query, [query.sort_by]: rangeValue.current?.value } : query
    ));
  }, [dispatch, query]);

  useEffect(() => {
    if (typeSelect?.value) {
      setTypeSelect((_prev: any) =>
        typeSearchLang?.find((e: any) => e.value === typeSelect?.value)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t('Keyword')} - ${t('Car Keyword')} | NewsHub`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Car Keyword')} pageTitle={t('Keyword')} />
          {/* <Row>
            <Col lg={12} className="text-end mb-3">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => setShow(true)}
              >
                <i className="ri-upload-2-line align-bottom me-1"></i>{" "}
                {t('Button Import')}
              </button>
            </Col>
          </Row> */}
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row>
                    <Col lg={12} className="text-end  mb-2 mb-md-0 d-block d-md-none">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => setShow(true)}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        {t('Button Import')}
                      </button>
                    </Col>
                  </Row>
                  <Row className="g-4 align-items-center mb-2">
                    <Col sm={4} lg={4} className="date-picker-wrapper-custom">
                      <DatePicker
                        className="form-control search"
                        placeholderText={`${t('Date Car')}...`}
                        value={dateSearch || ''}
                        showMonthYearPicker
                        showFullMonthYearPicker
                        showFourColumnMonthYearPicker
                        dateFormat="yyyy-MM"
                        isClearable={true}
                        locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                        onChange={handleChangePicker}
                      />
                    </Col>
                    <Col sm={4} lg={2}>
                      <Select
                        options={brandOptions || []}
                        value={brandSelect}
                        onChange={(e: any) => setBrandSelect(e)}
                        placeholder={<div>{`${t('Brand')}...`}</div>}
                        loadingMessage={() => (<div>{t('Loading')}...</div>)}
                        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
                      />
                      {/* <SearchOption /> */}
                    </Col>
                    <Col sm={4} lg={2}>
                      <Select
                        options={modelOptions || []}
                        value={modelSelect}
                        onChange={(e: any) => setModelSelect(e)}
                        placeholder={<div>{`${t('Model')}...`}</div>}
                        loadingMessage={() => (<div>{t('Loading')}...</div>)}
                        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
                      />
                      {/* <SearchOption /> */}
                    </Col>
                  </Row>
                  <Row className="g-4 align-items-center">
                    {/* type search */}
                    <Col sm={4} lg={4}>
                      <Select
                        options={typeSearchLang}
                        value={typeSelect}
                        onChange={(e: any) => setTypeSelect(e)}
                        placeholder={<div>{`${t('Search by field')}...`}</div>}
                        loadingMessage={() => (<div>{t('Loading')}...</div>)}
                        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
                      />
                    </Col>
                    <Col sm={4} lg={2}>
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={`${t('From Range')}...`}
                        value={fromRangeSearch}
                        onChange={(e) => setFromRangeSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            searchData();
                          }
                        }}
                      />
                    </Col>
                    <Col sm={4} lg={2}>
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={`${t('To Range')}...`}
                        value={toRangeSearch}
                        onChange={(e) => setToRangeSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            searchData();
                          }
                        }}
                      />
                    </Col>
                    <Col sm={12} lg={4} className="hstack gap-1 justify-content-sm-center justify-content-md-between">
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary me-1"
                          onClick={searchData}
                          disabled={isCarLoading}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t('Button Search')}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t('Button Reset')}
                        </button>
                      </div>
                      <button
                        type="button"
                        className="btn btn-success d-none d-md-block"
                        onClick={() => setShow(true)}
                      >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        {t('Button Import')}
                      </button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={cars?.list?.length ? cars?.list : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={cars?.pagination?.total}
                      customPageCount={Math.ceil(Number(cars?.pagination?.total) / Number(cars?.pagination?.limit))}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{ sort_by: query.sort_by, order_by: query.order_by }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isCarLoading}
                    />
                  </div>

                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={show} centered={true}>
          <ModalHeader toggle={isImportCarLoading ? () => { } : onCloseClick}></ModalHeader>
          <ModalBody className="py-3 px-5">
            <form onSubmit={formik.handleSubmit}>
              <div className="row g-3">
                <Col xxl={12}>
                  <div>
                    <label htmlFor="file" className="form-label">{t('File')}<span className="text-danger"> *</span></label>
                    <Input
                      type="file"
                      id="file"
                      name="file"
                      accept=".csv, .excel, .sheet, .xlsx"
                      style={{ display: 'none' }}
                      placeholder={`${t('File')}...`}
                      onChange={(event: any) => {
                        formik.setFieldValue('file', event?.currentTarget?.files[0]);
                      }}
                    />
                    <div className="form-icon right">
                      <label
                        className="form-control form-control-icon"
                        htmlFor="file"
                        id="file-name"
                        style={{ color: '#8d8f9d' }}
                      >
                        {
                          //@ts-ignore
                          formik?.values?.file?.name || `${t('Choose File')}...`
                        }
                        <i className=" ri-attachment-line"></i>
                      </label>
                    </div>
                    {formik.touched.file && formik.errors.file ? (
                      <div className="text-danger mt-2">{formik.errors.file}</div>
                    ) : null}
                  </div>
                </Col>
                <Col xxl={12}>
                  <div>
                    <label htmlFor="date_version" className="form-label">{t('Date Version')}<span className="text-danger"> *</span></label>
                    <div className="date-picker-wrapper-custom">
                      <DatePicker
                        className="form-control search"
                        placeholderText={`${t('Date Version')}...`}
                        showMonthYearPicker
                        showFullMonthYearPicker
                        showFourColumnMonthYearPicker
                        dateFormat="yyyy-MM"
                        isClearable={true}
                        value={formik.values.date_version}
                        locale={i18n?.language === 'ko' ? 'ko' : 'en'}
                        onChange={(value: Date | null) => formik.setFieldValue('date_version', value ? moment(new Date(value)).format("Y-MM") : '')}
                      />
                    </div>

                    {formik.touched.date_version && formik.errors.date_version ? (
                      <div className="text-danger mt-2">{formik.errors.date_version}</div>
                    ) : null}
                  </div>
                </Col>
                <div className="col-lg-12">
                  <div className="hstack gap-2 justify-content-end">
                    <button className="btn btn-secondary fs-14" color="light" onClick={onCloseClick} disabled={isImportCarLoading}>
                      <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                      {t('Button Close')}
                    </button>
                    <button className="btn btn-primary fs-14" color="success" type="submit" disabled={isImportCarLoading}>
                      {isImportCarLoading ? <Spinner size="sm" className='me-2'></Spinner> : <i className="ri-file-download-line align-bottom me-1"></i>}
                      {t('Button Import')}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default CarKeyword;