import { Option } from "api/types/_public";
import { ICategoryTrendIndex } from "api/types/_trendIndex";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import BreadCrumb from "components/Common/BreadCrumb";
import DropdownCategoryKeyword from "components/Common/DropdownCategoryKeyword";
import ModalConfirm from "components/Common/ModalConfirm";
import TableContainer from "components/Common/TableContainer";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import { STATUS_KEYWORD_OPTIONS, typeSearchTrendIndex } from "helpers/constans";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import * as Yup from "yup";
import {
  deleteTrendIndex,
  getAllCategoriesTrendIndexNormal,
  getTrendIndexes as onGetTrendIndexes,
  postCategoryKeywords,
} from "../../../store/thunks";

const typeQuery = {
  index_trend: StringParam,
};

const TYPE_SELECT_DEFAULT: string = "index_trend";
registerLocale("en", en);
registerLocale("ko", ko);

function removeParentheses(text: string = "") {
  // Use regular expressions to remove content within ()
  return text.replace(/\([^)]*\)/g, "").trim();
}

function decreaseQuantity(input: string) {
  // Use regex to find and replace the numbers decreased by 1
  return input.replace(/\((\d+)\)/g, (match, p1) => {
    const originalQuantity = Number(p1);
    const newQuantity = originalQuantity - 1;
    return `(${newQuantity})`;
  });
}

interface Props {
  detailCategory?: any | undefined;
  handleRefreshListCategory?: (itemLoading: any) => void;
}

const TrendIndexKeyword = ({
  detailCategory,
  handleRefreshListCategory,
}: Props) => {
  const { t, i18n } = useTranslation();

  const STATUS_KEYWORD_OPTIONS_LANG = STATUS_KEYWORD_OPTIONS?.map((item: any) => ({ value: item?.value, label: t(item?.label) }));

  const [query, setQuery]: any = useQueryParams({
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    order_by: withDefault(StringParam, `DESC`),
    category_id: withDefault(
      NumberParam,
      detailCategory?.value ? Number(detailCategory?.value) : null
    ),
    competition_level: StringParam,
    keyword: StringParam,
    status: StringParam,
    ...typeQuery,
  });

  const typeSearchLang = typeSearchTrendIndex?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));
  const [keywordSearch, setKeywordSearch] = useState<string>(
    query?.keyword || ""
  );
  const [typeSelect, setTypeSelect] = useState(
    typeSearchLang?.find((e: any) => e.value === query.sort_by)
  );
  const rangeValue = useRef<any>();
  const [fromRangeSearch, setFromRangeSearch] = useState<string>("");
  const [toRangeSearch, setToRangeSearch] = useState<string>("");
  const [statusSearch, setStatusSearch] = useState<Option | null>(null);
  const [categorySelect, setCategorySelect] = useState<Option | null>(
    detailCategory || null
  );

  const [listCategoriesTrendIndex, setListCategoriesTrendIndex] = useState<
    ICategoryTrendIndex[]
  >([]);

  const [isOpenConfirm, setIsConfirm] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [initialValuesDefault, setInitialValuesDefault] =
    useState<ICategoryTrendIndex | null>(null);

  const [isUpdateKeywordsLoading, setIsUpdateKeywordsLoading] =
    useState<boolean>(false);

  const [isKeyLoading, setIsKeyLoading] = useState<number | null>(null);

  const [isFullRowEnterKeywords, setIsFullRowEnterKeywords] =
    useState<boolean>(false);

  const textareaKeyWordsRef = useRef<any>();

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.TrendIndex;

  const TrendIndexProperties = createSelector(selectLayoutState, (state) => ({
    trendIndexes: state.trendIndexes,
    isTrendIndexesSuccess: state.isTrendIndexesSuccess,
    isTrendIndexesLoading: state.isTrendIndexesLoading,
    error: state.error,
  }));

  // Inside your component
  const { trendIndexes, isTrendIndexesLoading } =
    useSelector(TrendIndexProperties);

  useEffect(() => {
    dispatch(
      onGetTrendIndexes(
        query.sort_by && rangeValue.current
          ? { ...query, [query.sort_by]: rangeValue.current?.value }
          : query
      )
    );
  }, [dispatch, query]);

  const searchData = () => {
    const rangeQuery =
      fromRangeSearch && toRangeSearch
        ? `gt:${fromRangeSearch}-lt:${toRangeSearch}`
        : undefined;
    setQuery({
      ...query,
      [typeSelect?.value]: rangeQuery,
      keyword: keywordSearch || undefined,
      sort_by: typeSelect?.value || TYPE_SELECT_DEFAULT,
      page: 1,
      category_id: categorySelect?.value,
      status: statusSearch?.value || '',
    });
  };

  // Begin::Delete
  const handleConfirmDelete = (item: ICategoryTrendIndex) => {
    setInitialValuesDefault((_prev) => item);
    setIsConfirm((_prev) => true);
  };

  const handleActionDelete = async () => {
    if (!initialValuesDefault?.id) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await deleteTrendIndex(initialValuesDefault?.id);
      if (response?.code === 200) {
        dispatch(onGetTrendIndexes(query));
        setIsConfirmLoading((_prev) => false);
        setIsConfirm((_prev) => false);
        setInitialValuesDefault((_prev) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        handleCallAllOption();
        if (!!detailCategory) {
          const op = {
            ...detailCategory,
            keyword_count: Number(detailCategory?.keyword_count) - 1,
            label: `${removeParentheses(String(detailCategory?.label))} (${Number(detailCategory?.keyword_count) - 1
              })`,
          };
          handleRefreshListCategory && handleRefreshListCategory(op);
          setCategorySelect((prev) => op);
        }
        if (
          String(query?.category_id) === String(categorySelect?.value) &&
          categorySelect?.value
        ) {
          setCategorySelect((prev: any) => ({
            ...prev,
            label: `${decreaseQuantity(String(prev?.label))}`,
          }));
        }
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmClick = () => {
    setInitialValuesDefault((_prev) => null);
    setIsConfirm((_prev) => false);
  };
  // End::Delete

  const resetData = () => {
    setQuery(
      {
        keyword: undefined,
        sort_by: undefined,
        [typeSelect?.value]: undefined,
        category_id: undefined,
        status: undefined,
      },
      "push"
    );
    setKeywordSearch("");
    setTypeSelect(
      typeSearchLang?.find((e: any) => e.value === TYPE_SELECT_DEFAULT)
    );
    setFromRangeSearch("");
    setToRangeSearch("");
    setCategorySelect(null);
    setStatusSearch(null);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Index Trend"),
        accessor: "naver_trend",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => {
          return (
            <>
              <div>{cell?.value}</div>
            </>
          );
        },
      },
      // {
      //   Header: t("Naver Trend"),
      //   accessor: "naver_trend",
      //   filterable: false,
      //   sortable: true,
      //   Cell: (cell: any) => {
      //     return (
      //       <>
      //         <div>{cell?.value}</div>
      //       </>
      //     );
      //   },
      // },
      // {
      //   Header: t("Google Trend"),
      //   accessor: "google_trend",
      //   filterable: false,
      //   sortable: true,
      //   Cell: (cell: any) => {
      //     return (
      //       <>
      //         <div>{cell?.value}</div>
      //       </>
      //     );
      //   },
      // },
      {
        Header: t("Categories"),
        accessor: "categories",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const categories = value?.categories || [];
          let categoryName: string[] = [];
          for (let category of categories) {
            let subs = [];
            for (let sub of category?.group_categories) {
              subs.push(sub?.name);
            }
            subs.push(category?.name);
            categoryName.push(subs.join(` > `));
          }
          return (
            <div
              dangerouslySetInnerHTML={{ __html: categoryName.join(`<br>`) }}
            />
          );
        },
      },
      {
        Header: t("Keyword"),
        accessor: "keyword",
        filterable: false,
        sortable: true,
        Cell: (cell: any) => {
          return (
            <>
              <div>{cell?.value}</div>
            </>
          );
        },
      },
      {
        Header: t("Gender Trend"),
        accessor: "gender_label",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const { naver_trend_male_ratio, naver_trend_female_ratio } = value;
          const num_male = Number(String(naver_trend_male_ratio).replace('%', '').replace(',', ''));
          const num_female = Number(String(naver_trend_female_ratio).replace('%', '').replace(',', ''));
          let nameTop: string = '';
          if (num_male > num_female) {
            nameTop = 'naver_trend_male_ratio';
          } else if (num_male < num_female) {
            nameTop = 'naver_trend_female_ratio';
          }
          return (
            <div>
              <span className={`${nameTop === 'naver_trend_male_ratio' && 'text-danger'}`}>{naver_trend_male_ratio}</span>{' , '}
              <span className={`${nameTop === 'naver_trend_female_ratio' && 'text-danger'}`}>{naver_trend_female_ratio}</span>
            </div>
          );
        },
      },
      {
        Header: t("Age Trend"),
        accessor: "age_label",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const {
            naver_trend_age_10_ratio,
            naver_trend_age_20_ratio,
            naver_trend_age_30_ratio,
            naver_trend_age_40_ratio,
            naver_trend_age_50_ratio,
          } = value;
          const top = [
            naver_trend_age_10_ratio,
            naver_trend_age_20_ratio,
            naver_trend_age_30_ratio,
            naver_trend_age_40_ratio,
            naver_trend_age_50_ratio,
          ].reduce(({ numMax = 0, i = 0 }, item: string, index) => {
            const num = Number(String(item).replace('%', '').replace(',', ''));
            const max = num > numMax ? ({ numMax: num, i: index + 1 }) : ({ numMax, i });
            return max;
          }, {});

          return (
            <div>
              <span className={`${top?.i === 1 && 'text-danger'}`}>{naver_trend_age_10_ratio}</span>{' , '}
              <span className={`${top?.i === 2 && 'text-danger'}`}>{naver_trend_age_20_ratio}</span>{' , '}
              <span className={`${top?.i === 3 && 'text-danger'}`}>{naver_trend_age_30_ratio}</span>{' , '}
              <span className={`${top?.i === 4 && 'text-danger'}`}>{naver_trend_age_40_ratio}</span>{' , '}
              <span className={`${top?.i === 5 && 'text-danger'}`}>{naver_trend_age_50_ratio}</span>
            </div>
          );
        },
      },
      {
        Header: t("Status"),
        accessor: "status_name",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          const value: any = cell?.row?.original;
          const status = value?.status;
          const color =
            status === 1 ? "success" : status === 2 ? "primary" : "danger";
          return (
            <div style={{ minWidth: "60px" }}>
              <span className={`badge bg-${color}`}>{`${t(
                `Status_Keyword_${status}`
              )}`}</span>
            </div>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updated_at",
        filterable: false,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <div>{cell?.value}</div>
            </>
          );
        },
      },
      {
        Header: t("Button Action"),
        thClass: "text-center",
        thWidth: 60,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul className="list-inline hstack gap-1 mb-0">
              {/* <li className="list-inline-item" title={t('Button Delete Keyword')}>
                <button type="button" className="btn btn-sm btn-success btn-sm fs-14"
                  disabled={!!isKeyLoading} onClick={() => handleAnalysis(cell?.row?.original?.keyword, cell?.row?.id)}
                >
                  {(String(isKeyLoading) === String(cell?.row?.id)) ? <Spinner size="sm"></Spinner> : <i className="ri-refresh-line align-bottom"></i>}
                </button>
              </li> */}

              <li
                className="list-inline-item"
                title={t("Button Delete Keyword")}
              >
                <Link
                  className="btn btn-sm btn-soft-secondary  edit-item-btn"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleConfirmDelete(item);
                  }}
                >
                  <i className="ri-delete-bin-3-fill align-bottom"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language, isKeyLoading]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCallAllOption = async () => {
    try {
      const [resCategoriesTrendIndex]: any = await Promise.all([
        getAllCategoriesTrendIndexNormal(),
      ]);
      setListCategoriesTrendIndex(
        (_prev) => resCategoriesTrendIndex?.data || []
      );
    } catch (error: any) {
      return error;
    }
  };

  // Begin::Add New Keyword
  const handleSubmit = async (values: any) => {
    if (!detailCategory?.value) {
      return;
    }
    const keywords = String(values?.keywords || "")
      .split("\n")
      ?.reduce((arr: string[], item: string) => {
        return !!String(item).trim() ? [...arr, String(item).trim()] : arr;
      }, []);
    try {
      setIsUpdateKeywordsLoading((_prev) => true);
      const data = {
        category_id: detailCategory?.value,
        keywords: [],
        manual_keywords: keywords || [],
        source: "chatgpt",
      };
      const response: any = await postCategoryKeywords(data);
      if (response?.data) {
        dispatch(onGetTrendIndexes(query));
        setIsUpdateKeywordsLoading((_prev) => false);
        const op = {
          ...detailCategory,
          keyword_count: response?.data?.keyword_count,
          label: `${removeParentheses(String(detailCategory?.label))} (${response?.data?.keyword_count
            })`,
        };
        handleRefreshListCategory && handleRefreshListCategory(op);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        formik.setFieldValue("keywords", "");
      } else {
        setIsUpdateKeywordsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsUpdateKeywordsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchema = Yup.object({
    keywords: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      keywords: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  //@ts-ignore
  const initialValueKeywords = formik?.values?.keywords;

  // End::Add New Keyword

  useEffect(() => {
    handleCallAllOption();
  }, []);

  useEffect(() => {
    if (typeSelect?.value) {
      setTypeSelect((_prev: any) =>
        typeSearchLang?.find((e: any) => e.value === typeSelect?.value)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t("Trend Index")} - ${t("Keyword")} | NewsHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className={`page-content ${!!detailCategory?.value && "p-0"}`}>
        <Container fluid>
          {!detailCategory?.value && (
            <BreadCrumb title={t("Trend Index")} pageTitle={t("Keyword")} />
          )}
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardHeader className="border-0">
                  <Row className="g-3">
                    <Col
                      md={
                        !!detailCategory?.value && !isFullRowEnterKeywords
                          ? 7
                          : 12
                      }
                    >
                      <div
                        className=""
                        style={
                          !!detailCategory?.value
                            ? {
                              backgroundColor: "var(--vz-topbar-search-bg)",
                              padding: "10px 10px 20px",
                              borderRadius: "5px",
                            }
                            : {}
                        }
                      >
                        <Row className="g-4 align-items-center mb-2">
                          <Col sm={6} md={!!detailCategory?.value ? 6 : 4}>
                            <DropdownCategoryKeyword
                              categoryType="trend_index"
                              dataListNaver={[]}
                              dataListTrendIndex={listCategoriesTrendIndex}
                              initialValue={
                                detailCategory || categorySelect || undefined
                              }
                              onChangeCategory={(event: any) => {
                                setCategorySelect(event);
                              }}
                              disabled={!!detailCategory?.value}
                            />
                          </Col>
                          <Col sm={6} md={!!detailCategory?.value ? 3 : 2}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t("Search by keyword")}...`}
                              value={keywordSearch}
                              onChange={(e) => setKeywordSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col>
                          <Col sm={6} md={!!detailCategory?.value ? 3 : 2}>
                            <Select
                              options={STATUS_KEYWORD_OPTIONS_LANG}
                              value={statusSearch || null}
                              onChange={(e: any) => setStatusSearch(e)}
                              placeholder={<div>{`${t('Status')}...`}</div>}
                              className="search-filter-category-type"
                              classNamePrefix="name-prefix"
                            />
                          </Col>
                        </Row>
                        <Row className="g-4 align-items-center">
                          {/* type search */}
                          <Col sm={6} md={!!detailCategory?.value ? 6 : 4}>
                            <Select
                              options={typeSearchLang}
                              value={typeSelect}
                              onChange={(e: any) => setTypeSelect(e)}
                              placeholder={
                                <div>{`${t("Search by field")}...`}</div>
                              }
                            />
                          </Col>
                          <Col sm={3} md={!!detailCategory?.value ? 3 : 2}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t("From Range")}...`}
                              value={fromRangeSearch}
                              onChange={(e) =>
                                setFromRangeSearch(e.target.value)
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col>
                          <Col sm={3} md={!!detailCategory?.value ? 3 : 2}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t("To Range")}...`}
                              value={toRangeSearch}
                              onChange={(e) => setToRangeSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </Col>
                          <Col
                            sm={12}
                            md={!!detailCategory?.value ? 12 : 4}
                            className={`hstack gap-1 justify-content-sm-${!!detailCategory?.value ? "end" : "center"
                              } justify-content-md-${!!detailCategory?.value ? "end" : "start"
                              }`}
                          >
                            <div>
                              <button
                                type="button"
                                className="btn btn-primary me-1"
                                onClick={searchData}
                                disabled={isTrendIndexesLoading}
                              >
                                <i className="ri-search-line align-bottom me-1"></i>{" "}
                                {t("Button Search")}
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary fs-14"
                                onClick={resetData}
                              >
                                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                                {t("Button Reset")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    {!!detailCategory?.value && (
                      <Col lg={isFullRowEnterKeywords ? 12 : 5}>
                        <div
                          className="position-relative"
                          style={{
                            backgroundColor: "var(--vz-topbar-search-bg)",
                            padding: "10px 10px 20px",
                            borderRadius: "5px",
                          }}
                        >
                          <form onSubmit={formik.handleSubmit}>
                            <div className="row g-3">
                              <Col md={12} lg={12}>
                                <div className="text-start">
                                  <textarea
                                    ref={textareaKeyWordsRef}
                                    rows={3}
                                    id="keywords"
                                    name="keywords"
                                    className="form-control"
                                    autoFocus={true}
                                    autoComplete="off"
                                    style={{ minHeight: "85px" }}
                                    value={initialValueKeywords || ""}
                                    placeholder={`${t(
                                      "Keywords list which is separated by new line"
                                    )}...`}
                                    onChange={(event: any) =>
                                      formik.setFieldValue(
                                        "keywords",
                                        event?.target?.value || ""
                                      )
                                    }
                                  />
                                  {/* {formik.touched.keywords && formik.errors.keywords ? (
                                  <div className="text-danger mt-2">{formik.errors.keywords}</div>
                                ) : null} */}
                                </div>
                              </Col>
                              <Col md={12} xxl={12} lg={12}>
                                <div className="d-flex align-items-start gap-3 mt-2">
                                  <button
                                    type="submit"
                                    className="btn btn-success ms-auto "
                                    disabled={
                                      isUpdateKeywordsLoading ||
                                      String(initialValueKeywords).trim() === ""
                                    }
                                  >
                                    {isUpdateKeywordsLoading ? (
                                      <i className="label-icon align-middle">
                                        <Spinner
                                          size="sm"
                                          className="me-2"
                                        ></Spinner>
                                      </i>
                                    ) : (
                                      <i className="ri-login-circle-line align-bottom me-1"></i>
                                    )}
                                    {t("Button Add Keywords")}
                                  </button>
                                </div>
                              </Col>
                            </div>
                          </form>
                          <button
                            type="button"
                            className="btn btn-ghost-secondary ms-auto btn-sm fs-14 position-absolute"
                            style={{ left: "0px", bottom: "0px" }}
                            onClick={() =>
                              setIsFullRowEnterKeywords((prev) => !prev)
                            }
                          >
                            <i
                              className={`${isFullRowEnterKeywords
                                ? "ri-picture-in-picture-exit-line"
                                : "ri-picture-in-picture-2-line"
                                } align-bottom me-1`}
                              style={{ fontSize: "18px" }}
                            ></i>
                          </button>
                        </div>
                      </Col>
                    )}
                  </Row>
                </CardHeader>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle"
                      theadClass="table-light"
                      columns={columns}
                      data={
                        trendIndexes?.list?.length ? trendIndexes?.list : []
                      }
                      customPageSize={query.limit}
                      customPageCount={Math.ceil(
                        Number(trendIndexes?.pagination?.total) /
                        Number(trendIndexes?.pagination?.limit)
                      )}
                      customPageIndex={query.page - 1}
                      totalRecords={trendIndexes?.pagination?.total}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{
                        sort_by: query.sort_by,
                        order_by: query.order_by,
                      }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isTrendIndexesLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ModalConfirm
          header={t("Button Delete Keyword")}
          isOpen={isOpenConfirm}
          isLoading={isConfirmLoading}
          onClose={onCloseConfirmClick}
          onConfirm={handleActionDelete}
        />
      </div>
    </React.Fragment>
  );
};

export default TrendIndexKeyword;
